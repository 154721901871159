// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'personalAccessTokens';
const repository = 'PersonalAccessToken';

export enum PersonalAccessTokenScopes {
	'Create' = 'personalAccessTokens:create',
	'Read' = 'personalAccessTokens:read',
	'Update' = 'personalAccessTokens:update',
	'Delete' = 'personalAccessTokens:delete',
	'Manage' = 'personalAccessTokens:manage',
}

const namespace = 'atosco';

export const personalAccessTokenScopes: AbilityScopeMap<PersonalAccessTokenScopes> = Map<
	PersonalAccessTokenScopes,
	AbilityScopeItem<PersonalAccessTokenScopes>
>({
	[PersonalAccessTokenScopes.Create]: {
		code: PersonalAccessTokenScopes.Create,
		id: `${namespace}_2FXkmVawqV01Vrv3haPFjza3aMh`,
		order: 0,
		icon: 'circle-user',
		label: 'PersonalAccessToken Create',
		collection,
		repository,
	},
	[PersonalAccessTokenScopes.Read]: {
		code: PersonalAccessTokenScopes.Read,
		id: `${namespace}_2FXknxTTh1ZtLMmHKmn6xY2qUB3`,
		order: 1,
		icon: 'circle-user',
		label: 'PersonalAccessToken Read',
		collection,
		repository,
	},
	[PersonalAccessTokenScopes.Update]: {
		code: PersonalAccessTokenScopes.Update,
		id: `${namespace}_2FXkq0anyeDuz8HIConJR7sUlA9`,
		order: 2,
		icon: 'circle-user',
		label: 'PersonalAccessToken Update',
		collection,
		repository,
	},
	[PersonalAccessTokenScopes.Delete]: {
		code: PersonalAccessTokenScopes.Delete,
		id: `${namespace}_2FXkrchssUvpJliZ61A3tnWGhZQ`,
		order: 3,
		icon: 'circle-user',
		label: 'PersonalAccessToken Delete',
		collection,
		repository,
	},
	[PersonalAccessTokenScopes.Manage]: {
		code: PersonalAccessTokenScopes.Manage,
		id: `${namespace}_2FXkkvRmPbztLRh8zEKZTzEXUdo`,
		order: 4,
		icon: 'circle-user',
		label: 'PersonalAccessToken Manage',
		alias: [
			PersonalAccessTokenScopes.Create,
			PersonalAccessTokenScopes.Read,
			PersonalAccessTokenScopes.Update,
			PersonalAccessTokenScopes.Delete,
		],
		collection,
		repository,
	},
});
