import { RouteMetadata } from 'routes/types/routes.type';

export const WorkersRoutesMetadata: Record<string, RouteMetadata> = {
	documents: {
		isWorkerDocumentPage: true,
	},
	profile: {
		isProfilePage: true,
	},
	surveys: {
		isSurveysPage: true,
	},
	engagements: {
		isEngagementPage: true,
	},
	workersInfo: {
		isWorkerInfoPage: true,
	},
};
