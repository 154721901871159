// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { capitalizeFirstLetter } from '../shared/common/helpers/strings.helpers';
import { BaseDictionary } from './types/dictionaries.type';

export enum EmailTemplateType {
	LOGIN_CODE = 'login-code',
	NEW_WORKER_WELCOME_NOTIFICATION = 'new-worker-welcome-notification',
	NEW_ADMINISTRATOR_WELCOME_NOTIFICATION = 'new-administrator-welcome-notification',
	WORKER_NEW_ENGAGEMENT_NOTIFICATION = 'worker-new-engagement-notification',
	WORKER_ENGAGEMENT_OUTSTANDING_SURVEY_REMAINDER = 'worker-engagement-outstanding-survey-remainder',
	WORKER_ENGAGEMENT_MONTHLY_SURVEY_REMAINDER = 'worker-engagement-monthly-survey-remainder',
	WORKER_ENGAGEMENT_MISSING_ACTION_ITEMS_REMAINDER = 'worker-engagement-missing-action-items-remainder',
	WORKER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION = 'worker-engagement-determination-changed-notification',
	HIRING_MANAGER_NEW_ENGAGEMENT_NOTIFICATION = 'hiring-manager-new-engagement-notification',
	HIRING_MANAGER_MONTHLY_SURVEY_REMAINDER = 'hiring-manager-monthly-survey-remainder',
	HIRING_MANAGER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION = 'hiring-manager-engagement-determination-changed-notification',
}

export enum EmailTemplateSchemaKey {
	LOGIN_CODE = 'loginCode',
	NEW_WORKER_WELCOME_NOTIFICATION = 'newWorkerWelcomeNotification',
	NEW_ADMINISTRATOR_WELCOME_NOTIFICATION = 'newAdministratorWelcomeNotification',
	WORKER_NEW_ENGAGEMENT_NOTIFICATION = 'workerNewEngagementNotification',
	WORKER_ENGAGEMENT_OUTSTANDING_SURVEY_REMAINDER = 'workerEngagementOutstandingSurveyRemainder',
	WORKER_ENGAGEMENT_MONTHLY_SURVEY_REMAINDER = 'workerEngagementMonthlySurveyRemainder',
	WORKER_ENGAGEMENT_MISSING_ACTION_ITEMS_REMAINDER = 'workerEngagementMissingActionItemsRemainder',
	WORKER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION = 'workerEngagementDeterminationChangedNotification',
	HIRING_MANAGER_NEW_ENGAGEMENT_NOTIFICATION = 'hiringManagerNewEngagementNotification',
	HIRING_MANAGER_MONTHLY_SURVEY_REMAINDER = 'hiringManagerMonthlySurveyRemainder',
	HIRING_MANAGER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION = 'hiringManagerEngagementDeterminationChangedNotification',
}

export enum SystemEmailTemplateSchemaKey {
	SYSTEM_LOGIN_CODE = 'systemLoginCode',
	SYSTEM_NEW_WORKER_WELCOME_NOTIFICATION = 'systemNewWorkerWelcomeNotification',
	SYSTEM_NEW_ADMINISTRATOR_WELCOME_NOTIFICATION = 'systemNewAdministratorWelcomeNotification',
	SYSTEM_WORKER_NEW_ENGAGEMENT_NOTIFICATION = 'systemWorkerNewEngagementNotification',
	SYSTEM_WORKER_ENGAGEMENT_OUTSTANDING_SURVEY_REMAINDER = 'systemWorkerEngagementOutstandingSurveyRemainder',
	SYSTEM_WORKER_ENGAGEMENT_MONTHLY_SURVEY_REMAINDER = 'systemWorkerEngagementMonthlySurveyRemainder',
	SYSTEM_WORKER_ENGAGEMENT_MISSING_ACTION_ITEMS_REMAINDER = 'systemWorkerEngagementMissingActionItemsRemainder',
	SYSTEM_WORKER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION = 'systemWorkerEngagementDeterminationChangedNotification',
	SYSTEM_HIRING_MANAGER_NEW_ENGAGEMENT_NOTIFICATION = 'systemHiringManagerNewEngagementNotification',
	SYSTEM_HIRING_MANAGER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION = 'systemHiringManagerEngagementDeterminationChangedNotification',
	SYSTEM_HIRING_MANAGER_MONTHLY_SURVEY_REMAINDER = 'systemHiringManagerMonthlySurveyRemainder',
	SYSTEM_INTERNAL_SME_NEW_ENGAGEMENT_NOTIFICATION = 'systemInternalSmeNewEngagementNotification',
	SYSTEM_INTERNAL_SME_ENGAGEMENT_PROGRESS_CHANGED_NOTIFICATION = 'systemInternalSmeEngagementProgressChangedNotification',
	SYSTEM_INTERNAL_SME_ENGAGEMENT_COMPLIANCE_DUE_DATE_72_HOUR_REMINDER = 'systemInternalSmeEngagementComplianceDueDate72HourReminder',
}

export interface EmailTemplateTypeItem extends BaseDictionary {
	code: EmailTemplateType;
	id: `${IdNamespace.EmailTemplateType}_${string}`;
	schemaKey: EmailTemplateSchemaKey;
	order: number;
	icon: string;
	label: string;
	systemDefaultKey: SystemEmailTemplateSchemaKey;
}

export const emailTemplateTypes = Map<
	EmailTemplateType | `${EmailTemplateType}`,
	EmailTemplateTypeItem
>({
	[EmailTemplateType.LOGIN_CODE]: {
		code: EmailTemplateType.LOGIN_CODE,
		id: 'emtemty_2YNYxaeRIMgY5WWLip62IJxjuAW',
		schemaKey: EmailTemplateSchemaKey.LOGIN_CODE,
		order: 0,
		icon: 'star',
		label: 'New Worker Welcome Notification',
		systemDefaultKey: SystemEmailTemplateSchemaKey.SYSTEM_LOGIN_CODE,
	},
	[EmailTemplateType.NEW_WORKER_WELCOME_NOTIFICATION]: {
		code: EmailTemplateType.NEW_WORKER_WELCOME_NOTIFICATION,
		id: 'emtemty_2YNYxgYWVdutUR2e6MI5ivoyOry',
		schemaKey: EmailTemplateSchemaKey.NEW_WORKER_WELCOME_NOTIFICATION,
		order: 1,
		icon: 'star',
		label: 'New Worker Welcome Notification',
		systemDefaultKey: SystemEmailTemplateSchemaKey.SYSTEM_NEW_WORKER_WELCOME_NOTIFICATION,
	},
	[EmailTemplateType.NEW_ADMINISTRATOR_WELCOME_NOTIFICATION]: {
		code: EmailTemplateType.NEW_ADMINISTRATOR_WELCOME_NOTIFICATION,
		id: 'emtemty_2YNYxZBz1hgefm9oMxpiGfPDQDT',
		schemaKey: EmailTemplateSchemaKey.NEW_ADMINISTRATOR_WELCOME_NOTIFICATION,
		order: 2,
		icon: 'star',
		label: 'New Administrator Welcome Notification',
		systemDefaultKey:
			SystemEmailTemplateSchemaKey.SYSTEM_NEW_ADMINISTRATOR_WELCOME_NOTIFICATION,
	},
	[EmailTemplateType.WORKER_NEW_ENGAGEMENT_NOTIFICATION]: {
		code: EmailTemplateType.WORKER_NEW_ENGAGEMENT_NOTIFICATION,
		id: 'emtemty_2Scgad7JxR33nvLZhSaYWJxqQ8I',
		schemaKey: EmailTemplateSchemaKey.WORKER_NEW_ENGAGEMENT_NOTIFICATION,
		order: 3,
		icon: 'star',
		label: 'Worker New Engagement Notification',
		systemDefaultKey: SystemEmailTemplateSchemaKey.SYSTEM_WORKER_NEW_ENGAGEMENT_NOTIFICATION,
	},
	[EmailTemplateType.WORKER_ENGAGEMENT_OUTSTANDING_SURVEY_REMAINDER]: {
		code: EmailTemplateType.WORKER_ENGAGEMENT_OUTSTANDING_SURVEY_REMAINDER,
		id: 'emtemty_2Sch0F76RPUt5JLxKuGWvgewkYD',
		schemaKey: EmailTemplateSchemaKey.WORKER_ENGAGEMENT_OUTSTANDING_SURVEY_REMAINDER,
		order: 4,
		icon: 'star',
		label: 'Worker Engagement Outstanding Survey Remainder',
		systemDefaultKey:
			SystemEmailTemplateSchemaKey.SYSTEM_WORKER_ENGAGEMENT_OUTSTANDING_SURVEY_REMAINDER,
	},
	[EmailTemplateType.WORKER_ENGAGEMENT_MONTHLY_SURVEY_REMAINDER]: {
		code: EmailTemplateType.WORKER_ENGAGEMENT_MONTHLY_SURVEY_REMAINDER,
		id: 'emtemty_2U5oy2gNjGbvC8LOXToXwUqjSSq',
		schemaKey: EmailTemplateSchemaKey.WORKER_ENGAGEMENT_MONTHLY_SURVEY_REMAINDER,
		order: 5,
		icon: 'star',
		label: 'Worker Engagement Monthly Survey Remainder',
		systemDefaultKey:
			SystemEmailTemplateSchemaKey.SYSTEM_WORKER_ENGAGEMENT_MONTHLY_SURVEY_REMAINDER,
	},
	[EmailTemplateType.WORKER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION]: {
		code: EmailTemplateType.WORKER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION,
		id: 'emtemty_2U5pMHVou5QE2lsdmlnxrsSePx6',
		schemaKey: EmailTemplateSchemaKey.WORKER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION,
		order: 6,
		icon: 'star',
		label: 'Worker Engagement Determination Changed Notification',
		systemDefaultKey:
			SystemEmailTemplateSchemaKey.SYSTEM_WORKER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION,
	},
	[EmailTemplateType.HIRING_MANAGER_NEW_ENGAGEMENT_NOTIFICATION]: {
		code: EmailTemplateType.HIRING_MANAGER_NEW_ENGAGEMENT_NOTIFICATION,
		id: 'emtemty_2Sch2UrQe3ADu814Gl7eK3HW1kR',
		schemaKey: EmailTemplateSchemaKey.HIRING_MANAGER_NEW_ENGAGEMENT_NOTIFICATION,
		order: 7,
		icon: 'star',
		label: 'Hiring Manager New Engagement Notification',
		systemDefaultKey:
			SystemEmailTemplateSchemaKey.SYSTEM_HIRING_MANAGER_NEW_ENGAGEMENT_NOTIFICATION,
	},
	[EmailTemplateType.HIRING_MANAGER_MONTHLY_SURVEY_REMAINDER]: {
		code: EmailTemplateType.HIRING_MANAGER_MONTHLY_SURVEY_REMAINDER,
		id: 'emtemty_2SchFdNRFQMdLYYcGozGvWUMy91',
		schemaKey: EmailTemplateSchemaKey.HIRING_MANAGER_MONTHLY_SURVEY_REMAINDER,
		order: 8,
		icon: 'star',
		label: 'Hiring Manager Engagement Monthly Survey Remainder',
		systemDefaultKey:
			SystemEmailTemplateSchemaKey.SYSTEM_HIRING_MANAGER_MONTHLY_SURVEY_REMAINDER,
	},
	[EmailTemplateType.HIRING_MANAGER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION]: {
		code: EmailTemplateType.HIRING_MANAGER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION,
		id: 'emtemty_2U5ocyKcXEfw86TstnVS66x0Uz1',
		schemaKey:
			EmailTemplateSchemaKey.HIRING_MANAGER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION,
		order: 9,
		icon: 'star',
		label: 'Hiring Manager Engagement Determination Changed Notification',
		systemDefaultKey:
			SystemEmailTemplateSchemaKey.SYSTEM_HIRING_MANAGER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION,
	},
});
