// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'fieldChoices';
const repository = 'FieldChoice';

export enum FieldChoiceScopes {
	'Create' = 'fieldChoices:create',
	'Read' = 'fieldChoices:read',
	'Update' = 'fieldChoices:update',
	'Delete' = 'fieldChoices:delete',
	'Manage' = 'fieldChoices:manage',
}

const namespace = 'fldchosco';

export const fieldChoiceScopes: AbilityScopeMap<FieldChoiceScopes> = Map<
	FieldChoiceScopes,
	AbilityScopeItem<FieldChoiceScopes>
>({
	[FieldChoiceScopes.Create]: {
		code: FieldChoiceScopes.Create,
		id: `${namespace}_2FXfV8XCNWkLJ1YMNc8tv8hGtxU`,
		order: 0,
		icon: 'circle-user',
		label: 'FieldChoice Create',
		collection,
		repository,
	},
	[FieldChoiceScopes.Read]: {
		code: FieldChoiceScopes.Read,
		id: `${namespace}_2FXfWflZQF8dfLOVHvOD0Qagl3B`,
		order: 1,
		icon: 'circle-user',
		label: 'FieldChoice Read',
		collection,
		repository,
	},
	[FieldChoiceScopes.Update]: {
		code: FieldChoiceScopes.Update,
		id: `${namespace}_2FXfbmwga3SKVkSNbVQYwySoH5O`,
		order: 2,
		icon: 'circle-user',
		label: 'FieldChoice Update',
		collection,
		repository,
	},
	[FieldChoiceScopes.Delete]: {
		code: FieldChoiceScopes.Delete,
		id: `${namespace}_2FXfkjxbhwYmvkxhga7EnIxuAAU`,
		order: 3,
		icon: 'circle-user',
		label: 'FieldChoice Delete',
		collection,
		repository,
	},
	[FieldChoiceScopes.Manage]: {
		code: FieldChoiceScopes.Manage,
		id: `${namespace}_2FXfBDt0F2RWKrVlZ2R3SjrGJrG`,
		order: 4,
		icon: 'circle-user',
		label: 'FieldChoice Manage',
		alias: [
			FieldChoiceScopes.Create,
			FieldChoiceScopes.Read,
			FieldChoiceScopes.Update,
			FieldChoiceScopes.Delete,
		],
		collection,
		repository,
	},
});
