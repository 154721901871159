import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'emailLogs';
const repository = 'EmailLog';

export enum EmailLogScopes {
	'Read' = 'emailLogs:read',
}

export const emailLogScopes: AbilityScopeMap<EmailLogScopes> = Map<
	EmailLogScopes,
	AbilityScopeItem<EmailLogScopes>
>({
	[EmailLogScopes.Read]: {
		code: EmailLogScopes.Read,
		id: `emlogsco_2R1272XMY3NVK0DEGiyvjYO9aNS`,
		order: 0,
		icon: 'circle-user',
		label: 'EmailLog Read',
		collection,
		repository,
	},
});
