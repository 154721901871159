import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'emailTemplates';
const repository = 'EmailTemplate';

export enum EmailTemplateScopes {
	'Create' = 'emailTemplates:create',
	'Read' = 'emailTemplates:read',
	'Update' = 'emailTemplates:update',
	'Delete' = 'emailTemplates:delete',
	'Manage' = 'emailTemplates:manage',
}

const namespace = 'emtemsco';

export const emailTemplateScopes: AbilityScopeMap<EmailTemplateScopes> = Map<
	EmailTemplateScopes,
	AbilityScopeItem<EmailTemplateScopes>
>({
	[EmailTemplateScopes.Create]: {
		code: EmailTemplateScopes.Create,
		id: `${namespace}_2FX7nIzZgWSmdUmETE2eBT6cOQM`,
		order: 0,
		icon: 'circle-user',
		label: 'EmailTemplate Create',
		collection,
		repository,
	},
	[EmailTemplateScopes.Read]: {
		code: EmailTemplateScopes.Read,
		id: `${namespace}_2FX7oq6FNR9l06WpzOeMivaADR9`,
		order: 1,
		icon: 'circle-user',
		label: 'EmailTemplate Read',
		collection,
		repository,
	},
	[EmailTemplateScopes.Update]: {
		code: EmailTemplateScopes.Update,
		id: `${namespace}_2FX7qlthEcEzXxZ05nQ3x0x91W4`,
		order: 2,
		icon: 'circle-user',
		label: 'EmailTemplate Update',
		collection,
		repository,
	},
	[EmailTemplateScopes.Delete]: {
		code: EmailTemplateScopes.Delete,
		id: `${namespace}_2FX7tT3hsxIGhjADY6TdR6j60Rm`,
		order: 3,
		icon: 'circle-user',
		label: 'EmailTemplate Delete',
		collection,
		repository,
	},
	[EmailTemplateScopes.Manage]: {
		code: EmailTemplateScopes.Manage,
		id: `${namespace}_2FX7lMzuFNko2NRdF6vtDNFrSLq`,
		order: 4,
		icon: 'circle-user',
		label: 'EmailTemplate Manage',
		alias: [
			EmailTemplateScopes.Create,
			EmailTemplateScopes.Read,
			EmailTemplateScopes.Update,
			EmailTemplateScopes.Delete,
		],
		collection,
		repository,
	},
});
