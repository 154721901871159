// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { type SortList, SortListItem } from './types/dictionaries.type';
import { sortList } from './sort-list';

export enum ClientSortList {
	NAME = 'name',
}

export interface ClientSortListItem extends Omit<SortListItem, 'id' | 'code'> {
	code: SortList | ClientSortList;
	id: `${IdNamespace.SortList}_${string}` | `${IdNamespace.ClientSortList}_${string}`;
}

export const clientSortList: Map<
	SortList | `${SortList}` | ClientSortList | `${ClientSortList}`,
	ClientSortListItem
> = Map<ClientSortList, ClientSortListItem>({
	[ClientSortList.NAME]: {
		code: ClientSortList.NAME,
		id: 'cliesoli_2FeKNBBfeoCprS2sDFg0Y9Ym85K',
		order: 3,
		// label: i18n.t('clientSortList.name', { ns: 'dictionaries' }),
		label: 'Name',
		directionIcons: {
			asc: 'sort-alpha-up-alt',
			desc: 'sort-alpha-down-alt',
		},
		function: {
			asc: {
				name: 'asc',
				_createdDate: 'asc',
			},
			desc: {
				name: 'desc',
				_createdDate: 'desc',
			},
		},
	},
}).merge(sortList);
