// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum JurisdictionTimelines {
	LESS_THAN_ONE_WEEKS = 'less-than-one-weeks',
	ONE_TO_TWO_WEEKS = 'one-to-two-weeks',
	TWO_TO_THREE_WEEKS = 'two-to-three-weeks',
	THREE_TO_FOUR_WEEKS = 'three-to-four-weeks',
}

export interface JurisdictionTimelinesItem extends BaseDictionary {
	code: JurisdictionTimelines;
	id: `${IdNamespace.JurisdictionTimelines}_${string}`;
	order: number;
	label: string;
}

export const jurisdictionTimelines = Map<
	JurisdictionTimelines | `${JurisdictionTimelines}`,
	JurisdictionTimelinesItem
>({
	[JurisdictionTimelines.LESS_THAN_ONE_WEEKS]: {
		code: JurisdictionTimelines.LESS_THAN_ONE_WEEKS,
		id: 'jurtili_2b142V78Q7P58aLJEeavzkcLqb8',
		order: 0,
		// label: i18n.t('jurisdictionTimelines.lessThanOneWeeks', { ns: 'dictionaries' }),
		label: '<1 Weeks',
	},
	[JurisdictionTimelines.ONE_TO_TWO_WEEKS]: {
		code: JurisdictionTimelines.ONE_TO_TWO_WEEKS,
		id: 'jurtili_2b142VebnroSDA7nuCdCIkEHf8p',
		order: 1,
		// label: i18n.t('jurisdictionTimelines.oneToTwoWeeks', { ns: 'dictionaries' }),
		label: '1-2 Weeks',
	},
	[JurisdictionTimelines.TWO_TO_THREE_WEEKS]: {
		code: JurisdictionTimelines.TWO_TO_THREE_WEEKS,
		id: 'jurtili_2b142SThQGP4ZqMpwrOD74K2aJC',
		order: 2,
		// label: i18n.t('jurisdictionTimelines.twoToThreeWeeks', { ns: 'dictionaries' }),
		label: '2-3 Weeks',
	},
	[JurisdictionTimelines.THREE_TO_FOUR_WEEKS]: {
		code: JurisdictionTimelines.THREE_TO_FOUR_WEEKS,
		id: 'jurtili_2ipHdE050oPTZAOloBdBc1ZNtvA',
		order: 3,
		// label: i18n.t('jurisdictionTimelines.threeToFourWeeks', { ns: 'dictionaries' }),
		label: '3-4 Weeks',
	},
});
