import { defaultEndpoint, type LoadOptions } from '@fingerprintjs/fingerprintjs-pro';
import { ENV, isEnvironmentDevelopment } from '@shared/configs/environment.config';
import { match } from 'ts-pattern';

export const fingerprintJsConfig: LoadOptions = {
	region: 'us',
	apiKey: match<boolean, string>(isEnvironmentDevelopment(ENV))
		.with(true, (_isDev: true): string => 'Zso1UBD14LhnpVYwviMS')
		.otherwise((): string => 'ITDniIfxLe7bMCd3wQC8'),
	endpoint: [
		'https://fpid.independently.ai/OBJwwk65voQPt0By/Mvnt0KQrxGcen9qX',
		defaultEndpoint, // The default endpoint as fallback
	],
};
