// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum SkillType {
	SKILL = 'skill',
	CERTIFICATE = 'certificate',
}

export interface SkillTypeItem extends BaseDictionary {
	code: SkillType;
	id: `${IdNamespace.SkillType}_${string}`;
	label: string;
}

export const skillTypes = Map<SkillType | `${SkillType}`, SkillTypeItem>({
	[SkillType.SKILL]: {
		code: SkillType.SKILL,
		id: 'skillty_2FePwq6kGaYJRghOYpqbWAMFjnb',
		// label: i18n.t('skill', { ns: 'app' }),
		label: 'Skill',
	},
	[SkillType.CERTIFICATE]: {
		code: SkillType.CERTIFICATE,
		id: 'skillty_2FePwmhvmG1nEHcC0npMHHy4TtB',
		// label: i18n.t('certificate', { ns: 'app' }),
		label: 'Certificate',
	},
});
