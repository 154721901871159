// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { type SortList, SortListItem } from './types/dictionaries.type';
import { sortList } from './sort-list';

export enum TagSortList {
	LABEL = 'label',
}

export interface TagSortListItem extends Omit<SortListItem, 'id' | 'code'> {
	code: SortList | TagSortList;
	id: `${IdNamespace.SortList}_${string}` | `${IdNamespace.TagSortList}_${string}`;
}

export const tagSortList: Map<
	SortList | `${SortList}` | TagSortList | `${TagSortList}`,
	TagSortListItem
> = Map<TagSortList, TagSortListItem>({
	[TagSortList.LABEL]: {
		code: TagSortList.LABEL,
		id: 'tagsoli_2g85kVgXnPqd4W6NS1kIfSuOGlO',
		order: 3,
		// label: i18n.t('tagSortList.label', { ns: 'dictionaries' }),
		label: 'Label',
		directionIcons: {
			asc: 'sort-alpha-up-alt',
			desc: 'sort-alpha-down-alt',
		},
		function: {
			asc: {
				label: 'asc',
				_createdDate: 'asc',
			},
			desc: {
				label: 'desc',
				_createdDate: 'desc',
			},
		},
	},
}).merge(sortList);
