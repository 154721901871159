import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import { Image } from 'mui-image';
// @ts-ignore: Bug with type "declare module" not working
import emailImageData from 'assets/images/undraw_secure_login.svg?inline';
import { ComponentType, ReactElement } from 'react';
import { SignInUpHeader } from 'supertokens-auth-react/lib/build/recipe/passwordless/components/themes/signInUp/signInUpHeader';

const PasswordlessSignInUpHeader = ({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	DefaultComponent,
}: {
	DefaultComponent: ComponentType<typeof SignInUpHeader>;
}): ReactElement | null => (
	<Box>
		<Typography variant='h4'>{t('welcome_back')}</Typography>
		<Typography variant='body1'>{t('welcome_desc')}</Typography>
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				paddingY: '5px',
			}}
		>
			<Image
				src={emailImageData as string}
				alt=''
				duration={2500}
				errorIcon={null}
				height='100px'
				width='auto'
			/>
		</Box>
	</Box>
);

export default PasswordlessSignInUpHeader;
