// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'apiTokens';
const repository = 'ApiToken';

export enum ApiTokenScopes {
	'Create' = 'apiTokens:create',
	'Read' = 'apiTokens:read',
	'Update' = 'apiTokens:update',
	'Delete' = 'apiTokens:delete',
	'Manage' = 'apiTokens:manage',
}

// const NS: string = IdNamespace.ApiTokenScopes;

export const apiTokenScopes: AbilityScopeMap<ApiTokenScopes> = Map<
	ApiTokenScopes,
	AbilityScopeItem<ApiTokenScopes>
>({
	[ApiTokenScopes.Create]: {
		code: ApiTokenScopes.Create,
		id: 'apitosco_2Gt5AO9luD16F9Z6YnKfg4y7sqd',
		order: 0,
		icon: 'circle-user',
		label: 'ApiToken Create',
		collection,
		repository,
	},
	[ApiTokenScopes.Read]: {
		code: ApiTokenScopes.Read,
		id: 'apitosco_2Gt5AGglzmARWlJoHySw34XURWV',
		order: 1,
		icon: 'circle-user',
		label: 'ApiToken Read',
		collection,
		repository,
	},
	[ApiTokenScopes.Update]: {
		code: ApiTokenScopes.Update,
		id: 'apitosco_2Gt5AItpGmMXlKMsUgnkvUHZukA',
		order: 2,
		icon: 'circle-user',
		label: 'ApiToken Update',
		collection,
		repository,
	},
	[ApiTokenScopes.Delete]: {
		code: ApiTokenScopes.Delete,
		id: 'apitosco_2Gt5ALF6XZJtJTgAmWdwfaQw7dN',
		order: 3,
		icon: 'circle-user',
		label: 'ApiToken Delete',
		collection,
		repository,
	},
	[ApiTokenScopes.Manage]: {
		code: ApiTokenScopes.Manage,
		id: 'apitosco_2Gt5AGk3RvSkGef2aAiPgBlNgzo',
		order: 4,
		icon: 'circle-user',
		label: 'ApiToken Manage',
		alias: [
			ApiTokenScopes.Create,
			ApiTokenScopes.Read,
			ApiTokenScopes.Update,
			ApiTokenScopes.Delete,
		],
		collection,
		repository,
	},
});
