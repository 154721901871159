import { createTheme } from "@mui/material/styles";
const defaultTheme = createTheme();
export const colorPalette = {
    black: defaultTheme.palette.augmentColor({
        color: {
            main: "#000"
        },
        name: "black"
    }),
    blue: {
        ...defaultTheme.palette.augmentColor({
            color: {
                main: "#0157BF"
            },
            name: "blue"
        }),
        veryLight: "#e7f5ff"
    },
    green: defaultTheme.palette.augmentColor({
        color: {
            main: "#37ca60"
        },
        name: "green"
    }),
    greyish: defaultTheme.palette.augmentColor({
        color: {
            main: "#D8DADC"
        },
        name: "grey"
    }),
    orange: defaultTheme.palette.augmentColor({
        color: {
            main: "#fd7e14"
        },
        name: "orange"
    }),
    pink: defaultTheme.palette.augmentColor({
        color: {
            main: "#F1DABF"
        },
        name: "pink"
    }),
    purple: {
        ...defaultTheme.palette.augmentColor({
            color: {
                main: "#420039"
            },
            name: "purple"
        }),
        veryLight: "#f8f0fc"
    },
    red: defaultTheme.palette.augmentColor({
        color: {
            main: "#fa2927"
        },
        name: "red"
    }),
    white: defaultTheme.palette.augmentColor({
        color: {
            main: "#FFFBFF"
        },
        name: "white"
    }),
    yellow: defaultTheme.palette.augmentColor({
        color: {
            main: "#f8ed62"
        },
        name: "yellow"
    }),
    onHover: {
        blue: "#1976d20a"
    }
};
