// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary, Hex } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { ProgressColor, ProgressIcon } from './constants/dictionary-progress.constant';

export enum RiskWithRecommendationStatus {
	MISSING = 'missing',
	NEED_TO_CLARIFY = 'need-to-clarify',
	IN_REVIEW = 'in-review',
	SNOOZED = 'snoozed',
	DECLINED = 'declined',
	NOT_APPROVED = 'not-approved',
	APPROVED = 'approved',
}

export type RiskWithRecommendationStatusIsResolvable = Extract<
	RiskWithRecommendationStatus,
	| RiskWithRecommendationStatus.MISSING
	| RiskWithRecommendationStatus.NEED_TO_CLARIFY
	| RiskWithRecommendationStatus.SNOOZED
	| RiskWithRecommendationStatus.DECLINED
	| RiskWithRecommendationStatus.NOT_APPROVED
>;

export type RiskWithRecommendationStatusIsSnoozable = Extract<
	RiskWithRecommendationStatus,
	| RiskWithRecommendationStatus.MISSING
	| RiskWithRecommendationStatus.NEED_TO_CLARIFY
	| RiskWithRecommendationStatus.NOT_APPROVED
>;

export type RiskWithRecommendationStatusIsIgnorable = Extract<
	RiskWithRecommendationStatus,
	| RiskWithRecommendationStatus.MISSING
	| RiskWithRecommendationStatus.NEED_TO_CLARIFY
	| RiskWithRecommendationStatus.SNOOZED
	| RiskWithRecommendationStatus.NOT_APPROVED
>;

export type RiskWithRecommendationStatusIsComplete = Extract<
	RiskWithRecommendationStatus,
	RiskWithRecommendationStatus.NOT_APPROVED | RiskWithRecommendationStatus.APPROVED
>;

export interface RiskWithRecommendationStatusItem extends BaseDictionary {
	code: RiskWithRecommendationStatus;
	id: `${IdNamespace.RiskWithRecommendationStatus}_${string}`;
	order: number;
	icon: string;
	label: string;
	description: string;
	color: Hex;
	isResolvable?: true;
	isSnoozable?: true;
	isIgnorable?: true;
	isComplete?: true;
}

export const riskWithRecommendationStatuses = Map<
	RiskWithRecommendationStatus | `${RiskWithRecommendationStatus}`,
	RiskWithRecommendationStatusItem
>({
	[RiskWithRecommendationStatus.MISSING]: {
		code: RiskWithRecommendationStatus.MISSING,
		id: `riwiresta_2LTzNLPB1navIZDHoE87y7mxIVT`,
		order: 0,
		label: 'Missing',
		description: 'Recommended item not provided by worker',
		color: ProgressColor.MISSING,
		icon: ProgressIcon.MISSING,
		isResolvable: true,
		isSnoozable: true,
		isIgnorable: true,
	},
	[RiskWithRecommendationStatus.NEED_TO_CLARIFY]: {
		code: RiskWithRecommendationStatus.NEED_TO_CLARIFY,
		id: 'riwiresta_2LqdqOiL7hUNBhLTq565taxdx7t',
		order: 1,
		// label: i18n.t('missing', { ns: 'app' }),
		label: 'Need to Clarify',
		description: 'Worker completed item; requires confirmation of understanding',
		color: ProgressColor.PENDING,
		icon: ProgressIcon.PENDING,
		isResolvable: true,
		isSnoozable: true,
		isIgnorable: true,
	},
	[RiskWithRecommendationStatus.IN_REVIEW]: {
		code: RiskWithRecommendationStatus.IN_REVIEW,
		id: `riwiresta_2LTzNS2ez3dPEEoIKxFJzR1fCzS`,
		order: 4,
		label: 'In Review',
		description: "Worker's action or provision under compliance review",
		color: ProgressColor.IN_REVIEW,
		icon: ProgressIcon.IN_REVIEW,
	},
	[RiskWithRecommendationStatus.SNOOZED]: {
		code: RiskWithRecommendationStatus.SNOOZED,
		id: `riwiresta_2M1On3d7tFx2TFJuK2oaSrZ7KI9`,
		order: 2,
		label: 'Snoozed',
		description: 'Worker snoozed item for reminder-free remediation time',
		color: ProgressColor.SNOOZED,
		icon: ProgressIcon.SNOOZED,
		isResolvable: true,
		isIgnorable: true,
	},
	[RiskWithRecommendationStatus.DECLINED]: {
		code: RiskWithRecommendationStatus.DECLINED,
		id: `riwiresta_2M1Xg31jMFy8GupGBmMj5ciOe1M`,
		order: 3,
		label: 'Declined',
		description: "Worker declined recommendation; won't remediate item",
		color: ProgressColor.CANCELLED,
		icon: ProgressIcon.CANCELLED,
		isResolvable: true,
	},
	[RiskWithRecommendationStatus.NOT_APPROVED]: {
		code: RiskWithRecommendationStatus.NOT_APPROVED,
		id: `riwiresta_2LTzNSbWAbxKZeHtyX1qFNn8SJD`,
		order: 5,
		label: 'Not Approved',
		description: 'Recommended item reviewed by Independently and not approved',
		color: ProgressColor.FAIL,
		icon: ProgressIcon.FAIL,
		isResolvable: true,
		isSnoozable: true,
		isIgnorable: true,
		isComplete: true,
	},
	[RiskWithRecommendationStatus.APPROVED]: {
		code: RiskWithRecommendationStatus.APPROVED,
		id: `riwiresta_2LTzNRMuZ55HcsV641S2uYnZVxI`,
		order: 6,
		label: 'Approved',
		description: "Worker's recommended action or proof independently reviewed and approved",
		color: ProgressColor.PASS,
		icon: ProgressIcon.PASS,
		isComplete: true,
	},
});
// console.debug('riskWithRecommendationStatuses:', JSON.stringify(riskWithRecommendationStatuses.toArray()));
