// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'engagementReports';
const repository = 'EngagementReport';

export enum EngagementReportScopes {
	'Create' = 'engagementReports:create',
	'Read' = 'engagementReports:read',
}

const namespace = 'engreptsco';

export const engagementReportScopes: AbilityScopeMap<EngagementReportScopes> = Map<
	EngagementReportScopes,
	AbilityScopeItem<EngagementReportScopes>
>({
	[EngagementReportScopes.Create]: {
		code: EngagementReportScopes.Create,
		id: `${namespace}_2RvWRmNDHMuNR5CEOtG4Yckf7KO`,
		order: 0,
		icon: 'circle-user',
		label: 'EngagementReport Create',
		collection,
		repository,
	},
	[EngagementReportScopes.Read]: {
		code: EngagementReportScopes.Read,
		id: `${namespace}_2RvWTm4FRIR4Hos2ocIMNI4Y14S`,
		order: 1,
		icon: 'circle-user',
		label: 'EngagementReport Read',
		collection,
		repository,
	},
});
