// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary, Hex } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { ActiveColor } from './constants/dictionary-progress.constant';

export enum RecordStatus {
	DRAFT = 'draft',
	LIVE = 'live',
}

export interface RecordStatusItem extends BaseDictionary {
	code: RecordStatus;
	id: `${IdNamespace.RecordStatus}_${string}`;
	order: number;
	icon: string;
	label: string;
	desc: string;
	color: Hex;
}

export const recordStatuses = Map<RecordStatus | `${RecordStatus}`, RecordStatusItem>({
	[RecordStatus.DRAFT]: {
		code: RecordStatus.DRAFT,
		id: 'recsta_2FeMZSRp3IDyJvbJtu0K1Fm42A9',
		order: 0,
		icon: 'pen-ruler',
		// label: i18n.t('recordStatuses.label.draft', { ns: 'dictionaries' }),
		label: 'Draft',
		// desc: i18n.t('recordStatuses.desc.draft', { ns: 'dictionaries' }),
		desc: '',
		color: ActiveColor.DRAFT,
	},
	[RecordStatus.LIVE]: {
		code: RecordStatus.LIVE,
		id: 'recsta_2FeMZX2p1ly4r8uNnlpAo9yzyT0',
		order: 1,
		icon: 'truck-fast',
		// label: i18n.t('recordStatuses.label.live', { ns: 'dictionaries'  }),
		label: 'Live',
		// desc: i18n.t('recordStatuses.desc.live', { ns: 'dictionaries' }),
		desc: '',
		color: ActiveColor.LIVE,
	},
});
