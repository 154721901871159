// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum LocationType {
	CLIENT = 'client',
	REMOTE = 'remote',
	HOME = 'home',
	OTHER = 'other',
}

export interface LocationTypeItem extends BaseDictionary {
	code: LocationType;
	id: `${IdNamespace.LocationType}_${string}`;
	order: number;
	icon: string;
	label: string;
	description: string;
}

export const locationTypes = Map<LocationType | `${LocationType}`, LocationTypeItem>({
	[LocationType.CLIENT]: {
		code: LocationType.CLIENT,
		// id: '62857703e561f0b242fc0e0c',
		id: 'locty_2FeOkTUE4MRMcWwMb2qfou3Wk85',
		order: 1,
		icon: 'building',
		label: 'Onsite - Client',
		description: 'Any location owned or operated by the Client',
	},
	[LocationType.REMOTE]: {
		code: LocationType.REMOTE,
		// id: '62857708da9bb7aaaa7f233a',
		id: 'locty_2FeOkOGqDhERLkfYmfqDVKwlPX3',
		order: 2,
		icon: 'house-laptop',
		label: 'Offsite - Remote',
		description: 'Any remote location (Incl. rental space, WeWork, or other co-working)',
	},
	[LocationType.HOME]: {
		code: LocationType.HOME,
		// id: '628576ff2a0470764014d07f',
		id: 'locty_2FeOkQtMprZfjZK0gOcsdscqYiW',
		order: 0,
		icon: 'home',
		label: 'Offsite - Home',
		description: 'Any residential location (IE. work from home)',
	},
	[LocationType.OTHER]: {
		code: LocationType.OTHER,
		// id: '6285770edf0f66b18304d83e',
		id: 'locty_2FeOkOUB2oQS6jOshVs3dbx3ENb',
		order: 3,
		icon: 'map-marker',
		label: 'Other',
		description: 'Any other/unknown location',
	},
});
