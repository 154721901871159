// import i18n from 'libs/localization/i18n-instance.service';
import type { BucketLocationConstraint } from '@aws-sdk/client-s3';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import type { BaseDictionary } from './types/dictionaries.type';

export enum DataZone {
	US = 'US',
	CA = 'CA',
	UK = 'UK',
}

export interface dataZoneItem extends BaseDictionary {
	code: DataZone;
	id: `${IdNamespace.DataZone}_${string}`;
	order: number;
	label: string;
	domainNamePrefix: string;
	infrastructure: {
		mongoDBShardKey: string;
		awsS3Bucket: BucketLocationConstraint | 'us-east-1';
	};
}

export const dataZones = Map<DataZone | `${DataZone}`, dataZoneItem>({
	[DataZone.US]: {
		code: DataZone.US,
		id: 'dazo_2FeLNWh3DjqYwoNtp8x8YIeaaDN',
		order: 1,
		// label: i18n.t('yes', { ns: 'app' }),
		label: 'USA',
		domainNamePrefix: 'us',
		infrastructure: {
			mongoDBShardKey: 'US',
			awsS3Bucket: 'us-east-1',
		},
	},
	[DataZone.CA]: {
		code: DataZone.CA,
		id: 'dazo_2FeLNbVXs7dxxI27OZCYU8uqdyI',
		order: 2,
		// label: i18n.t('no', { ns: 'app' }),
		label: 'Canada',
		domainNamePrefix: 'ca',
		infrastructure: {
			mongoDBShardKey: 'CA',
			awsS3Bucket: 'ca-central-1',
		},
	},
	[DataZone.UK]: {
		code: DataZone.UK,
		id: 'dazo_2FeLNdyJgVr8A8aHSmUMhf1OIjs',
		order: 2,
		// label: i18n.t('no', { ns: 'app' }),
		label: 'United Kingdom',
		domainNamePrefix: 'de',
		infrastructure: {
			mongoDBShardKey: 'DE',
			awsS3Bucket: 'eu-central-1',
		},
	},
});

// @TODO:
// interface exampleItem {
// 	zone: keyof typeof dataZones; // @TODO: TEMP for debugging
// }
