// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'roles';
const repository = 'Role';

export enum RoleScopes {
	'Create' = 'roles:create',
	'Read' = 'roles:read',
	'Update' = 'roles:update',
	'Delete' = 'roles:delete',
	'Manage' = 'roles:manage',
}

const namespace = 'rolesco';

export const roleScopes: AbilityScopeMap<RoleScopes> = Map<
	RoleScopes,
	AbilityScopeItem<RoleScopes>
>({
	[RoleScopes.Create]: {
		code: RoleScopes.Create,
		id: `${namespace}_2FXlP7wseam4spFYXoYHcyKNAKa`,
		order: 0,
		icon: 'circle-user',
		label: 'Role Create',
		collection,
		repository,
	},
	[RoleScopes.Read]: {
		code: RoleScopes.Read,
		id: `${namespace}_2FXlQMU6Vgue1MvBRMBJW0sE8qv`,
		order: 1,
		icon: 'circle-user',
		label: 'Role Read',
		collection,
		repository,
	},
	[RoleScopes.Update]: {
		code: RoleScopes.Update,
		id: `${namespace}_2FXlS1792jFIMooUZYBV62PPia5`,
		order: 2,
		icon: 'circle-user',
		label: 'Role Update',
		collection,
		repository,
	},
	[RoleScopes.Delete]: {
		code: RoleScopes.Delete,
		id: `${namespace}_2FXlTeRV7YBhs3VGEwyZqmfVUYy`,
		order: 3,
		icon: 'circle-user',
		label: 'Role Delete',
		collection,
		repository,
	},
	[RoleScopes.Manage]: {
		code: RoleScopes.Manage,
		id: `${namespace}_2FXlNnYrvnXaU8WVT6n0uttMa7S`,
		order: 4,
		icon: 'circle-user',
		label: 'Role Manage',
		alias: [RoleScopes.Create, RoleScopes.Read, RoleScopes.Update, RoleScopes.Delete],
		collection,
		repository,
	},
});
