// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'jurisdictions';
const repository = 'Jurisdiction';

export enum JurisdictionScopes {
	'Create' = 'jurisdictions:create',
	'Read' = 'jurisdictions:read',
	'Update' = 'jurisdictions:update',
	'Delete' = 'jurisdictions:delete',
	'Manage' = 'jurisdictions:manage',
}

const namespace = 'jursco';

export const jurisdictionScopes: AbilityScopeMap<JurisdictionScopes> = Map<
	JurisdictionScopes,
	AbilityScopeItem<JurisdictionScopes>
>({
	[JurisdictionScopes.Create]: {
		code: JurisdictionScopes.Create,
		id: `${namespace}_2VjsQI0GdLQ1ugcrQav7sxCK2hm`,
		order: 0,
		icon: 'circle-user',
		label: 'Jurisdiction Create',
		collection,
		repository,
	},
	[JurisdictionScopes.Read]: {
		code: JurisdictionScopes.Read,
		id: `${namespace}_2VjsQErnlApWJXwFCt0Cbq7DGMe`,
		order: 1,
		icon: 'circle-user',
		label: 'Jurisdiction Read',
		collection,
		repository,
	},
	[JurisdictionScopes.Update]: {
		code: JurisdictionScopes.Update,
		id: `${namespace}_2VjsQCfLVgh0WRHhRbaLFjDdutN`,
		order: 2,
		icon: 'circle-user',
		label: 'Jurisdiction Update',
		collection,
		repository,
	},
	[JurisdictionScopes.Delete]: {
		code: JurisdictionScopes.Delete,
		id: `${namespace}_2VjsQElmoNd1rccS2MXzdIr9fmI`,
		order: 3,
		icon: 'circle-user',
		label: 'Jurisdiction Delete',
		collection,
		repository,
	},
	[JurisdictionScopes.Manage]: {
		code: JurisdictionScopes.Manage,
		id: `${namespace}_2VjsQIbzC37gzb9cnzK5BLLIEkc`,
		order: 4,
		icon: 'circle-user',
		label: 'Jurisdiction Manage',
		alias: [
			JurisdictionScopes.Create,
			JurisdictionScopes.Read,
			JurisdictionScopes.Update,
			JurisdictionScopes.Delete,
		],
		collection,
		repository,
	},
});
