import { Map } from 'immutable';
import { merge } from 'lodash';
import { BaseDictionary } from './types/dictionaries.type';
import { SystemLocale, LanguageItem, languages } from './languages';
// import i18n from 'libs/localization/i18n-instance.service';

export interface SystemLanguageItem extends LanguageItem {
	code: SystemLocale;
	label: string;
}

const _languages = Map<SystemLocale | `${SystemLocale}`, Partial<SystemLanguageItem>>({
	[SystemLocale.EN]: {
		code: SystemLocale.EN,
		// label: i18n.t('systemLanguages.label.english', { ns: 'dictionaries' }),
		label: 'English',
	},
	[SystemLocale.FR_CA]: {
		code: SystemLocale.FR_CA,
		// label: i18n.t('systemLanguages.label.french', { ns: 'dictionaries' }),
		label: 'French',
	},
});

export const systemLanguages: Map<SystemLocale | `${SystemLocale}`, SystemLanguageItem> =
	languages.mergeDeep(_languages as Iterable<[SystemLocale, LanguageItem]>);
