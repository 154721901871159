// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../../+types/scope-item.type';
import { IdNamespace } from '../../../shared/database/constants/id-namespaces.constant';

const collection = 'accessTokens';
const repository = 'AccessToken';

export enum AccessTokenScopes {
	'Create' = 'accessTokens:create',
	'Read' = 'accessTokens:read',
	'Update' = 'accessTokens:update',
	'Delete' = 'accessTokens:delete',
}

const NS: string = IdNamespace.AccessTokenScope;

export const accessTokenScopes: AbilityScopeMap<AccessTokenScopes> = Map<
	AccessTokenScopes,
	AbilityScopeItem<AccessTokenScopes>
>({
	[AccessTokenScopes.Create]: {
		code: AccessTokenScopes.Create,
		id: `${NS}_2Gt5Ee59hgDUnIME3SNas0y5pZv`,
		order: 0,
		icon: 'circle-user',
		label: 'AccessToken Create',
		collection,
		repository,
	},
	[AccessTokenScopes.Read]: {
		code: AccessTokenScopes.Read,
		id: `${NS}_2Gt5EYrYtLxVRwiU0UIM8E8cwj9`,
		order: 1,
		icon: 'circle-user',
		label: 'AccessToken Read',
		collection,
		repository,
	},
	[AccessTokenScopes.Update]: {
		code: AccessTokenScopes.Update,
		id: `${NS}_2Gt5EcYPAZzclZSFxgEmRi53LsR`,
		order: 1,
		icon: 'circle-user',
		label: 'AccessToken Update',
		collection,
		repository,
	},
	[AccessTokenScopes.Delete]: {
		code: AccessTokenScopes.Delete,
		id: `${NS}_2Gt5EXmvy4PF94vjypAhTjngi7H`,
		order: 1,
		icon: 'circle-user',
		label: 'AccessToken Delete',
		collection,
		repository,
	},
});
