// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'companies';
const repository = 'Partner';

export enum PartnerScopes {
	'Create' = 'partners:create',
	'Read' = 'partners:read',
	'Update' = 'partners:update',
	'Delete' = 'partners:delete',
	'Manage' = 'partners:manage',
	'Import' = 'partners:import',
}

const namespace = 'comsco';

export const partnerScopes: AbilityScopeMap<PartnerScopes> = Map<
	PartnerScopes,
	AbilityScopeItem<PartnerScopes>
>({
	[PartnerScopes.Create]: {
		code: PartnerScopes.Create,
		id: `${namespace}_2gsiCT30fLIfkjl1xeIwre5A4uK`,
		order: 0,
		icon: 'circle-user',
		label: 'Partner Create',
		collection,
		repository,
	},
	[PartnerScopes.Read]: {
		code: PartnerScopes.Read,
		id: `${namespace}_2gsiCQseINnc7gawqkMX6ZOl9NC`,
		order: 1,
		icon: 'circle-user',
		label: 'Partner Read',
		collection,
		repository,
	},
	[PartnerScopes.Update]: {
		code: PartnerScopes.Update,
		id: `${namespace}_2gsiCMvev1ZbQt2d4VgkUZtuaGj`,
		order: 2,
		icon: 'circle-user',
		label: 'Partner Update',
		collection,
		repository,
	},
	[PartnerScopes.Delete]: {
		code: PartnerScopes.Delete,
		id: `${namespace}_2gsiCQvHsKBRyWjGE01jDFssrEY`,
		order: 3,
		icon: 'circle-user',
		label: 'Partner Delete',
		collection,
		repository,
	},
	[PartnerScopes.Import]: {
		code: PartnerScopes.Import,
		id: `${namespace}_2gsiCOnvK5BDThPBVu5PrPbcfwo`,
		order: 4,
		icon: 'circle-user',
		label: 'Partner Import',
		collection,
		repository,
	},
	[PartnerScopes.Manage]: {
		code: PartnerScopes.Manage,
		id: `${namespace}_2gsiCOuD3088cmkPSk6pOsmkOcU`,
		order: 5,
		icon: 'circle-user',
		label: 'Partner Manage',
		alias: [
			PartnerScopes.Create,
			PartnerScopes.Read,
			PartnerScopes.Update,
			PartnerScopes.Delete,
		],
		collection,
		repository,
	},
});
