// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'users';
const repository = 'Administrator';

export enum AdministratorScopes {
	'Create' = 'administrators:create',
	'Read' = 'administrators:read',
	'Update' = 'administrators:update',
	'Delete' = 'administrators:delete',
	'Manage' = 'administrators:manage',
	'Import' = 'administrators:import',
	'UpdateDeleted' = 'administrators:updateDeleted',
}

const NS: string = IdNamespace.AdministratorScopes;

export const administratorScopes: AbilityScopeMap<AdministratorScopes> = Map<
	AdministratorScopes,
	AbilityScopeItem<AdministratorScopes>
>({
	[AdministratorScopes.Create]: {
		code: AdministratorScopes.Create,
		id: `${NS}_2Gt55Y4S1htnYPTRYLfO9H12624`,
		order: 0,
		icon: 'circle-user',
		label: 'Administrator Create',
		collection,
		repository,
	},
	[AdministratorScopes.Read]: {
		code: AdministratorScopes.Read,
		id: `${NS}_2Gt55VhN4soMhY0SSI7vNWHasHK`,
		order: 1,
		icon: 'circle-user',
		label: 'Administrator Read',
		collection,
		repository,
	},
	[AdministratorScopes.Update]: {
		code: AdministratorScopes.Update,
		id: `${NS}_2Gt55VQNYKksyxKnpfmColvCTLL`,
		order: 2,
		icon: 'circle-user',
		label: 'Administrator Update',
		collection,
		repository,
	},
	[AdministratorScopes.Delete]: {
		code: AdministratorScopes.Delete,
		id: `${NS}_2Gt55abRnwOFtU6q29nfSOmxI8C`,
		order: 3,
		icon: 'circle-user',
		label: 'Administrator Delete',
		collection,
		repository,
	},
	[AdministratorScopes.Manage]: {
		code: AdministratorScopes.Manage,
		id: `${NS}_2Gt55b3q31lwsquRpcvwGGWQYRr`,
		order: 4,
		icon: 'circle-user',
		label: 'Administrator Manage',
		alias: [
			AdministratorScopes.Create,
			AdministratorScopes.Read,
			AdministratorScopes.Update,
			AdministratorScopes.Delete,
		],
		collection,
		repository,
	},
	[AdministratorScopes.Import]: {
		code: AdministratorScopes.Import,
		id: `${NS}_2cy3bDxwU98QDKrNE2YoPsf00xB`,
		order: 5,
		icon: 'circle-user',
		label: 'Administrator Import',
		collection,
		repository,
	},
	[AdministratorScopes.UpdateDeleted]: {
		code: AdministratorScopes.UpdateDeleted,
		id: `${NS}_2fFdNpJAIU4ZgASWTwelcNHSLy3`,
		order: 6,
		icon: 'circle-user',
		label: 'Administrator Update Deleted',
		collection,
		repository,
	},
});
