import { Box } from '@mui/material';
import { t } from 'i18next';
import type { ComponentType, ReactElement } from 'react';
import type {
	LoginAttemptInfo,
	NormalisedConfig,
	UserInputCodeFormHeaderProps,
} from 'supertokens-auth-react/lib/build/recipe/passwordless/types';
import type { RecipeInterface } from 'supertokens-web-js/recipe/passwordless';
import { Typography } from '@mui/material';
import { Image } from 'mui-image';
// @ts-ignore: Bug with type "declare module" not working
import codeImageData from 'assets/images/undraw_opened.svg?inline';

const PasswordlessUserInputCodeFormHeader = ({
	DefaultComponent,
	...props
}: {
	DefaultComponent: ComponentType<UserInputCodeFormHeaderProps>;
	loginAttemptInfo: LoginAttemptInfo;
	recipeImplementation: RecipeInterface;
	config: NormalisedConfig;
}): ReactElement | null => (
	<Box
		sx={{
			overflow: 'auto',
		}}
	>
		<Typography variant='h4'>{t('check_email')}</Typography>
		<Typography variant='body1'>
			{t('code_desc', { email: props.loginAttemptInfo.contactInfo })}
		</Typography>
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				paddingY: '5px',
			}}
		>
			<Image
				src={codeImageData as string}
				alt=''
				duration={2500}
				errorIcon={null}
				height='100px'
				width='auto'
			/>
		</Box>
	</Box>
);

export default PasswordlessUserInputCodeFormHeader;
