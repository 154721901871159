// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'businessRegistrations';
const repository = 'BusinessRegistration';

export enum BusinessRegistrationScopes {
	'Create' = 'businessRegistrations:create',
	'Read' = 'businessRegistrations:read',
	'Update' = 'businessRegistrations:update',
	'Delete' = 'businessRegistrations:delete',
	'Manage' = 'businessRegistrations:manage',
}

const namespace = 'busregsco';

export const businessRegistrationScopes: AbilityScopeMap<BusinessRegistrationScopes> = Map<
	BusinessRegistrationScopes,
	AbilityScopeItem<BusinessRegistrationScopes>
>({
	[BusinessRegistrationScopes.Create]: {
		code: BusinessRegistrationScopes.Create,
		id: `${namespace}_2FX0pRpol7mikhrKVMLdte6STQK`,
		order: 0,
		icon: 'circle-user',
		label: 'BusinessRegistration Create',
		collection,
		repository,
	},
	[BusinessRegistrationScopes.Read]: {
		code: BusinessRegistrationScopes.Read,
		id: `${namespace}_2FX0rbsgUF83tECv0NRO8Afq5Yv`,
		order: 1,
		icon: 'circle-user',
		label: 'BusinessRegistration Read',
		collection,
		repository,
	},
	[BusinessRegistrationScopes.Update]: {
		code: BusinessRegistrationScopes.Update,
		id: `${namespace}_2FX1ZfVXnpftq26YUlBXHGMAwKj`,
		order: 2,
		icon: 'circle-user',
		label: 'BusinessRegistration Update',
		collection,
		repository,
	},
	[BusinessRegistrationScopes.Delete]: {
		code: BusinessRegistrationScopes.Delete,
		id: `${namespace}_2FX1cCJiVBZpCTHHBoPaP8ufiUX`,
		order: 3,
		icon: 'circle-user',
		label: 'BusinessRegistration Delete',
		collection,
		repository,
	},
	[BusinessRegistrationScopes.Manage]: {
		code: BusinessRegistrationScopes.Manage,
		id: `${namespace}_2FX0aHWQUB4TAJZtRfCk6H23b2r`,
		order: 4,
		icon: 'circle-user',
		label: 'BusinessRegistration Manage',
		alias: [
			BusinessRegistrationScopes.Create,
			BusinessRegistrationScopes.Read,
			BusinessRegistrationScopes.Update,
			BusinessRegistrationScopes.Delete,
		],
		collection,
		repository,
	},
});
