// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../../+types/scope-item.type';
import { IdNamespace } from '../../../shared/database/constants/id-namespaces.constant';

const collection = 'nodeJobs';
const repository = 'NodeJob';

export enum NodeJobScopes {
	'Create' = 'nodeJobs:create',
	'Read' = 'nodeJobs:read',
	'Update' = 'nodeJobs:update',
	'Delete' = 'nodeJobs:delete',
	'Manage' = 'nodeJobs:manage',
}

const namespace = IdNamespace.AutomationJobScope;

export const nodeJobScopes: AbilityScopeMap<NodeJobScopes> = Map<
	NodeJobScopes,
	AbilityScopeItem<NodeJobScopes>
>({
	[NodeJobScopes.Create]: {
		code: NodeJobScopes.Create,
		id: `${namespace}_2OW7NHDOQfd1wNGZWK5qOOnTk7f`,
		order: 0,
		icon: 'circle-user',
		label: 'NodeJob Create',
		collection,
		repository,
	},
	[NodeJobScopes.Read]: {
		code: NodeJobScopes.Read,
		id: `${namespace}_2OW7OoByIMnZBV6H2O3ttmZAtAi`,
		order: 1,
		icon: 'circle-user',
		label: 'NodeJob Read',
		collection,
		repository,
	},
	[NodeJobScopes.Update]: {
		code: NodeJobScopes.Update,
		id: `${namespace}_2OW7QMOMeZKlXvhBiqliyfKoqXW`,
		order: 2,
		icon: 'circle-user',
		label: 'NodeJob Update',
		collection,
		repository,
	},
	[NodeJobScopes.Delete]: {
		code: NodeJobScopes.Delete,
		id: `${namespace}_2OW7Rjrn1C5MbXUTEFwNVIpOMQO`,
		order: 3,
		icon: 'circle-user',
		label: 'SurveyQuestion Delete',
		collection,
		repository,
	},
	[NodeJobScopes.Manage]: {
		code: NodeJobScopes.Manage,
		id: `${namespace}_2OW7KKT0QxUmdx9LImSRLmN9CRc`,
		order: 4,
		icon: 'circle-user',
		label: 'NodeJob Manage',
		alias: [
			NodeJobScopes.Create,
			NodeJobScopes.Read,
			NodeJobScopes.Update,
			NodeJobScopes.Delete,
		],
		collection,
		repository,
	},
});
