// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'factors';
const repository = 'Factor';

export enum FactorScopes {
	'Create' = 'factors:create',
	'Read' = 'factors:read',
	'Update' = 'factors:update',
	'Delete' = 'factors:delete',
	'Manage' = 'factors:manage',
}

const namespace = 'factsco';

export const factorScopes: AbilityScopeMap<FactorScopes> = Map<
	FactorScopes,
	AbilityScopeItem<FactorScopes>
>({
	[FactorScopes.Create]: {
		code: FactorScopes.Create,
		id: `${namespace}_2FXeVToTnTgbF125XtMkrtdKrEq`,
		order: 0,
		icon: 'circle-user',
		label: 'Factor Create',
		collection,
		repository,
	},
	[FactorScopes.Read]: {
		code: FactorScopes.Read,
		id: `${namespace}_2FXeXRcTy2Bm6YfOLgFVvog2qkE`,
		order: 1,
		icon: 'circle-user',
		label: 'Factor Read',
		collection,
		repository,
	},
	[FactorScopes.Update]: {
		code: FactorScopes.Update,
		id: `${namespace}_2FXeYy093VDVFc7NNd31iNHfQvK`,
		order: 2,
		icon: 'circle-user',
		label: 'Factor Update',
		collection,
		repository,
	},
	[FactorScopes.Delete]: {
		code: FactorScopes.Delete,
		id: `${namespace}_2FXebXeOljNDdqLHVphvGdSvRLs`,
		order: 3,
		icon: 'circle-user',
		label: 'Factor Delete',
		collection,
		repository,
	},
	[FactorScopes.Manage]: {
		code: FactorScopes.Manage,
		id: `${namespace}_2FXeTcM6ca5x8N8e27ft8Hti94z`,
		order: 4,
		icon: 'circle-user',
		label: 'Factor Manage',
		alias: [FactorScopes.Create, FactorScopes.Read, FactorScopes.Update, FactorScopes.Delete],
		collection,
		repository,
	},
});
