// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'companies';
const repository = 'ManagedServiceProvider';

export enum ManagedServiceProviderScopes {
	'Create' = 'managedServiceProviders:create',
	'Read' = 'managedServiceProviders:read',
	'Update' = 'managedServiceProviders:update',
	'Delete' = 'managedServiceProviders:delete',
	'Manage' = 'managedServiceProviders:manage',
}

const namespace = 'comsco';

export const managedServiceProviderScopes: AbilityScopeMap<ManagedServiceProviderScopes> = Map<
	ManagedServiceProviderScopes,
	AbilityScopeItem<ManagedServiceProviderScopes>
>({
	[ManagedServiceProviderScopes.Create]: {
		code: ManagedServiceProviderScopes.Create,
		id: `${namespace}_2FXkDpO4FjBH39k5wcE1WtJJ8NB`,
		order: 0,
		icon: 'circle-user',
		label: 'ManagedServiceProvider Create',
		collection,
		repository,
	},
	[ManagedServiceProviderScopes.Read]: {
		code: ManagedServiceProviderScopes.Read,
		id: `${namespace}_2FXkFA6UPodAr5wXwZe3mPaISNU`,
		order: 1,
		icon: 'circle-user',
		label: 'ManagedServiceProvider Read',
		collection,
		repository,
	},
	[ManagedServiceProviderScopes.Update]: {
		code: ManagedServiceProviderScopes.Update,
		id: `${namespace}_2FXkGVQY4bp4YfDq6NUUUhA0Cfj`,
		order: 2,
		icon: 'circle-user',
		label: 'ManagedServiceProvider Update',
		collection,
		repository,
	},
	[ManagedServiceProviderScopes.Delete]: {
		code: ManagedServiceProviderScopes.Delete,
		id: `${namespace}_2FXkJ1ts1e60hnaewPgJxzLwqLg`,
		order: 3,
		icon: 'circle-user',
		label: 'ManagedServiceProvider Delete',
		collection,
		repository,
	},
	[ManagedServiceProviderScopes.Manage]: {
		code: ManagedServiceProviderScopes.Manage,
		id: `${namespace}_2FXkC8xc5nTXCiYnNe67EO84MLp`,
		order: 4,
		icon: 'circle-user',
		label: 'ManagedServiceProvider Manage',
		alias: [
			ManagedServiceProviderScopes.Create,
			ManagedServiceProviderScopes.Read,
			ManagedServiceProviderScopes.Update,
			ManagedServiceProviderScopes.Delete,
		],
		collection,
		repository,
	},
});
