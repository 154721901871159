// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../../+types/scope-item.type';
import { IdNamespace } from '../../../shared/database/constants/id-namespaces.constant';

const collection = 'scheduleJobs';
const repository = 'ScheduleJob';

export enum ScheduleJobScopes {
	'Create' = 'scheduleJobs:create',
	'Read' = 'scheduleJobs:read',
	'Update' = 'scheduleJobs:update',
	'Delete' = 'scheduleJobs:delete',
	'Manage' = 'scheduleJobs:manage',
}

const namespace = IdNamespace.ScheduleJobScope;

export const scheduleJobScopes: AbilityScopeMap<ScheduleJobScopes> = Map<
	ScheduleJobScopes,
	AbilityScopeItem<ScheduleJobScopes>
>({
	[ScheduleJobScopes.Create]: {
		code: ScheduleJobScopes.Create,
		id: `${namespace}_2OvWlCuw7AOgI0t0XNaFJqOjFoP`,
		order: 0,
		icon: 'circle-user',
		label: 'ScheduleJob Create',
		collection,
		repository,
	},
	[ScheduleJobScopes.Read]: {
		code: ScheduleJobScopes.Read,
		id: `${namespace}_2OvWmD7w6u41o4wx1zDCUaM7fL8`,
		order: 1,
		icon: 'circle-user',
		label: 'ScheduleJob Read',
		collection,
		repository,
	},
	[ScheduleJobScopes.Update]: {
		code: ScheduleJobScopes.Update,
		id: `${namespace}_2OvWnFLVxKP2r1QKjA5nIuvDc5H`,
		order: 2,
		icon: 'circle-user',
		label: 'ScheduleJob Update',
		collection,
		repository,
	},
	[ScheduleJobScopes.Delete]: {
		code: ScheduleJobScopes.Delete,
		id: `${namespace}_2OvWpNnLyt5nvvCPoIWb9JMbiPk`,
		order: 3,
		icon: 'circle-user',
		label: 'SurveyQuestion Delete',
		collection,
		repository,
	},
	[ScheduleJobScopes.Manage]: {
		code: ScheduleJobScopes.Manage,
		id: `${namespace}_2OvWkLWTbz6jskV6b4gL0kh7Yuv`,
		order: 4,
		icon: 'circle-user',
		label: 'ScheduleJob Manage',
		alias: [
			ScheduleJobScopes.Create,
			ScheduleJobScopes.Read,
			ScheduleJobScopes.Update,
			ScheduleJobScopes.Delete,
		],
		collection,
		repository,
	},
});
