// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum FieldStateList {
	EDITABLE = 'editable',
	VISIBLE = 'visible',
	HIDDEN = 'hidden',
}

export interface FieldStateListItem extends BaseDictionary {
	code: FieldStateList;
	id: `${IdNamespace.FieldStateList}_${string}`;
	order: number;
	label: string;
}

export const fieldStateList = Map<FieldStateList | `${FieldStateList}`, FieldStateListItem>({
	[FieldStateList.EDITABLE]: {
		code: FieldStateList.EDITABLE,
		id: 'fldstali_2FeNzTqPl84WScDarrMAr4ADFuM',
		order: 1,
		// label: i18n.t('editable', { ns: 'app' }),
		label: 'Editable',
	},
	[FieldStateList.VISIBLE]: {
		code: FieldStateList.VISIBLE,
		id: 'fldstali_2FeNzU6nngbG0NZg6idKp647d81',
		order: 1,
		// label: i18n.t('visible', { ns: 'app' }),
		label: 'Visible',
	},
	[FieldStateList.HIDDEN]: {
		code: FieldStateList.HIDDEN,
		id: 'fldstali_2FeNzR5lVxjj6LBOT0u6zU5xTor',
		order: 1,
		// label: i18n.t('hidden', { ns: 'app' }),
		label: 'Hidden',
	},
});
