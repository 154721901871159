// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'companies';
const repository = 'Client';

export enum ClientScopes {
	'Create' = 'clients:create',
	'Read' = 'clients:read',
	'Update' = 'clients:update',
	'Delete' = 'clients:delete',
	'Manage' = 'clients:manage',
	'Import' = 'clients:import',
	'EditDueDateOffset' = 'clients:editDueDateOffset',
}

const namespace = 'comsco';

export const clientScopes: AbilityScopeMap<ClientScopes> = Map<
	ClientScopes,
	AbilityScopeItem<ClientScopes>
>({
	[ClientScopes.Create]: {
		code: ClientScopes.Create,
		id: `${namespace}_2FX2mnb2Psg2tGqBiNg3qVqn0U9`,
		order: 0,
		icon: 'circle-user',
		label: 'Client Create',
		collection,
		repository,
	},
	[ClientScopes.Read]: {
		code: ClientScopes.Read,
		id: `${namespace}_2FX2psoMxMrIWiVvroqrr3wclPS`,
		order: 1,
		icon: 'circle-user',
		label: 'Client Read',
		collection,
		repository,
	},
	[ClientScopes.Update]: {
		code: ClientScopes.Update,
		id: `${namespace}_2FX2ro9ihRbG8Y2jC5ZazwaQiE9`,
		order: 2,
		icon: 'circle-user',
		label: 'Client Update',
		collection,
		repository,
	},
	[ClientScopes.Delete]: {
		code: ClientScopes.Delete,
		id: `${namespace}_2FX2tdg7BOhO1vTVIKZ8dyyPsmy`,
		order: 3,
		icon: 'circle-user',
		label: 'Client Delete',
		collection,
		repository,
	},
	[ClientScopes.Manage]: {
		code: ClientScopes.Manage,
		id: `${namespace}_2FX2k02honzDuLToIPummmXHrXF`,
		order: 4,
		icon: 'circle-user',
		label: 'Client Manage',
		alias: [ClientScopes.Create, ClientScopes.Read, ClientScopes.Update, ClientScopes.Delete],
		collection,
		repository,
	},
	[ClientScopes.Import]: {
		code: ClientScopes.Import,
		id: `${namespace}_2dP29LxOZks4O89xgXhr0Q3kFs4`,
		order: 5,
		icon: 'circle-user',
		label: 'Client Import',
		collection,
		repository,
	},
	[ClientScopes.EditDueDateOffset]: {
		code: ClientScopes.EditDueDateOffset,
		id: `${namespace}_2WBlzLZtwcW8YuZNHiVX1VMniyl`,
		order: 6,
		icon: 'circle-user',
		label: 'Client Edit Due Date Offset',
		collection,
		repository,
	},
});
