import { systemLanguages, type SystemLanguageItem } from '@dictionaries/system-languages';
import type { addPrefix } from '@indly/types/prefix.type';
import type { SystemLocale } from '@openapi/models';
import type {
	GlobalConfigurationDefaultsKeys,
	GlobalConfigurationSettingsKeys,
} from '@types/global-configuration.type';
import { AppConfig } from 'configs/app.config';
import { get, has, isArray, isNil, isString } from 'lodash';
import { vanillaStore } from 'stores/vanilla.store';
import { Pattern, match } from 'ts-pattern';

// @TODO:
// import { Company } from '@openapi/models/company';
// import { UserWithAuthEmbedsWithoutCompanies } from '@openapi/models/userWithAuthEmbedsWithoutCompanies';
// import type { PartialDeep } from 'type-fest';

export type AllSettingsKeys =
	| 'settings'
	| addPrefix<GlobalConfigurationSettingsKeys, 'settings.'>
	| 'defaults'
	| addPrefix<GlobalConfigurationDefaultsKeys, 'defaults.'>;

const settings = {
	get: <T = unknown>(path: AllSettingsKeys, skipUser?: boolean, forceUserReturn?: boolean): T => {
		const { user, company } = vanillaStore.getState();

		const value: unknown = match<
			{ path: string; skipUser: boolean; forceUserReturn: boolean },
			unknown
		>({
			path,
			skipUser: skipUser ?? false,
			forceUserReturn: forceUserReturn ?? false,
		})
			.with(
				{
					forceUserReturn: true,
				},
				() => get(user, path)
			)
			.with(
				{
					skipUser: false,
					path: Pattern.when((p: string) => has(user, p)),
				},
				() => get(user, path)
			)
			.with(
				{
					path: Pattern.when((p: string) => has(company, p)),
				},
				() => get(company, path)
			)
			.otherwise(() => get(AppConfig, path));

		return match(path)
			.with('settings.locale', (p: 'settings.locale') => {
				const dictValue: SystemLanguageItem | undefined = systemLanguages.get(
					value as SystemLocale
				);
				// console.debug('[DEBUG] dictValue:', dictValue);

				if (!isNil(dictValue)) {
					return dictValue;
				}

				return systemLanguages.get(get(AppConfig, p));
			})
			.otherwise(() => value);
	},
	getArray: <T = unknown>(path: AllSettingsKeys, skipUser?: boolean): Array<T> => {
		let returnArray: unknown = [];

		const { user, company } = vanillaStore.getState();

		if (!skipUser && has(user, path)) returnArray = get(user, path) as unknown;
		else if (has(company, path)) returnArray = get(company, path) as unknown;
		else returnArray = get(AppConfig, path) as unknown;

		return isArray(returnArray) ? returnArray : [];
	},
	getString: <T = string>(path: AllSettingsKeys, skipUser?: boolean): T => {
		let returnString: unknown = '';

		const { user, company } = vanillaStore.getState();

		if (!skipUser && has(user, path)) returnString = get(user, path) as unknown;
		else if (has(company, path)) returnString = get(company, path) as unknown;
		else returnString = get(AppConfig, path) as unknown;

		return isString(returnString) ? returnString : '';
	},
};

export default settings;
