// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'stages';
const repository = 'Stage';

export enum StageScopes {
	'Create' = 'stages:create',
	'Read' = 'stages:read',
	'Update' = 'stages:update',
	'Delete' = 'stages:delete',
	'Manage' = 'stages:manage',
}

const namespace = 'stagesco';

export const stageScopes: AbilityScopeMap<StageScopes> = Map<
	StageScopes,
	AbilityScopeItem<StageScopes>
>({
	[StageScopes.Create]: {
		code: StageScopes.Create,
		id: `${namespace}_2FXo1FpFPhp1VYaNpKMXg2l5fxI`,
		order: 0,
		icon: 'circle-user',
		label: 'Stage Create',
		collection,
		repository,
	},
	[StageScopes.Read]: {
		code: StageScopes.Read,
		id: `${namespace}_2FXo2aCQO4GbSfUjgSrSjaLbDQl`,
		order: 1,
		icon: 'circle-user',
		label: 'Stage Read',
		collection,
		repository,
	},
	[StageScopes.Update]: {
		code: StageScopes.Update,
		id: `${namespace}_2FXo4AlUxT4fiNpkBgv0jGihwQI`,
		order: 2,
		icon: 'circle-user',
		label: 'Stage Update',
		collection,
		repository,
	},
	[StageScopes.Delete]: {
		code: StageScopes.Delete,
		id: `${namespace}_2FXo5PLonUXimp8CJm6CMYPO87A`,
		order: 3,
		icon: 'circle-user',
		label: 'Stage Delete',
		collection,
		repository,
	},
	[StageScopes.Manage]: {
		code: StageScopes.Manage,
		id: `${namespace}_2FXnzqvHjoRKKOhYcic9GI2EPwR`,
		order: 4,
		icon: 'circle-user',
		label: 'Stage Manage',
		alias: [StageScopes.Create, StageScopes.Read, StageScopes.Update, StageScopes.Delete],
		collection,
		repository,
	},
});
