import KSUID from 'ksuid';
import { find, isNil, isString, toPairs } from 'remeda';
import { getEnumValuesAsMappedArray } from '../common/helpers/enums.helpers';
import { IdNamespace } from './constants/id-namespaces.constant';
import { KSUID_REGEX } from './constants/schema.constant';
import { typeGuard } from 'tsafe/typeGuard';

export type Id<NS extends string = IdNamespace> = `${NS}_${string}`;

const validIdNamespaces = getEnumValuesAsMappedArray<IdNamespace>(IdNamespace);
// console.debug('[DEBUG] validIdNamespaces:', validIdNamespaces);

// KSUID Payload is 16-bytes
const PayloadLengthInBytes = 16;

export const SeedTime = 1684898482477;

export const SeedBuffer = '1234567890123';

const LegacyKsuidCodes = toPairs({
	cur_USD: 'cur_2FeL3rv5JRtUmLc2Dr1MnsQsC6K',
	'timezo_AM/Toronto': 'timezo_2FeQO451rrjiQRYF66EXbXa6lvC',
	'timezo_AM/Los_Angeles': 'timezo_2FeQO8Zy6ByhoPpWnmcJES4dG5m',
});

// @TODO: if name space is sent in the function, validate the name space
// if namespace is not sent the check if id has namespace, then check if exists in the allowed namespaces
// isValid("usr_134234slkfsdf", 'user?');

const split = <NS extends string = IdNamespace>(id: Id<NS>): [NS, KSUID] => {
	const [namespace, ksuidString]: string[] = id.split('_');

	if (!isString(namespace) || !isString(ksuidString)) {
		throw new TypeError(`Expected ID to have namespace "${namespace}" but received ${id}`);
	}

	return [namespace as NS, KSUID.parse(ksuidString)];
};

export function create<NS extends string = IdNamespace>(namespace?: NS): Id<NS> | string {
	// validateNamespace(namespace, true)
	const ksuid = KSUID.randomSync();
	return namespace ? `${namespace}_${ksuid.string}` : `${ksuid.string}`;
}

export function createFromCode<NS extends string = IdNamespace>(
	code: string,
	namespace?: NS
): Id<NS> | string {
	// console.debug('[DEBUG] createFromCode:', code, namespace);

	const legacyId = find(LegacyKsuidCodes, ([val, _rep]) => `${namespace}_${code}` === val);
	// console.debug('[DEBUG] legacyId:', legacyId);

	if (!isNil(legacyId)) {
		return legacyId[1];
	}

	const normalizedCode: string = code.replace(/[^\w\s]/gi, '');
	// console.debug('A:', normalizedCode.length, normalizedCode);

	if (normalizedCode.length > PayloadLengthInBytes) {
		throw new Error(
			`Indly KSUID code "${code}" is too long (greater than ${PayloadLengthInBytes})`
		);
	}

	const payload: Buffer = Buffer.from(
		normalizedCode.substring(0, PayloadLengthInBytes).padEnd(PayloadLengthInBytes, '0')
	);
	// console.debug('B:', payload.byteLength, payload.toString());

	const ksuid = KSUID.fromParts(SeedTime, payload);
	// console.debug('C:', ksuid.toString());

	const id = namespace ? `${namespace}_${ksuid.string}` : `${ksuid.string}`;
	// console.debug('D:', id);

	return id;
}

const isValidIndlyIdNamespace = (value?: string | IdNamespace): value is IdNamespace =>
	typeGuard<IdNamespace>(value, isString(value)) && validIdNamespaces.includes(value);

export function isValidIndlyKsuId<NS extends string = IdNamespace>(
	id: unknown,
	nameSpace?: IdNamespace | string
): id is Id<NS> {
	if (!isString(id)) {
		return false;
	}

	let idNameSpace: string | undefined = '';
	let ksuid: string | undefined = id;

	if (id.includes('_')) {
		[idNameSpace, ksuid] = id.split('_');
	}

	if (nameSpace && !isValidIndlyIdNamespace(idNameSpace)) {
		return false;
	}

	// @TODO: KSUID.isValid(KSUID.parse(id)) // Boolean
	return KSUID_REGEX.test(ksuid ?? '');
}

export function getNamespace<NS extends string>(id: Id<NS>): NS {
	const [namespace]: [NS, KSUID] = split(id);
	return namespace;
}

export function getKSUID<NS extends string>(id: Id<NS>): KSUID {
	const [_, ksuid]: [NS, KSUID] = split(id);
	return ksuid;
}

// export function isNamespace<NS extends string>(
// 	id: Id,
// 	namespace: NS,
// 	throwIfNotMatch: boolean = false
// ): id is Id<NS> {
// 	const isMatch = getNamespace(id) === namespace;

// 	if (!isMatch && throwIfNotMatch) {
// 		throw new TypeError(
// 			`Expected ID to have namespace "${namespace}" but received ${getNamespace(id)}`
// 		);
// 	}

// 	return isMatch;
// }
