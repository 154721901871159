// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'taxRegistrations';
const repository = 'TaxRegistration';

export enum TaxRegistrationScopes {
	'Create' = 'taxRegistrations:create',
	'Read' = 'taxRegistrations:read',
	'Update' = 'taxRegistrations:update',
	'Delete' = 'taxRegistrations:delete',
	'Manage' = 'taxRegistrations:manage',
}

const namespace = 'taxregsco';

export const taxRegistrationScopes: AbilityScopeMap<TaxRegistrationScopes> = Map<
	TaxRegistrationScopes,
	AbilityScopeItem<TaxRegistrationScopes>
>({
	[TaxRegistrationScopes.Create]: {
		code: TaxRegistrationScopes.Create,
		id: `${namespace}_2FXon3o8XTOwfkTORAhTr4b4Ygf`,
		order: 0,
		icon: 'circle-user',
		label: 'TaxRegistration Create',
		collection,
		repository,
	},
	[TaxRegistrationScopes.Read]: {
		code: TaxRegistrationScopes.Read,
		id: `${namespace}_2FXoodAaMTqDEmL583osOtcLV4R`,
		order: 1,
		icon: 'circle-user',
		label: 'TaxRegistration Read',
		collection,
		repository,
	},
	[TaxRegistrationScopes.Update]: {
		code: TaxRegistrationScopes.Update,
		id: `${namespace}_2FXoqbiq8frrUZpdRefaXga0xJg`,
		order: 2,
		icon: 'circle-user',
		label: 'TaxRegistration Update',
		collection,
		repository,
	},
	[TaxRegistrationScopes.Delete]: {
		code: TaxRegistrationScopes.Delete,
		id: `${namespace}_2FXosdIRzv51lhEJDS2PwEqSKQl`,
		order: 3,
		icon: 'circle-user',
		label: 'TaxRegistration Delete',
		collection,
		repository,
	},
	[TaxRegistrationScopes.Manage]: {
		code: TaxRegistrationScopes.Manage,
		id: `${namespace}_2FXolBOJa3mc2s0Dt1zan3DPvo6`,
		order: 4,
		icon: 'circle-user',
		label: 'TaxRegistration Manage',
		alias: [
			TaxRegistrationScopes.Create,
			TaxRegistrationScopes.Read,
			TaxRegistrationScopes.Update,
			TaxRegistrationScopes.Delete,
		],
		collection,
		repository,
	},
});
