// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'securityLogs';
const repository = 'SecurityLog';

export enum SecurityLogScopes {
	'Read' = 'securityLogs:read',
}

const namespace = 'seclogsco';

export const securityLogScopes: AbilityScopeMap<SecurityLogScopes> = Map<
	SecurityLogScopes,
	AbilityScopeItem<SecurityLogScopes>
>({
	[SecurityLogScopes.Read]: {
		code: SecurityLogScopes.Read,
		id: `${namespace}_2FXmkXmSdUVYi9zFMtyWCk9etPK`,
		order: 2,
		icon: 'circle-user',
		label: 'SecurityLog Read',
		collection,
		repository,
	},
});
