// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum BusinessStructure {
	SOLE_PROPRIETORSHIP = 'sole-proprietorship',
	GENERAL_PARTNERSHIP = 'general-partnership',
	LIMITED_PARTNERSHIP = 'limited-partnership',
	LLC = 'llc',
	CORPORATION = 'corporation',
}

export enum BusinessStructureSchemaKey {
	SOLE_PROPRIETORSHIP = 'soleProprietorship',
	GENERAL_PARTNERSHIP = 'generalPartnership',
	LIMITED_PARTNERSHIP = 'limitedPartnership',
	LLC = 'llc',
	CORPORATION = 'corporation',
}

export interface BusinessStructureItem extends BaseDictionary {
	code: BusinessStructure;
	id: `${IdNamespace.BusinessStructure}_${string}`;
	schemaKey: BusinessStructureSchemaKey;
	order: number;
	countries: string[]; // @TODO: Change to enum of country ISO code strings
	icon: string;
	label: string;
	description: string;
}

export const businessStructures = Map<
	BusinessStructure | `${BusinessStructure}`,
	BusinessStructureItem
>({
	[BusinessStructure.SOLE_PROPRIETORSHIP]: {
		code: BusinessStructure.SOLE_PROPRIETORSHIP,
		id: 'bustru_2FeJesxlbIJwt9bC3tXbHAENoNu',
		schemaKey: BusinessStructureSchemaKey.SOLE_PROPRIETORSHIP,
		order: 0,
		countries: ['CA', 'US'],
		icon: 'user-tie',
		label: 'Sole Proprietorship',
		description:
			"A sole proprietorship is an unincorporated business without a legal distinction between the company and the individual who owns and runs it. It's arguably the most straightforward business structure and is simple to set up and manage.",
	},
	[BusinessStructure.GENERAL_PARTNERSHIP]: {
		code: BusinessStructure.GENERAL_PARTNERSHIP,
		id: 'bustru_2FeJeqOqMULPfzYankegDFMsJs6',
		schemaKey: BusinessStructureSchemaKey.GENERAL_PARTNERSHIP,
		order: 1,
		countries: ['CA', 'US'],
		icon: 'people-group',
		label: 'General partnership (GP)',
		description:
			'A general partnership assumes the business is either evenly divided or split into percentages that have been documented and agreed upon beforehand.',
	},
	[BusinessStructure.LIMITED_PARTNERSHIP]: {
		code: BusinessStructure.LIMITED_PARTNERSHIP,
		id: 'bustru_2FeJerQ6vZGex91WiIp9giS9jyD',
		schemaKey: BusinessStructureSchemaKey.LIMITED_PARTNERSHIP,
		order: 2,
		countries: ['CA', 'US'],
		icon: 'people-roof',
		label: 'Limited partnership (LP)',
		description:
			'A limited partnership can limit both control and liability for specified partners.',
	},
	[BusinessStructure.LLC]: {
		code: BusinessStructure.LLC,
		schemaKey: BusinessStructureSchemaKey.LLC,
		id: 'bustru_2FeJeuDX8r7fQB3Esl0067vVZZC',

		order: 3,
		countries: ['US'],
		icon: 'users-between-lines',
		label: 'Limited liability company (LLC)',
		description:
			"A limited liability company, or LLC, is a hybrid business structure, combining the ease of a partnership with the liability protection found in corporations. It's technically a type of corporation.",
	},
	[BusinessStructure.CORPORATION]: {
		code: BusinessStructure.CORPORATION,
		schemaKey: BusinessStructureSchemaKey.CORPORATION,
		id: 'bustru_2FeJewNoQ2JDSryuv87C0eHBqqN',

		order: 4,
		countries: ['CA', 'US'],
		icon: 'buildings',
		label: 'Corporation',
		description:
			'A corporation is a legal entity separate from a person, so owners are free from personal liability, except for rare and extenuating circumstances. Instead, a corporation assumes all the risk instead of passing it on to the people who own and run it.',
	},
});
