/* eslint-disable no-param-reassign */
// #NOTE REQUIRED to import first before React and other dependencies
/////////////////////////////////////
//////////////////////////////////////////////////////////////
//////////

import { SuperTokensConfig } from '@configs/supertokens.config';
import { mainTheme } from '@indly/themes/main.theme';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license';
import type { GlobalConfigurationType } from '@types/global-configuration.type';
import { LOGIN_PAGE_PATH } from 'auth/constants/auth.constant';
import { isPathPublic } from 'auth/utils/auth-client-side.util';
import ClientSideRendering from 'components/utils/ClientSideRendering.component';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import settings from 'libs/settings';
import { useEffect } from 'react';
import SuperTokensReact, { SuperTokensWrapper } from 'supertokens-auth-react';
import { attemptRefreshingSession } from 'supertokens-auth-react/recipe/session';
import { AppProps, ServerSideProps } from 'types/next.type';

// import { redirectToAuth } from 'supertokens-auth-react/recipe/passwordless';

// #NOTE All global CSS is required to be imported in custom _app.tsx
import { SystemLanguageItem } from '@dictionaries/system-languages';
import '@exuanbo/file-icons-js/dist/css/file-icons.min.css';
import { ThemeProvider } from '@mui/material';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import 'assets/css/global.style.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'svix-react/style.css';

// @TODO:
// LicenseInfo.setLicenseKey(AppConfig.muiApiKey);
LicenseInfo.setLicenseKey(
	'fa83fcbe8948313245eee71a5892a3f5Tz05NDQyNyxFPTE3NTI4MDQ1NDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

if (typeof window !== 'undefined') {
	// we only want to call this init function on the frontend, so we check typeof window !== 'undefined'
	SuperTokensReact.init(SuperTokensConfig);
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

// Intended to ONLY run client-side (as a SPA - Single Page Application) in React 18+ / NextJS 12+ ala "SPA Mode"
const IndlyApp = ({ Component, pageProps }: AppProps<ServerSideProps>) => {
	// console.info(`[INFO] _app IndlyApp() INIT [isServerSide: ${JSON.stringify(typeof window === 'undefined')}], pageProps:`, pageProps);

	useEffect(() => {
		async function doRefresh() {
			if (pageProps.fromSupertokens?.action === 'TRY_REFRESH_TOKEN') {
				// console.debug('[DEBUG] _app useEffect() -> Session.attemptRefreshingSession()...');

				const isLoggedIn = await attemptRefreshingSession();
				// console.debug(`[DEBUG] isLoggedIn:`, isLoggedIn);

				if (isLoggedIn) {
					// eslint-disable-next-line no-restricted-globals
					location.reload();

					// #NOTE: IMPORTANT to only redirect to login page if not already on a public route
				} else if (!isPathPublic()) {
					console.debug(
						`[DEBUG] _app useEffect() -> Redirecting to auth page ${LOGIN_PAGE_PATH}...`
					);
					// @TODO:
					// // the user's session has expired. So we redirect
					// // them to the login page

					// eslint-disable-next-line no-restricted-globals
					window.location.replace(LOGIN_PAGE_PATH);
					// void redirectToAuth();
				}
			}
		}
		void doRefresh();
	}, [pageProps.fromSupertokens]);

	// @TODO: ??
	//	- I think we should simply keep showing the loading screen here...
	// in case the frontend needs to refresh, we show nothing.
	// Alternatively, you can show a spinner.
	// if (pageProps.fromSupertokens?.action === 'TRY_REFRESH_TOKEN') {
	// 	return null;
	// }

	// #REQUIRED To consume WhyDidYouRender dependency to prevent tree-shaking from removing import!
	pageProps.isWhyDidYouRenderEnabled =
		typeof WhyDidYouRender !== 'undefined' ? WhyDidYouRender.isEnabled() : false;

	const dateLocale: Locale = (
		settings.get<GlobalConfigurationType['settings']['locale']>(
			'settings.locale'
		) as SystemLanguageItem
	)?.plugins?.mui;

	return (
		<ClientSideRendering>
			<CssBaseline />
			<LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
				<ThemeProvider theme={mainTheme}>
					<SuperTokensWrapper>
						<div suppressHydrationWarning>
							<Component {...pageProps} />
						</div>
					</SuperTokensWrapper>
				</ThemeProvider>
			</LocalizationProvider>
		</ClientSideRendering>
	);
};

export default IndlyApp;
