/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
	captureException as captureSentryException,
	captureMessage as captureSentryMessage,
	configureScope as configureSentryScope,
	setUser as setSentryUser,
	withScope as withSentryScope,
} from '@sentry/nextjs';
import type {
	CaptureContext as CaptureSentryContext,
	SeverityLevel as SentrySeverityLevel,
	User as SentryUser,
} from '@sentry/types';

enum Severity {
	Fatal = 'fatal',
	Error = 'error',
	Warning = 'warning',
	Log = 'log',
	Info = 'info',
	Debug = 'debug',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _log = (
	level: SentrySeverityLevel = Severity.Error,
	message: unknown = '',
	metadata?: CaptureSentryContext
): void => {
	if (level === Severity.Fatal) level = Severity.Error;
	if (level === Severity.Warning) level = Severity.Log;
	console[level](message, metadata);
};

export const isEnabled = (): boolean => true; // @TODO: ENV !== 'development';

export const setTag = (key: string = '', value: Primitive = null): void => {
	if (!isEnabled()) return;

	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	configureSentryScope((scope) => scope.setTag(key, value));
};

export const setUser = (id: string = '', data: SentryUser = {}): void => {
	if (!isEnabled()) return;

	const user: SentryUser = {
		id,
		email: data?.email,
	};

	setSentryUser(user);
};

export const setExtra = (key: string = '', value: Primitive = null): void => {
	if (!isEnabled()) return;

	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	configureSentryScope((scope) => scope.setExtra(key, value));
};

export const captureException = (
	error: Error,
	metadata?: CaptureSentryContext,
	level: SentrySeverityLevel = Severity.Error
): void => {
	_log(level, error, metadata);
	if (!isEnabled()) return;

	withSentryScope((scope) => {
		scope.setLevel(level);
		scope.setExtras(metadata?.extra);
		captureSentryException(error, metadata);
	});
};

export const captureMessage = (
	message: string = '',
	metadata?: CaptureSentryContext,
	level: SentrySeverityLevel = Severity.Error
): void => {
	_log(level, message, metadata);
	if (!isEnabled()) return;

	withSentryScope((scope) => {
		scope.setLevel(level);
		scope.setExtras(metadata?.extra);
		captureSentryMessage(message, metadata);
	});
};

export const debug = (message: string = '', metadata?: CaptureSentryContext): void => {
	captureMessage(message, metadata, Severity.Debug);
};

export const log = (message: string = '', metadata?: CaptureSentryContext): void => {
	captureMessage(message, metadata, Severity.Log);
};

export const info = (message: string = '', metadata?: CaptureSentryContext): void => {
	captureMessage(message, metadata, Severity.Info);
};

export const warn = (message: string = '', metadata?: CaptureSentryContext): void => {
	captureMessage(message, metadata, Severity.Warning);
};

export const throwError = (message: string = '', metadata?: CaptureSentryContext): void => {
	captureMessage(message, metadata, Severity.Error);
};

export const fatal = (message: string = '', metadata?: CaptureSentryContext): void => {
	captureMessage(message, metadata, Severity.Fatal);
};
