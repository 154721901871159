// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'taxPayerStatuses';
const repository = 'TaxPayerStatus';

export enum TaxPayerStatusScopes {
	'Create' = 'taxPayerStatuses:create',
	'Read' = 'taxPayerStatuses:read',
	'Update' = 'taxPayerStatuses:update',
	'Delete' = 'taxPayerStatuses:delete',
	'Manage' = 'taxPayerStatuses:manage',
}

const namespace: string = IdNamespace.TaxPayerStatusScope;

export const taxPayerStatusScopes: AbilityScopeMap<TaxPayerStatusScopes> = Map<
	TaxPayerStatusScopes,
	AbilityScopeItem<TaxPayerStatusScopes>
>({
	[TaxPayerStatusScopes.Create]: {
		code: TaxPayerStatusScopes.Create,
		id: `${namespace}_2FXr4yaJVIcai8paUAWGdd6QxLk`,
		order: 0,
		icon: 'circle-user',
		label: 'Tax Payer Status Create',
		collection,
		repository,
	},
	[TaxPayerStatusScopes.Read]: {
		code: TaxPayerStatusScopes.Read,
		id: `${namespace}_2FXr6a0K27L8DldIdrAX9Q8UzN2`,
		order: 1,
		icon: 'circle-user',
		label: 'Tax Payer Status Read',
		collection,
		repository,
	},
	[TaxPayerStatusScopes.Update]: {
		code: TaxPayerStatusScopes.Update,
		id: `${namespace}_2FXr7u4WI927IjA0aaRgwODqaMC`,
		order: 2,
		icon: 'circle-user',
		label: 'Tax Payer Status Update',
		collection,
		repository,
	},
	[TaxPayerStatusScopes.Delete]: {
		code: TaxPayerStatusScopes.Delete,
		id: `${namespace}_2FXr9bE0th003XkkX1CJg7CMY8h`,
		order: 3,
		icon: 'circle-user',
		label: 'Tax Payer Status Delete',
		collection,
		repository,
	},
	[TaxPayerStatusScopes.Manage]: {
		code: TaxPayerStatusScopes.Manage,
		id: `${namespace}_2FXr2vyVpvqUoFZb4BVi9xWNFTt`,
		order: 4,
		icon: 'circle-user',
		label: 'Tax Payer Status Manage',
		alias: [
			TaxPayerStatusScopes.Create,
			TaxPayerStatusScopes.Read,
			TaxPayerStatusScopes.Update,
			TaxPayerStatusScopes.Delete,
		],
		collection,
		repository,
	},
});
