import type { DbDocumentsMapType } from './types/documents-map.database.type';
import { SystemEmailTemplateSchemaKey } from '../../dictionaries/email-template-types';

export const DbDocumentsMap: DbDocumentsMapType = {
	companies: {
		independentlyCompany: 'com_2PGPjFcTThP3lPTWXHuBY7o2z8O',
	},
	emailTemplates: {
		[SystemEmailTemplateSchemaKey.SYSTEM_LOGIN_CODE]: 'emtem_2H9NVnvYu1vkzmzlVdrlM2Jno8O',
		[SystemEmailTemplateSchemaKey.SYSTEM_NEW_WORKER_WELCOME_NOTIFICATION]:
			'emtem_2H9NVnYWr2FWD9FDO2cjpPIIaAR',
		[SystemEmailTemplateSchemaKey.SYSTEM_NEW_ADMINISTRATOR_WELCOME_NOTIFICATION]:
			'emtem_2Hc5h4hb7XUK7WZTgP5u75WBKPw',
		[SystemEmailTemplateSchemaKey.SYSTEM_WORKER_NEW_ENGAGEMENT_NOTIFICATION]:
			'emtem_2H9NVnYWr2FWD9FDO2cjpPIIaAR',
		[SystemEmailTemplateSchemaKey.SYSTEM_WORKER_ENGAGEMENT_OUTSTANDING_SURVEY_REMAINDER]:
			'emtem_2NTCeHI8gR1dHFskjMQvEk2utOl',
		[SystemEmailTemplateSchemaKey.SYSTEM_WORKER_ENGAGEMENT_MONTHLY_SURVEY_REMAINDER]:
			'emtem_2QGQXzPJhxX5fVf8trap4cPLGxX',
		[SystemEmailTemplateSchemaKey.SYSTEM_WORKER_ENGAGEMENT_MISSING_ACTION_ITEMS_REMAINDER]:
			'emtem_2NrFMXhPyRgYeJWrsd3Sxt1HgPr',
		[SystemEmailTemplateSchemaKey.SYSTEM_HIRING_MANAGER_NEW_ENGAGEMENT_NOTIFICATION]:
			'emtem_2NrFWOTxXAMVDgZcoFFNZfzTzFR',
		[SystemEmailTemplateSchemaKey.SYSTEM_HIRING_MANAGER_MONTHLY_SURVEY_REMAINDER]:
			'emtem_2NrFvctC1v2fDgubgLUEKEZOUau',
		[SystemEmailTemplateSchemaKey.SYSTEM_INTERNAL_SME_NEW_ENGAGEMENT_NOTIFICATION]:
			'emtem_2OL0PhVkjGiQdhx1KI312L0TDVd',
		[SystemEmailTemplateSchemaKey.SYSTEM_INTERNAL_SME_ENGAGEMENT_PROGRESS_CHANGED_NOTIFICATION]:
			'emtem_2RpYqIFwgRD73IWv34u43cJg9R5',
		[SystemEmailTemplateSchemaKey.SYSTEM_INTERNAL_SME_ENGAGEMENT_COMPLIANCE_DUE_DATE_72_HOUR_REMINDER]:
			'emtem_2RpYoPPFrCmGreqhxIGUaRYv2dF',

		// [SystemEmailTemplateSchemaKey.SYSTEM_WORKER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION]: 'doc_123',
		// [SystemEmailTemplateSchemaKey.SYSTEM_HIRING_MANAGER_ENGAGEMENT_DETERMINATION_CHANGED_NOTIFICATION]: 'doc_123',
	},
	roles: {
		systemIndependentlySuperAdminRole: 'role_2PDWKVG6PCMInlEavFqidpRfgbj',
		systemAdministratorRole: 'role_2KSwly35mTYsRqroB1c5MhQS927',
		systemAdministratorRoleWAutomations: 'role_2H94EK3l3iE2IR0BOf9UUMMhf5Y',
		systemManagerRole: 'role_2KSwlxeN60AKhZuSjeIxIeP9nS2',
		systemLegalRole: 'role_2KSwlwrluLLvRTvty6Exwsor8Cw',
		systemAuditorRole: 'role_2KSwlzhEnKD1TtjStye5yWItG5g',
		systemHiringManagerRole: 'role_2KTbK2Li1JU7c5heNurGpzN4iKP',
		systemWorkerRole: 'role_2H9qxnQMbnpP8ISW4ef7WCluHdR',
	},
	users: {
		systemIndependentlyDefaultComplianceManager: 'usr_2TL8cBPxKIsA2ZYEA0ouatj3Bbo', // Amber Merideth
	},
};
