import type { DeviceFingerprintType } from '../../../../dictionaries/device-fingerprint-types';

export enum AuthDeviceFingerprintKey {
	DeviceFingerprintType = 'deviceFingerprintType',
	DeviceFingerprintId = 'deviceFingerprintId',
	DeviceFingerprintConfidenceScore = 'deviceFingerprintConfidenceScore',
	DeviceFingerprintConfidenceComment = 'deviceFingerprintConfidenceComment',
}

export enum AuthDeviceFingerprintHeaders {
	DeviceFingerprintType = 'Device-Fip-Type',
	DeviceFingerprintId = 'Device-Fip-Id',
	DeviceFingerprintRequestId = 'Device-Fip-Rid',
	DeviceFingerprintConfidenceScore = 'Device-Fip-Con-Score',
	DeviceFingerprintConfidenceComment = 'Device-Fip-Con-Comment',
}

export type DeviceIdentityFP = {
	varient: DeviceFingerprintType.FP5ICAX3QY;
	userAgent: string;
	id: string;
	requestId?: string;
	confidence?: {
		score?: number;
		comment?: string;
	};
};

export type DeviceIdentityCL = {
	varient: DeviceFingerprintType.CLIPT52GYE;
	userAgent: string;
	id: string;
};

export type DeviceIdentityFA = {
	varient: DeviceFingerprintType.FASULEDOBX;
	userAgent: string;
	id: string;
};

export type DeviceIdentity = DeviceIdentityFP | DeviceIdentityCL | DeviceIdentityFA;
