// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'billings';
const repository = 'Billing';

export enum BillingScopes {
	'Read' = 'billings:read',
	'DownloadInternalMonthlyUsageReportsPackage' = 'billings:downloadInternalMonthlyUsageReports',
}

const NS: string = IdNamespace.BillingScope;

export const billingScopes: AbilityScopeMap<BillingScopes> = Map<
	BillingScopes,
	AbilityScopeItem<BillingScopes>
>({
	[BillingScopes.Read]: {
		code: BillingScopes.Read,
		id: `${NS}_2iRNmc9PqSV7rUqcycEFKw6sapQ`,
		order: 0,
		icon: 'circle-user',
		label: 'Billing Read',
		collection,
		repository,
	},
	[BillingScopes.DownloadInternalMonthlyUsageReportsPackage]: {
		code: BillingScopes.DownloadInternalMonthlyUsageReportsPackage,
		id: `${NS}_2iRNmea3Jm4s8cqUyqKBP3gLpLQ`,
		order: 1,
		icon: 'circle-user',
		label: 'Download Internal Monthly Usage Reports Package',
		collection,
		repository,
	},
});
