// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum InsurancePolicyType {
	PROPERTY = 'property',
	CYBER = 'cyber',
	GENERAL_LIABILITY = 'general-liability',
	PRODUCT_LIABILITY = 'product-liability',
	PROFESSIONAL_LIABILITY = 'professional-liability',
	WORKERS_COMPENSATION = 'workers-compensation',
}

export interface InsurancePolicyTypeItem extends BaseDictionary {
	code: InsurancePolicyType;
	id: `${IdNamespace.InsurancePolicyType}_${string}`;
	order: number;
	icon: string;
	label: string;
}

export const insurancePolicyTypes = Map<
	InsurancePolicyType | `${InsurancePolicyType}`,
	InsurancePolicyTypeItem
>({
	[InsurancePolicyType.PROPERTY]: {
		code: InsurancePolicyType.PROPERTY,
		id: 'insupoty_2FeOZ7gaymZaLgJvh8UX5OQyFsR',
		order: 0,
		icon: 'shop',
		label: 'Commercial Property Insurance',
	},
	[InsurancePolicyType.CYBER]: {
		code: InsurancePolicyType.CYBER,
		id: 'insupoty_2FeOZ9kOZlx7ggdWrYfQIV9d9Hb',
		order: 1,
		icon: 'user-robot',
		label: 'Cyber Risk Coverage',
	},
	[InsurancePolicyType.GENERAL_LIABILITY]: {
		code: InsurancePolicyType.GENERAL_LIABILITY,
		id: 'insupoty_2FeOZ6cpTBIGfoeRQ5IGmWMBKYS',
		order: 2,
		icon: 'trowel-bricks',
		label: 'General Liability Insurance',
	},
	[InsurancePolicyType.PRODUCT_LIABILITY]: {
		code: InsurancePolicyType.PRODUCT_LIABILITY,
		id: 'insupoty_2FeOZ6HQpKfSzIKwrGQXooAjcot',
		order: 3,
		icon: 'box-open-full',
		label: 'Product Liability Insurance',
	},
	[InsurancePolicyType.PROFESSIONAL_LIABILITY]: {
		code: InsurancePolicyType.PROFESSIONAL_LIABILITY,
		id: 'insupoty_2FeOZBFE6VrRqAhFXuKqWRfjiuZ',
		order: 4,
		icon: 'user-tie',
		label: 'Professional Liability Insurance',
	},
	[InsurancePolicyType.WORKERS_COMPENSATION]: {
		code: InsurancePolicyType.WORKERS_COMPENSATION,
		id: 'insupoty_2FeOZ9NFxpaA4gFoIb1YgtuB3yg',
		order: 5,
		icon: 'helmet-safety',
		label: 'Workers Compensation Insurance',
	},
});
