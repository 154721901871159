// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'fields';
const repository = 'Field';

export enum FieldScopes {
	'Create' = 'fields:create',
	'Read' = 'fields:read',
	'Update' = 'fields:update',
	'Delete' = 'fields:delete',
	'Manage' = 'fields:manage',
}

const namespace = 'fldsco';

export const fieldScopes: AbilityScopeMap<FieldScopes> = Map<
	FieldScopes,
	AbilityScopeItem<FieldScopes>
>({
	[FieldScopes.Create]: {
		code: FieldScopes.Create,
		id: `${namespace}_2FXhawe2me8wE8TK1d9mjfXaT3z`,
		order: 0,
		icon: 'circle-user',
		label: 'Field Create',
		collection,
		repository,
	},
	[FieldScopes.Read]: {
		code: FieldScopes.Read,
		id: `${namespace}_2FXhe4xLejxdnVazPfcrXsaXlWV`,
		order: 1,
		icon: 'circle-user',
		label: 'Field Read',
		collection,
		repository,
	},
	[FieldScopes.Update]: {
		code: FieldScopes.Update,
		id: `${namespace}_2FXhfvktFnHmaSiNiY4F9kXBHhX`,
		order: 2,
		icon: 'circle-user',
		label: 'Field Update',
		collection,
		repository,
	},
	[FieldScopes.Delete]: {
		code: FieldScopes.Delete,
		id: `${namespace}_2FXhhryb1ttmQXTLa8NBbnSJDMA`,
		order: 3,
		icon: 'circle-user',
		label: 'Field Delete',
		collection,
		repository,
	},
	[FieldScopes.Manage]: {
		code: FieldScopes.Manage,
		id: `${namespace}_2FXhZ1iBddd8eLLJHwMcBNqXQDY`,
		order: 4,
		icon: 'circle-user',
		label: 'Field Manage',
		alias: [FieldScopes.Create, FieldScopes.Read, FieldScopes.Update, FieldScopes.Delete],
		collection,
		repository,
	},
});
