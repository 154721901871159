// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'imports';
const repository = 'Import';

export enum ImportScopes {
	'Read' = 'imports:read',
}

const NS: string = IdNamespace.ImportScope;

export const importScopes: AbilityScopeMap<ImportScopes> = Map<
	ImportScopes,
	AbilityScopeItem<ImportScopes>
>({
	[ImportScopes.Read]: {
		code: ImportScopes.Read,
		id: `${NS}_2amwxfuLv1Fb3ucbeCPKCRoT9Rx`,
		order: 0,
		icon: 'circle-user',
		label: 'Import Read',
		collection,
		repository,
	},
});
