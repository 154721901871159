// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../../+types/scope-item.type';
import { IdNamespace } from '../../../shared/database/constants/id-namespaces.constant';

const collection = 'eventJobs';
const repository = 'EventJob';

export enum EventJobScopes {
	'Create' = 'eventJobs:create',
	'Read' = 'eventJobs:read',
	'Update' = 'eventJobs:update',
	'Delete' = 'eventJobs:delete',
	'Manage' = 'eventJobs:manage',
}

const namespace = IdNamespace.EventJobScope;

export const eventJobScopes: AbilityScopeMap<EventJobScopes> = Map<
	EventJobScopes,
	AbilityScopeItem<EventJobScopes>
>({
	[EventJobScopes.Create]: {
		code: EventJobScopes.Create,
		id: `${namespace}_2OW5S3Emnl7aWvJAzz6RFVOyhHR`,
		order: 0,
		icon: 'circle-user',
		label: 'EventJob Create',
		collection,
		repository,
	},
	[EventJobScopes.Read]: {
		code: EventJobScopes.Read,
		id: `${namespace}_2OW5Ylni7uJ4U6J5CjwQ3UiXi84`,
		order: 1,
		icon: 'circle-user',
		label: 'EventJob Read',
		collection,
		repository,
	},
	[EventJobScopes.Update]: {
		code: EventJobScopes.Update,
		id: `${namespace}_2OW5bAejDfDkffTO1DU669Vufg0`,
		order: 2,
		icon: 'circle-user',
		label: 'EventJob Update',
		collection,
		repository,
	},
	[EventJobScopes.Delete]: {
		code: EventJobScopes.Delete,
		id: `${namespace}_2OW5cwqkEV32VgM8eKhasKOO3Cb`,
		order: 3,
		icon: 'circle-user',
		label: 'EventJob Delete',
		collection,
		repository,
	},
	[EventJobScopes.Manage]: {
		code: EventJobScopes.Manage,
		id: `${namespace}_2OW5QaEf4KKGBRqnB8wl7SPIyXd`,
		order: 4,
		icon: 'circle-user',
		label: 'EventJob Manage',
		alias: [
			EventJobScopes.Create,
			EventJobScopes.Read,
			EventJobScopes.Update,
			EventJobScopes.Delete,
		],
		collection,
		repository,
	},
});
