import type { ComponentType, ReactElement, ReactNode } from 'react';
import type {
	LinkClickedScreenProps,
	NormalisedConfig,
} from 'supertokens-auth-react/lib/build/recipe/passwordless/types';
import type { RecipeInterface } from 'supertokens-web-js/recipe/passwordless';

const PasswordlessLinkClickedScreen = ({
	DefaultComponent,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	...props
}: {
	DefaultComponent: ComponentType<
		LinkClickedScreenProps & {
			children?: ReactNode;
		}
	>;
	recipeImplementation: RecipeInterface;
	config: NormalisedConfig;
	requireUserInteraction: boolean;
	consumeCode: () => void;
	onSuccess?: (() => void) | undefined;
	children?: ReactNode;
}): ReactElement | null => <></>;

export default PasswordlessLinkClickedScreen;
