// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../../+types/scope-item.type';
import { IdNamespace } from '../../../shared/database/constants/id-namespaces.constant';

const collection = 'automationJobs';
const repository = 'AutomationJob';

export enum AutomationJobScopes {
	'Create' = 'automationJobs:create',
	'Read' = 'automationJobs:read',
	'Update' = 'automationJobs:update',
	'Delete' = 'automationJobs:delete',
	'Manage' = 'automationJobs:manage',
}

const namespace = IdNamespace.AutomationJobScope;

export const automationJobsScopes: AbilityScopeMap<AutomationJobScopes> = Map<
	AutomationJobScopes,
	AbilityScopeItem<AutomationJobScopes>
>({
	[AutomationJobScopes.Create]: {
		code: AutomationJobScopes.Create,
		id: `${namespace}_2OW0jaDqJMEFUNx9qsyp3QJfUZH`,
		order: 0,
		icon: 'circle-user',
		label: 'AutomationJob Create',
		collection,
		repository,
	},
	[AutomationJobScopes.Read]: {
		code: AutomationJobScopes.Read,
		id: `${namespace}_2OW0mB6A843JKt1Z5Y80JsrmPem`,
		order: 1,
		icon: 'circle-user',
		label: 'AutomationJob Read',
		collection,
		repository,
	},
	[AutomationJobScopes.Update]: {
		code: AutomationJobScopes.Update,
		id: `${namespace}_2OW0oFn7PrqUp9vxi5Zl9Wgf0f7`,
		order: 2,
		icon: 'circle-user',
		label: 'AutomationJob Update',
		collection,
		repository,
	},
	[AutomationJobScopes.Delete]: {
		code: AutomationJobScopes.Delete,
		id: `${namespace}_2OW0rAzgqixlW0FVbl8tjGKsLQ4`,
		order: 3,
		icon: 'circle-user',
		label: 'AutomationJob Delete',
		collection,
		repository,
	},
	[AutomationJobScopes.Manage]: {
		code: AutomationJobScopes.Manage,
		id: `${namespace}_2OW0hX5K3n3l3QIBpHnypjkBpO8`,
		order: 4,
		icon: 'circle-user',
		label: 'AutomationJob Manage',
		alias: [
			AutomationJobScopes.Create,
			AutomationJobScopes.Read,
			AutomationJobScopes.Update,
			AutomationJobScopes.Delete,
		],
		collection,
		repository,
	},
});
