import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';
import { SortListDirections } from '../../shared/database/types/document-sort-doc.database.type';

export interface BaseDictionary {
	code: string;
	id: string;
}

export enum SortList {
	DATE_CREATED = 'date-created',
	DATE_LAST_UPDATED = 'date-last-updated',
	EXTERNAL_ID = 'external-id',
}

export interface SortListItem {
	code: SortList;
	id: `${IdNamespace.SortList}_${string}`;
	order: number;
	label: string;
	directionIcons: {
		[SortListDirections.ASC]: string;
		[SortListDirections.DESC]: string;
	};
	function: {
		[SortListDirections.ASC]: Record<string, 'asc'>;
		[SortListDirections.DESC]: Record<string, 'desc'>;
	};
}

export type Hex = `#${string}`;
