// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../../+types/scope-item.type';
import { IdNamespace } from '../../../shared/database/constants/id-namespaces.constant';

const collection = 'companies';
const repository = 'Company';

export enum CompanyScopes {
	'Create' = 'companies:create',
	'Read' = 'companies:read',
	'ReadCustomizations' = 'companies:readCustomizations',
	'ReadPrivacy' = 'companies:readPrivacy',
	'ReadIntegrations' = 'companies:readIntegrations',
	'Update' = 'companies:update',
	'UpdatePartner' = 'companies:updatePartner',
	'UpdateCustomizations' = 'companies:updateCustomizations',
	'UpdatePrivacy' = 'companies:updatePrivacy',
	'UpdateIntegrations' = 'companies:updateIntegrations',
	'ToggleFeature' = 'companies:toggleFeature',
	'Delete' = 'companies:delete',
	'SignUp' = 'companies:signUp',
	'DownloadUsageReport' = 'billings:downloadUsageReport',
}

// const NS: string = IdNamespace.CompanyScopes;

export const companyScopes: AbilityScopeMap<CompanyScopes> = Map<
	CompanyScopes,
	AbilityScopeItem<CompanyScopes>
>({
	[CompanyScopes.Create]: {
		code: CompanyScopes.Create,
		id: 'comsco_2Gt5Iz3hYLSMZLBpi2n3KlUmImk',
		order: 0,
		icon: 'circle-user',
		label: 'Company Create',
		collection,
		repository,
	},
	[CompanyScopes.Read]: {
		code: CompanyScopes.Read,
		id: 'comsco_2Gt5IxYTrH8SLl70PzO2G88prEi',
		order: 1,
		icon: 'circle-user',
		label: 'Company Read',
		collection,
		repository,
	},
	[CompanyScopes.ReadCustomizations]: {
		code: CompanyScopes.ReadCustomizations,
		id: 'comsco_2OUYtMEJ6n4HdYEyfM1rOopUjeV',
		order: 2,
		icon: 'circle-user',
		label: 'Company Read Customizations',
		collection,
		repository,
	},
	[CompanyScopes.ReadPrivacy]: {
		code: CompanyScopes.ReadPrivacy,
		id: 'comsco_2gc1hSyHDIvSmG7GbMYuj3IjCO5',
		order: 3,
		icon: 'circle-user',
		label: 'Company Read Privacy',
		collection,
		repository,
	},
	[CompanyScopes.ReadIntegrations]: {
		code: CompanyScopes.ReadIntegrations,
		id: 'comsco_2OUYtGVC9HOObXmhPZceLiRo898',
		order: 4,
		icon: 'circle-user',
		label: 'Company Read Integrations',
		collection,
		repository,
	},
	[CompanyScopes.Update]: {
		code: CompanyScopes.Update,
		id: 'comsco_2Gt5J00FJJG43SmHVLdpoqZkYpP',
		order: 5,
		icon: 'circle-user',
		label: 'Company Update',
		collection,
		repository,
	},
	[CompanyScopes.UpdatePartner]: {
		code: CompanyScopes.UpdatePartner,
		id: 'comsco_2hZdJqQCpkQziv9kePFLTU32syM',
		order: 6,
		icon: 'circle-user',
		label: 'Edit Partner',
		collection,
		repository,
	},
	[CompanyScopes.UpdateCustomizations]: {
		code: CompanyScopes.UpdateCustomizations,
		id: 'comsco_2OUYtLeKcgP6ZnqlVd601eCp0SS',
		order: 7,
		icon: 'circle-user',
		label: 'Company Update Customizations',
		collection,
		repository,
	},
	[CompanyScopes.UpdatePrivacy]: {
		code: CompanyScopes.UpdatePrivacy,
		id: 'comsco_2gc1hSTTiBAXdqmt4xlcKvP2TIw',
		order: 8,
		icon: 'circle-user',
		label: 'Company Update Privacy',
		collection,
		repository,
	},
	[CompanyScopes.UpdateIntegrations]: {
		code: CompanyScopes.UpdateIntegrations,
		id: 'comsco_2OUYtJV2GLhdW8huJnbATiEthh0',
		order: 9,
		icon: 'circle-user',
		label: 'Company Update Integrations',
		collection,
		repository,
	},
	[CompanyScopes.ToggleFeature]: {
		code: CompanyScopes.ToggleFeature,
		id: 'comsco_2XNCeX0XHBBfVqO2KTIQMwDmvQo',
		order: 10,
		icon: 'circle-user',
		label: 'Company Toggle Feature',
		collection,
		repository,
	},
	[CompanyScopes.Delete]: {
		code: CompanyScopes.Delete,
		id: 'comsco_2Gt5IxhRNUbCGO43MmteB4lwoNA',
		order: 11,
		icon: 'circle-user',
		label: 'Company Delete',
		collection,
		repository,
	},
	[CompanyScopes.SignUp]: {
		code: CompanyScopes.SignUp,
		id: 'comsco_2eecH4KpRr9VBzdk04On0b0Vrc3',
		order: 12,
		icon: 'circle-user',
		label: 'Sign Up',
		collection,
		repository,
	},
	[CompanyScopes.DownloadUsageReport]: {
		code: CompanyScopes.DownloadUsageReport,
		id: 'comsco_2iZBj4mFDsEr1fbmwavvcVD5hbG',
		order: 13,
		icon: 'circle-user',
		label: 'Download Usage Report',
		collection,
		repository,
	},
});
