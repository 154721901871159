import {
	AbilityBuilder,
	createAliasResolver,
	createMongoAbility,
	ExtractSubjectType,
	MongoAbility,
	RuleOf,
} from '@casl/ability';
import type { Map } from 'immutable';
import { filter, findIndex, forEach, isArray, isNil, isString, omit, pipe, uniq } from 'remeda';
import { isMatching, match, Pattern } from 'ts-pattern';
import type { PartialDeep, SetRequired } from 'type-fest';
import { existValueInEnum } from '../shared/common/helpers/enums.helpers';
import { abilityScopes } from './ability-scopes.dictionary';

import {
	AdministratorAbilities,
	AdministratorActions,
	AdministratorSubject,
} from './administrators/administrators.ability';
import { ApiTokenAbilities, ApiTokenActions, ApiTokenSubject } from './apiTokens/apiTokens.ability';
import { AppSubjects } from './app-subjects.dictionary';
import {
	AccessTokenAbilities,
	AccessTokenActions,
	AccessTokenSubject,
} from './auth/accessTokens/accessTokens.ability';
import {
	CompanyAbilities,
	CompanyActions,
	CompanySubject,
} from './auth/companies/companies.ability';
import { UserAbilities, UserActions, UserSubject } from './auth/users/users.ability';
import {
	AutomationJobAbilities,
	AutomationJobActions,
	AutomationJobSubject,
} from './automations/automationJobs/automationJobs.ability';
import {
	AutomationAbilities,
	AutomationActions,
	AutomationSubject,
} from './automations/automations.ability';
import {
	EventJobAbilities,
	EventJobActions,
	EventJobSubject,
} from './automations/eventJobs/eventJobs.ability';
import {
	NodeJobAbilities,
	NodeJobActions,
	NodeJobSubject,
} from './automations/nodeJobs/nodeJobs.ability';
import {
	ScheduleJobAbilities,
	ScheduleJobActions,
	ScheduleJobSubject,
} from './automations/scheduleJobs/scheduleJobs.ability';
import { BillingAbilities, BillingActions, BillingSubject } from './billings/billings.ability';
import {
	BusinessRegistrationAbilities,
	BusinessRegistrationActions,
	BusinessRegistrationSubject,
} from './businessRegistrations/businessRegistrations.ability';
import { ClientAbilities, ClientActions, ClientSubject } from './clients/clients.ability';
import {
	ComplianceFieldAbilities,
	ComplianceFieldActions,
	ComplianceFieldSubject,
} from './complianceFields/complianceFields.ability';
import {
	DepartmentAbilities,
	DepartmentActions,
	DepartmentSubject,
} from './departments/departments.ability';
import { EmailLogAbilities, EmailLogActions, EmailLogSubject } from './emailLogs/emailLogs.ability';
import {
	EmailTemplateAbilities,
	EmailTemplateActions,
	EmailTemplateSubject,
} from './emailTemplates/emailTemplates.ability';
import {
	EngagementProgressLogAbilities,
	EngagementProgressLogActions,
	EngagementProgressLogSubject,
} from './engagementProgressLogs/engagementProgressLogs.ability';
import {
	EngagementReportAbilities,
	EngagementReportActions,
	EngagementReportSubject,
} from './engagementReports/engagementReports.ability';
import {
	EngagementAbilities,
	EngagementActions,
	EngagementSubject,
} from './engagements/engagement.ability';
import {
	EngagementTemplateAbilities,
	EngagementTemplateActions,
	EngagementTemplateSubject,
} from './engagementTemplates/engagementTemplates.ability';
import { FactorAbilities, FactorActions, FactorSubject } from './factors/factors.ability';
import {
	FieldChoiceAbilities,
	FieldChoiceActions,
	FieldChoiceSubject,
} from './fieldChoices/fieldChoices.ability';
import { FieldAbilities, FieldActions, FieldSubject } from './fields/fields.ability';
import { FileAbilities, FileActions, FileSubject } from './files/files.ability';
import { FormAbilities, FormActions, FormSubject } from './forms/forms.ability';
import { ImportAbilities, ImportActions, ImportSubject } from './imports/imports.ability';
import {
	InsurancePolicyAbilities,
	InsurancePolicyActions,
	InsurancePolicySubject,
} from './insurancePolicies/insurancePolicies.ability';
import {
	JurisdictionAbilities,
	JurisdictionActions,
	JurisdictionSubject,
} from './jurisdictions/jurisdictions.ability';
import {
	ManagedServiceProviderAbilities,
	ManagedServiceProviderActions,
	ManagedServiceProviderSubject,
} from './managedServiceProviders/managedServiceProviders.ability';
import { PartnerAbilities, PartnerActions, PartnerSubject } from './partners/partners.ability';
import {
	PersonalAccessTokenAbilities,
	PersonalAccessTokenActions,
	PersonalAccessTokenSubject,
} from './personalAccessTokens/personalAccessTokens.ability';
import { ProjectAbilities, ProjectActions, ProjectSubject } from './projects/projects.ability';
import {
	RequestDeviceAbilities,
	RequestDeviceActions,
	RequestDeviceSubject,
} from './requestDevices/requestDevices.ability';
import { RoleAbilities, RoleActions, RoleSubject } from './roles/roles.ability';
import {
	SecurityLogAbilities,
	SecurityLogActions,
	SecurityLogSubject,
} from './securityLogs/securityLogs.ability';
import { SessionAbilities, SessionActions, SessionSubject } from './sessions/sessions.ability';
import { SkillAbilities, SkillActions, SkillSubject } from './skills/skills.ability';
import {
	SmsTemplateAbilities,
	SmsTemplateActions,
	SmsTemplateSubject,
} from './smsTemplates/smsTemplates.ability';
import { SourceAbilities, SourceActions, SourceSubject } from './sources/sources.ability';
import { StageAbilities, StageActions, StageSubject } from './stages/stages.ability';
import {
	SurveyAnswerAbilities,
	SurveyAnswerActions,
	SurveyAnswerSubject,
} from './surveyAnswers/surveyAnswers.ability';
import {
	SurveyQuestionAbilities,
	SurveyQuestionActions,
	SurveyQuestionSubject,
} from './surveyQuestions/surveyQuestions.ability';
import { TagAbilities, TagActions, TagSubject } from './tags/tags.ability';
import {
	TaxPayerStatusAbilities,
	TaxPayerStatusActions,
	TaxPayerStatusSubject,
} from './taxPayerStatuses/taxPayerStatuses.ability';
import {
	TaxRegistrationAbilities,
	TaxRegistrationActions,
	TaxRegistrationSubject,
} from './taxRegistrations/taxRegistrations.ability';
import { TeamAbilities, TeamActions, TeamSubject } from './teams/teams.ability';
import { TestAbilities, TestActions, TestSubject } from './tests/tests.ability';
import { AbilityScopeItem } from './+types/scope-item.type';
import { VendorAbilities, VendorActions, VendorSubject } from './vendors/vendors.ability';
import { WorkerAbilities, WorkerActions, WorkerSubject } from './workers/workers.ability';

export type AppAbilityActions =
	| AccessTokenActions
	| AdministratorActions
	| ApiTokenActions
	| AutomationActions
	| AutomationJobActions
	| BillingActions
	| BusinessRegistrationActions
	| ClientActions
	| CompanyActions
	| ComplianceFieldActions
	| DepartmentActions
	| EmailLogActions
	| EmailTemplateActions
	| EngagementActions
	| EngagementProgressLogActions
	| EngagementReportActions
	| EngagementTemplateActions
	| EventJobActions
	| FactorActions
	| FieldActions
	| FieldChoiceActions
	| FileActions
	| FormActions
	| ImportActions
	| InsurancePolicyActions
	| JurisdictionActions
	| ManagedServiceProviderActions
	| NodeJobActions
	| PartnerActions
	| PersonalAccessTokenActions
	| ProjectActions
	| RequestDeviceActions
	| RoleActions
	| ScheduleJobActions
	| SecurityLogActions
	| SessionActions
	| SkillActions
	| SmsTemplateActions
	| SourceActions
	| StageActions
	| SurveyAnswerActions
	| SurveyQuestionActions
	| TagActions
	| TaxPayerStatusActions
	| TaxRegistrationActions
	| TeamActions
	| TestActions
	| UserActions
	| VendorActions
	| WorkerActions;

export type AppAbilitySubjects =
	| AccessTokenSubject
	| AdministratorSubject
	| ApiTokenSubject
	| AutomationJobSubject
	| AutomationSubject
	| BillingSubject
	| BusinessRegistrationSubject
	| ClientSubject
	| CompanySubject
	| ComplianceFieldSubject
	| DepartmentSubject
	| EmailLogSubject
	| EmailTemplateSubject
	| EngagementProgressLogSubject
	| EngagementReportSubject
	| EngagementSubject
	| EngagementTemplateSubject
	| EventJobSubject
	| FactorSubject
	| FieldChoiceSubject
	| FieldSubject
	| FileSubject
	| FormSubject
	| ImportSubject
	| InsurancePolicySubject
	| JurisdictionSubject
	| ManagedServiceProviderSubject
	| NodeJobSubject
	| PartnerSubject
	| PersonalAccessTokenSubject
	| ProjectSubject
	| RequestDeviceSubject
	| RoleSubject
	| ScheduleJobSubject
	| SecurityLogSubject
	| SessionSubject
	| SkillSubject
	| SmsTemplateSubject
	| SourceSubject
	| StageSubject
	| SurveyAnswerSubject
	| SurveyQuestionSubject
	| TagSubject
	| TaxPayerStatusSubject
	| TaxRegistrationSubject
	| TeamSubject
	| TestSubject
	| UserSubject
	| VendorSubject
	| WorkerSubject;

export type AppAbilities =
	| AccessTokenAbilities
	| AdministratorAbilities
	| ApiTokenAbilities
	| AutomationAbilities
	| AutomationJobAbilities
	| BillingAbilities
	| BusinessRegistrationAbilities
	| ClientAbilities
	| CompanyAbilities
	| ComplianceFieldAbilities
	| DepartmentAbilities
	| EmailLogAbilities
	| EmailTemplateAbilities
	| EngagementAbilities
	| EngagementProgressLogAbilities
	| EngagementReportAbilities
	| EngagementTemplateAbilities
	| EventJobAbilities
	| FactorAbilities
	| FieldAbilities
	| FieldChoiceAbilities
	| FileAbilities
	| FormAbilities
	| ImportAbilities
	| InsurancePolicyAbilities
	| JurisdictionAbilities
	| ManagedServiceProviderAbilities
	| NodeJobAbilities
	| PartnerAbilities
	| PersonalAccessTokenAbilities
	| ProjectAbilities
	| RequestDeviceAbilities
	| RoleAbilities
	| ScheduleJobAbilities
	| SecurityLogAbilities
	| SessionAbilities
	| SkillAbilities
	| SmsTemplateAbilities
	| SourceAbilities
	| StageAbilities
	| SurveyAnswerAbilities
	| SurveyQuestionAbilities
	| TagAbilities
	| TaxPayerStatusAbilities
	| TaxRegistrationAbilities
	| TeamAbilities
	| TestAbilities
	| UserAbilities
	| VendorAbilities
	| WorkerAbilities;

export type AppAbility = MongoAbility<AppAbilities>;
export const AppAbility = createMongoAbility;

export interface SelfCondition {
	type: 'self';
	inlineMatch?: string; // @TODO: use template string , its either match or inlineMach but not both at the same time
	predefinedMatch?: string;
	// inverted:
	// optional?: boolean;
}

export interface NestedCondition {
	type: 'nested';
	inlineMatch?: string; // @TODO: use template string
	predefinedMatch?: string;
	subject: AppSubjects;
	scopes: Array<AppAbilityActions>;
	foreignField?: string;
}

export type AbilityCondition = SelfCondition | NestedCondition;
export type AbilityConditions = Array<AbilityCondition>;

export interface SubjectAbility {
	description?: string;
	subject: AppAbilitySubjects; //SubjectTypeOf<AppAbility> | SubjectTypeOf<AppAbility>[];
	scopes: Array<AppAbilityActions>;
	allOf?: AbilityConditions;
	anyOf?: AbilityConditions;
	blacklistFields?: Array<string>;
}

export interface AbilitiesContext {
	// type: 'User' | 'ApiKey' | 'PAT';

	label?: string;

	description?: string;

	abilities: Array<SubjectAbility>;
}

const _isAbilitiesElementValid = (
	abilityElem: SubjectAbility | unknown
): abilityElem is SubjectAbility =>
	isMatching({
		subject: Pattern.when(
			(subject: unknown): subject is AppAbilitySubjects =>
				isString(subject) && existValueInEnum<AppSubjects>(AppSubjects, subject)
		),
		scopes: Pattern.array(Pattern.string),
	})(abilityElem);

const _isAbilitiesValid = (
	abilities: Array<SubjectAbility> | unknown
): abilities is Array<SubjectAbility> =>
	isMatching(
		Pattern.when(
			(abilities: Array<SubjectAbility> | unknown): abilities is Array<SubjectAbility> => {
				return (
					isArray(abilities) &&
					abilities.some(
						(abilityElem: SubjectAbility | unknown): abilityElem is SubjectAbility =>
							_isAbilitiesElementValid(abilityElem)
					)
				);
			}
		)
	)(abilities);

const _isScopeCASLManage = (scope: string): boolean => scope === 'manage';

const _processScopesBySubject = (
	subject: AppAbilitySubjects,
	scopes: Array<AppAbilityActions>
): Array<AppAbilityActions> => {
	// console.info('[INFO] _getScopesBySubject', { subject, 'scopes(orig)': JSON.stringify(scopes) });

	if (!isArray(scopes)) {
		return [];
	}

	// #NOTE: Remove default CASL "manage" ability to prevent loopholes
	//	https://casl.js.org/v6/en/guide/define-aliases
	scopes = filter(scopes ?? [], (scope: AppAbilityActions) => !_isScopeCASLManage(scope));

	if (!isString(subject)) {
		return scopes;
	}

	// const foo: { [AppAbilitySubjects]: Map<AppAbilitySubjects, AbilityScopeItem> } = pickBy(AbilityScopesMap, (_v, key) => key === subject);
	// console.debug('[DEBUG AppAbility] foo:', foo);

	// @ts-expect-error: #TODO(archiveUpdates): @ringer-andrew Fix type...
	const abilityScopeMap: Map<
		AppAbilitySubjects,
		AbilityScopeItem<AppAbilityActions>
	> = abilityScopes[subject];
	// console.debug('[DEBUG AppAbility] abilityScopeMap:', abilityScopeMap);

	if (isNil(abilityScopeMap)) {
		console.warn(
			'[AppAbilitiyError]: Warning, AbilityScopesMap for subject not found (bad data)',
			{ subject }
		);
		return scopes;
	}

	void abilityScopeMap
		.filter(
			(
				item: AbilityScopeItem<AppAbilityActions>,
				ability: AppAbilitySubjects
			): item is SetRequired<AbilityScopeItem<AppAbilityActions>, 'alias'> => {
				// console.debug('[DEBUG AppAbility] in-filter:', { ability, scopeItem: JSON.stringify(item), isAbilityExists: !isNil(item.alias), isScopeAliasExist: scopes.includes(ability) });

				if (isNil(item.alias)) {
					return false;
				}

				// @ts-expect-error: #TODO(archiveUpdates): @ringer-andrew Fix type...
				if (!scopes.includes(ability)) {
					return false;
				}

				return true;
			}
		)
		.forEach(
			(
				item: SetRequired<AbilityScopeItem<AppAbilityActions>, 'alias'>,
				ability: AppAbilitySubjects
			): void => {
				// console.debug('[DEBUG AppAbility] in-forEach, alias FOUND!:', { 'ability(to-delete)': ability, alias: JSON.stringify(item.alias) });

				// @ts-expect-error: #TODO(archiveUpdates): @ringer-andrew Fix type...
				const index: number = findIndex(scopes, (s: AppAbilityActions) => s === ability);
				// console.debug('[DEBUG AppAbility] in-forEach, index:', { ability, index });

				scopes.splice(index, 1, ...item.alias);
			}
		);
	// console.debug('[DEBUG AppAbility] aliasScopes(post-findAlasises):', { subject, 'scopes(orig)': JSON.stringify(scopes) });

	scopes = uniq(scopes);
	// console.debug('[DEBUG AppAbility] scopes (END):', { subject, 'scopes(merged-FINAL)': JSON.stringify(scopes) });

	return scopes;
};

export const appAbilityRules = (
	context: AbilitiesContext
): {
	ability: AppAbility;
	abilityRawRules: Array<PartialDeep<SubjectAbility, { recurseIntoArrays: true }>>;
} => {
	// console.info('[INFO] appAbilityRules', { abilitiesCount: (context.abilities ?? []).length, abilitiesJSON: JSON.stringify(context.abilities) });

	// #NOTE: Set any global-level aliases here
	//	- Note needed per dictioinary Map<Subject, AbilityScopeItem>, instead, those are merged in _processScopesBySubject()
	const resolveAction = createAliasResolver({});
	// console.debug('[DEBUG AppAbility] resolveAction:', JSON.stringify(AbilityManageScopesEnumMap));

	const abiInstance: AbilityBuilder<AppAbility> = new AbilityBuilder<AppAbility>(AppAbility);
	const { can, cannot, build } = abiInstance;

	const abilityRawRules: Array<PartialDeep<SubjectAbility, { recurseIntoArrays: true }>> = [];

	if (!isArray(context.abilities)) {
		console.error('[AppAbilitiyError]: Warning, Invalid abilities object (bad data)');
		return {
			ability: build({ resolveAction }),
			abilityRawRules,
		};
	}

	void pipe(
		context.abilities ?? [],
		filter<Partial<SubjectAbility>, SubjectAbility>(
			(abilityElem: Partial<SubjectAbility>): abilityElem is SubjectAbility => {
				// console.debug('[DEBUG AppAbility] in-filter abilityElem:', JSON.stringify(abilityElem), { isAbilitiesElementValid: _isAbilitiesElementValid(abilityElem) });

				// @TODO: captureError
				// @Question: How do we capture errors for shared libs across both projects ?
				if (!_isAbilitiesElementValid(abilityElem)) {
					console.warn(
						'[AppAbilitiyError]: Warning, Invalid abilityElem object (bad data)',
						JSON.stringify(abilityElem)
					);
					return false;
				}

				return true;
			}
		),
		forEach((abilityElem: SubjectAbility) => {
			// console.debug('[DEBUG AppAbility] in-forEach', { subject: abilityElem.subject, scopes: JSON.stringify(abilityElem.scopes) });

			const scopes = _processScopesBySubject(abilityElem.subject, abilityElem.scopes);
			// console.debug('[DEBUG AppAbility] in-forEach', {
			// 	subject: abilityElem.subject,
			// 	'scopes(orig)': JSON.stringify(abilityElem.scopes),
			// 	'actions(hydrated)': JSON.stringify(scopes),
			// });

			const ruleDef: SetRequired<Partial<RuleOf<AppAbility>>, 'subject' | 'action'> = {
				subject: abilityElem.subject as ExtractSubjectType<AppAbilitySubjects>,
				action: scopes,
				fields: match(abilityElem.blacklistFields)
					.with(Pattern.array(Pattern.string), (fields: Array<string>) => fields)
					.otherwise(() => undefined),
			};
			// console.debug('[DEBUG AppAbility] in-forEach', { ruleDef: JSON.stringify(ruleDef) });

			abilityRawRules.push({
				subject: abilityElem.subject,
				scopes,
				...omit(abilityElem, ['subject', 'scopes']),
			});

			can(ruleDef.action, ruleDef.subject);
		})
	);

	const ability = build({ resolveAction });
	// console.debug('[DEBUG AppAbility] result(rules):', JSON.stringify(ability.rules));
	// console.debug('[DEBUG AppAbility] abilityRawRules:', JSON.stringify(abilityRawRules));

	return {
		ability,
		abilityRawRules,
	};
};

// export enum PermissionSubjects {
// 	File = 'File',
// }

// export type APPROVE = 'approve';

// export type PermissionActions = CRUD | APPROVE;

// export type PermissionSubjectsUnionType = keyof typeof PermissionSubjects;

// export const PermissionSubjectProp = 'permissionSubject';

// export const permissions: { [key in PermissionSubjects]: Ability } = {
// 	Document: new Ability(documentRules()),
// };

// export const checkPermission = (
// 	user: {permissions: [], roles: []}, // user Interface
// 	action: PermissionActions,
// 	sub: PermissionSubjects | PermissionSubjectsUnionType,
// 	subjectData: any = {}
// ) => {
// 	// console.log(user, action, sub, subjectData, JSON.stringify(permissions[sub].rules));
// 	return permissions[sub]?.can(
// 		action,
// 		subject(sub, {
// 			...subjectData,
// 			permissions: user?.permissions ?? [],
// 			roles: user?.roles ?? [],
// 			loggedInUserProfileData: user,
// 		})
// 	);
// };

// // @TODO:
// // 1.  Restrict Endpoints , Ex:  Ability to create an Engagement
// // 1b. Restrict Individual Documents
// // 1c. Restrict the group of fields
// // 2. Select function will allow only the defined fields, Redact, query,
// // 3. Front-end: Wrapping Module Ex: Access to Engagements
// // 4. Front-end: Buttons with Abilities: Add Worker Button

// // 5: API keys, Grouping Permissions
// 	// For Ex: An user has access to All Engagement actions

// // It could be a global api key or User accessing the

// // import { Ability, AbilityBuilder } from '@casl/ability';

// // define abilities
// // const { can, cannot, rules } = new AbilityBuilder();

// // can('read', ['Post', 'Comment']);
// // can('manage', 'Post', { author: 'me' });
// // can('create', 'Comment');

// // check abilities
// // const ability = new Ability(rules);

// // ability.can('read', 'Post') // true

// type PolicyHandlerCallback = (ability: DocumentAbility) => boolean;

// export type PolicyHandler = PolicyHandlerCallback;
