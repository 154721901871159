// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { SortListItem, type SortList } from './types/dictionaries.type';
import { sortList } from './sort-list';

export enum WorkerSortList {
	NAME = 'name',
}

export interface WorkerSortListItem extends Omit<SortListItem, 'id' | 'code'> {
	code: SortList | WorkerSortList;
	id: `${IdNamespace.SortList}_${string}` | `${IdNamespace.WorkerSortList}_${string}`;
}

export const workerSortList: Map<
	SortList | `${SortList}` | WorkerSortList | `${WorkerSortList}`,
	WorkerSortListItem
> = Map<SortList | WorkerSortList, WorkerSortListItem>({
	[WorkerSortList.NAME]: {
		code: WorkerSortList.NAME,
		id: 'wkrsoli_2LRb4cssRcEKyTcQAzEw5Zk7yIy',
		order: 3,
		// label: i18n.t('workersSortList.name', { ns: 'dictionaries' }),
		label: 'Name',
		directionIcons: {
			asc: 'sort-alpha-up-alt',
			desc: 'sort-alpha-down-alt',
		},
		function: {
			asc: {
				'name.last': 'asc',
				'name.first': 'asc',
				_createdDate: 'asc',
			},
			desc: {
				'name.last': 'desc',
				'name.first': 'desc',
				_createdDate: 'desc',
			},
		},
	},
}).merge(sortList);
