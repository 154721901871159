// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { SortListItem, type SortList } from './types/dictionaries.type';
import { sortList } from './sort-list';

export enum AdministratorSortList {
	NAME = 'name',
}

export interface AdministratorSortListItem extends Omit<SortListItem, 'id' | 'code'> {
	code: SortList | AdministratorSortList;
	id: `${IdNamespace.SortList}_${string}` | `${IdNamespace.AdministratorSortList}_${string}`;
}

export const administratorSortList: Map<
	SortList | `${SortList}` | AdministratorSortList | `${AdministratorSortList}`,
	AdministratorSortListItem
> = Map<SortList | AdministratorSortList, AdministratorSortListItem>({
	[AdministratorSortList.NAME]: {
		code: AdministratorSortList.NAME,
		id: 'admsoli_2g8Bkgl3aMALrsa6k1FPL5S1HdH',
		order: 3,
		// label: i18n.t('administratorsSortList.name', { ns: 'dictionaries' }),
		label: 'Name',
		directionIcons: {
			asc: 'sort-alpha-up-alt',
			desc: 'sort-alpha-down-alt',
		},
		function: {
			asc: {
				'name.last': 'asc',
				'name.first': 'asc',
				_createdDate: 'asc',
			},
			desc: {
				'name.last': 'desc',
				'name.first': 'desc',
				_createdDate: 'desc',
			},
		},
	},
}).merge(sortList);
