// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'projects';
const repository = 'Project';

export enum ProjectScopes {
	'Create' = 'projects:create',
	'Read' = 'projects:read',
	'Update' = 'projects:update',
	'Delete' = 'projects:delete',
	'Manage' = 'projects:manage',
}

const namespace = 'depsco';

export const projectScopes: AbilityScopeMap<ProjectScopes> = Map<
	ProjectScopes,
	AbilityScopeItem<ProjectScopes>
>({
	[ProjectScopes.Create]: {
		code: ProjectScopes.Create,
		id: `${namespace}_2FX4lpgOYdX28QT5MDUvo8EeCez`,
		order: 0,
		icon: 'circle-user',
		label: 'Project Create',
		collection,
		repository,
	},
	[ProjectScopes.Read]: {
		code: ProjectScopes.Read,
		id: `${namespace}_2FX4oQgs4VuwrMdxz0APwzUa4ng`,
		order: 1,
		icon: 'circle-user',
		label: 'Project Update',
		collection,
		repository,
	},
	[ProjectScopes.Update]: {
		code: ProjectScopes.Update,
		id: `${namespace}_2FX4xxxVaA854q13keIxgupm3aZ`,
		order: 2,
		icon: 'circle-user',
		label: 'Project Update',
		collection,
		repository,
	},
	[ProjectScopes.Delete]: {
		code: ProjectScopes.Delete,
		id: `${namespace}_2FX4znfmsdvurDA5hgNHh8KLYHg`,
		order: 3,
		icon: 'circle-user',
		label: 'Project Delete',
		collection,
		repository,
	},
	[ProjectScopes.Manage]: {
		code: ProjectScopes.Manage,
		id: `${namespace}_2FX4kG3kinz4KoetuE6aMpXjf8B`,
		order: 4,
		icon: 'circle-user',
		label: 'Project Manage',
		alias: [
			ProjectScopes.Create,
			ProjectScopes.Read,
			ProjectScopes.Update,
			ProjectScopes.Delete,
		],
		collection,
		repository,
	},
});
