// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'engagementProgressLogs';
const repository = 'EngagementProgressLog';

export enum EngagementProgressLogScopes {
	'Read' = 'engagementProgressLogs:read',
}

const namespace = 'engprglogsco';

export const engagementProgressLogScopes: AbilityScopeMap<EngagementProgressLogScopes> = Map<
	EngagementProgressLogScopes,
	AbilityScopeItem<EngagementProgressLogScopes>
>({
	[EngagementProgressLogScopes.Read]: {
		code: EngagementProgressLogScopes.Read,
		id: `${namespace}_2QTUy0qxTFfvbFQyMFFxocTNSCa`,
		order: 0,
		icon: 'circle-user',
		label: 'EngagementProgressLog Read',
		collection,
		repository,
	},
});
