import type { Type } from '@nestjs/common';
import { Map } from 'immutable';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe/assert';
import type { KebabCase, PascalCase } from 'type-fest';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { BaseDictionary, Hex } from './types/dictionaries.type';

/**
 * (kebab-case) external dictionary enum (below) & API endpoints/entities
 *
 * @return enum
 */
export enum Module {
	AccessToken = 'access-token',
	Administrator = 'administrator',
	ApiToken = 'api-token',
	Automation = 'automation',
	AutomationJob = 'automation-job',
	Billing = 'billing',
	BusinessRegistration = 'business-registration',
	Client = 'client',
	Company = 'company',
	ComplianceField = 'compliance-field',
	Department = 'department',
	EmailLog = 'email-log',
	EmailTemplate = 'email-template',
	Engagement = 'engagement',
	EngagementProgressLog = 'engagement-progress-log',
	EngagementReport = 'engagement-report',
	EngagementTemplate = 'engagement-template',
	EventJob = 'event-job',
	Factor = 'factor',
	Field = 'field',
	FieldChoice = 'field-choice',
	File = 'file',
	Form = 'form',
	Import = 'import',
	InsurancePolicy = 'insurance-policy',
	Jurisdiction = 'jurisdiction',
	ManagedServiceProvider = 'managed-service-provider',
	NodeJob = 'node-job',
	Partner = 'partner',
	PersonalAccessToken = 'personal-access-token',
	Project = 'project',
	RequestDevice = 'request-device',
	Role = 'role',
	ScheduleJob = 'schedule-job',
	SecurityLog = 'security-log',
	Session = 'session',
	Skill = 'skill',
	SmsTemplate = 'sms-template',
	Source = 'source',
	Stage = 'stage',
	SurveyAnswer = 'survey-answer',
	SurveyQuestion = 'survey-question',
	Tag = 'tag',
	TaxPayerStatus = 'tax-payer-status',
	TaxRegistration = 'tax-registration',
	Team = 'team',
	Test = 'test',
	User = 'user',
	Vendor = 'vendor',
	Worker = 'worker',
}

export type ModuleKeys = keyof typeof Module;

// compile error if any enum `Module` (value) is not kebab-case
assert<Equals<`${Module}`, KebabCase<Module>>>();

/**
 * (PascalCase) internal map of modules (used in services, repositories, & models)
 * 	- NOTE: previously abilities/app-subjects.dictionary.ts: (enum) AppSubjects
 *
 * @return enum
 */
export enum AppModule {
	AccessToken = 'AccessToken',
	Administrator = 'Administrator',
	ApiToken = 'ApiToken',
	Automation = 'Automation',
	AutomationJob = 'AutomationJob',
	Billing = 'Billing',
	BusinessRegistration = 'BusinessRegistration',
	Client = 'Client',
	Company = 'Company',
	ComplianceField = 'ComplianceField',
	Department = 'Department',
	EmailLog = 'EmailLog',
	EmailTemplate = 'EmailTemplate',
	Engagement = 'Engagement',
	EngagementProgressLog = 'EngagementProgressLog',
	EngagementReport = 'EngagementReport',
	EngagementTemplate = 'EngagementTemplate',
	EventJob = 'EventJob',
	Factor = 'Factor',
	Field = 'Field',
	FieldChoice = 'FieldChoice',
	File = 'File',
	Form = 'Form',
	Import = 'Import',
	InsurancePolicy = 'InsurancePolicy',
	Jurisdiction = 'Jurisdiction',
	ManagedServiceProvider = 'ManagedServiceProvider',
	NodeJob = 'NodeJob',
	Partner = 'Partner',
	PersonalAccessToken = 'PersonalAccessToken',
	Project = 'Project',
	RequestDevice = 'RequestDevice',
	Role = 'Role',
	ScheduleJob = 'ScheduleJob',
	SecurityLog = 'SecurityLog',
	Session = 'Session',
	Skill = 'Skill',
	SmsTemplate = 'SmsTemplate',
	Source = 'Source',
	Stage = 'Stage',
	SurveyAnswer = 'SurveyAnswer',
	SurveyQuestion = 'SurveyQuestion',
	Tag = 'Tag',
	TaxPayerStatus = 'TaxPayerStatus',
	TaxRegistration = 'TaxRegistration',
	Team = 'Team',
	Test = 'Test',
	User = 'User',
	Vendor = 'Vendor',
	Worker = 'Worker',
}

export type AppModulesKeys = keyof typeof AppModule;

// compile error if the enum of `Module` does not match enum `AppModulesKeys`
assert<Equals<ModuleKeys, AppModulesKeys>>();

// compile error if any enum `Module` (value) is not kebab-case
assert<Equals<`${AppModule}`, PascalCase<AppModule>>>();

export interface ModuleItem extends BaseDictionary {
	code: Module;
	// @TODO: @ringer-andrew Rename this for consistency
	repository: AppModule;
	id: `${IdNamespace.Module}_${string}`;
	order: number;
	icon: string;
	label: string;
	desc: string;
	color: Hex;
	schemaClass?: Type<unknown>;
}

export const modules = Map<Module | `${Module}`, ModuleItem>({
	[Module.AccessToken]: {
		code: Module.AccessToken,
		repository: AppModule.AccessToken,
		id: 'modu_2ha5TPnUlthwkfKx92A1A5VhH55',
		order: 0,
		icon: 'circle-user',
		label: 'Access Token',
		desc: '',
		color: '#f59f00',
	},
	[Module.Administrator]: {
		code: Module.Administrator,
		repository: AppModule.Administrator,
		id: 'modu_2InR5Fydvg0gVT3JFqTWnnXlyhC',
		order: 1,
		icon: 'circle-user',
		label: 'Administrator',
		desc: '',
		color: '#f59f00',
	},
	[Module.ApiToken]: {
		code: Module.ApiToken,
		repository: AppModule.ApiToken,
		id: 'modu_2hf9mgRUVcvN3Lrp2whJGQbWq84',
		order: 2,
		icon: 'circle-user',
		label: 'Api Token',
		desc: '',
		color: '#f59f00',
	},
	[Module.Automation]: {
		code: Module.Automation,
		repository: AppModule.Automation,
		id: 'modu_2JILEMTg2DMaA0nk0avkdJUTkE0',
		order: 3,
		icon: 'circle-user',
		label: 'Automation',
		desc: '',
		color: '#f59f00',
	},
	[Module.Billing]: {
		code: Module.Billing,
		repository: AppModule.Billing,
		id: 'modu_2iQw1sxqLNNsKK0XatWXyRrYy8O',
		order: 4,
		icon: 'circle-user',
		label: 'Billing',
		desc: '',
		color: '#f59f00',
	},
	[Module.AutomationJob]: {
		code: Module.AutomationJob,
		repository: AppModule.AutomationJob,
		id: 'modu_2PFEcW6nZ064KHfxRadFC5qlJ6k',
		order: 5,
		icon: 'circle-user',
		label: 'Automation Job',
		desc: '',
		color: '#f59f00',
	},
	[Module.BusinessRegistration]: {
		code: Module.BusinessRegistration,
		repository: AppModule.BusinessRegistration,
		id: 'modu_2InR5Eih1kNQ1gUkQh0PZePQ4Xp',
		order: 6,
		icon: 'circle-user',
		label: 'Business Registration',
		desc: '',
		color: '#f59f00',
	},
	[Module.Client]: {
		code: Module.Client,
		repository: AppModule.Client,
		id: 'modu_2InR5JZhH1kG0CKCydJx47v4rnr',
		order: 7,
		icon: 'circle-user',
		label: 'Client',
		desc: '',
		color: '#f59f00',
	},
	[Module.Company]: {
		code: Module.Company,
		repository: AppModule.Company,
		id: 'modu_2InR5K3IUiJc5mavkvFRoU4TXy5',
		order: 8,
		icon: 'circle-user',
		label: 'Company',
		desc: '',
		color: '#f59f00',
		// schemaClass: Company, @TODO: @Andrew: Debug referencing Schema class in modules
	},
	[Module.ComplianceField]: {
		code: Module.ComplianceField,
		repository: AppModule.ComplianceField,
		id: 'modu_2InR5GpbcMIQqLniEQAQqEYIr7H',
		order: 9,
		icon: 'circle-user',
		label: 'Compliance Field',
		desc: '',
		color: '#f59f00',
	},
	[Module.Department]: {
		code: Module.Department,
		repository: AppModule.Department,
		id: 'modu_2InR5HdY8ArWb8SS5lMW1JXWe6L',
		order: 10,
		icon: 'circle-user',
		label: 'Department',
		desc: '',
		color: '#f59f00',
	},
	[Module.EmailLog]: {
		code: Module.EmailLog,
		repository: AppModule.EmailLog,
		id: 'modu_2hf9mcEXdMF2fBEmdj7HOdgBMDi',
		order: 11,
		icon: 'circle-user',
		label: 'Email Log',
		desc: '',
		color: '#f59f00',
	},
	[Module.EmailTemplate]: {
		code: Module.EmailTemplate,
		repository: AppModule.EmailTemplate,
		id: 'modu_2InR5D4sZAKOC9Un8sfsPtUj4Mj',
		order: 12,
		icon: 'circle-user',
		label: 'Email Template',
		desc: '',
		color: '#f59f00',
	},
	[Module.Engagement]: {
		code: Module.Engagement,
		repository: AppModule.Engagement,
		id: 'modu_2InR5JMgFLaLdmlAHSDKCRvLqjS',
		order: 13,
		icon: 'circle-user',
		label: 'Engagement',
		desc: '',
		color: '#f59f00',
	},
	[Module.EngagementProgressLog]: {
		code: Module.EngagementProgressLog,
		repository: AppModule.EngagementProgressLog,
		id: 'modu_2hf9mhEybRwLLCfIuZgCDEctqKJ',
		order: 14,
		icon: 'circle-user',
		label: 'Engagement Progress Log',
		desc: '',
		color: '#f59f00',
	},
	[Module.EngagementReport]: {
		code: Module.EngagementReport,
		repository: AppModule.EngagementReport,
		id: 'modu_2RwMlTBntAmuDOfFyqZJMjGbYX6',
		order: 15,
		icon: 'circle-user',
		label: 'Engagement Report',
		desc: '',
		color: '#f59f00',
	},
	[Module.EngagementTemplate]: {
		code: Module.EngagementTemplate,
		repository: AppModule.EngagementTemplate,
		id: 'modu_2InR5JoPRks0HxDNE4ciXfMrCVq',
		order: 16,
		icon: 'circle-user',
		label: 'Engagement Template',
		desc: '',
		color: '#f59f00',
	},
	[Module.EventJob]: {
		code: Module.EventJob,
		repository: AppModule.EventJob,
		id: 'modu_2PFEdmhufsIOzm9QeXw81oK0rSU',
		order: 17,
		icon: 'circle-user',
		label: 'Event Job',
		desc: '',
		color: '#f59f00',
	},
	[Module.Factor]: {
		code: Module.Factor,
		repository: AppModule.Factor,
		id: 'modu_2InR5Gn6RI56NDd76OrH1YAluR0',
		order: 18,
		icon: 'circle-user',
		label: 'Factor',
		desc: '',
		color: '#f59f00',
	},
	[Module.Field]: {
		code: Module.Field,
		repository: AppModule.Field,
		id: 'modu_2InR5CdIS26qcaMSpgoF7hH9MPz',
		order: 19,
		icon: 'circle-user',
		label: 'Field',
		desc: '',
		color: '#f59f00',
	},
	[Module.FieldChoice]: {
		code: Module.FieldChoice,
		repository: AppModule.FieldChoice,
		id: 'modu_2InR5IoMnisK3KHXZRulS0k5Qz9',
		order: 20,
		icon: 'circle-user',
		label: 'Field Choice',
		desc: '',
		color: '#f59f00',
	},
	[Module.File]: {
		code: Module.File,
		repository: AppModule.File,
		id: 'modu_2InR5JBfpzB0VVsmGVaViwj8ajH',
		order: 21,
		icon: 'circle-user',
		label: 'File',
		desc: '',
		color: '#f59f00',
	},
	[Module.Form]: {
		code: Module.Form,
		repository: AppModule.Form,
		id: 'modu_2InR5JEimPFo2iNaa80nFmdar2O',
		order: 22,
		icon: 'circle-user',
		label: 'Form',
		desc: '',
		color: '#f59f00',
	},
	[Module.Import]: {
		code: Module.Import,
		repository: AppModule.Import,
		id: 'modu_2hf9meQG6zZwWO3rAbsN5HQ6pys',
		order: 23,
		icon: 'circle-user',
		label: 'Import',
		desc: '',
		color: '#f59f00',
	},
	[Module.InsurancePolicy]: {
		code: Module.InsurancePolicy,
		repository: AppModule.InsurancePolicy,
		id: 'modu_2InR5JxQlirB76qAs2UWf7qmQjG',
		order: 24,
		icon: 'circle-user',
		label: 'Insurance Policy',
		desc: '',
		color: '#f59f00',
	},
	[Module.Jurisdiction]: {
		code: Module.Jurisdiction,
		repository: AppModule.Jurisdiction,
		id: 'modu_2hf9mevjkTLVeBYeorIcSRBl2JI',
		order: 25,
		icon: 'circle-user',
		label: 'Jurisdiction',
		desc: '',
		color: '#f59f00',
	},
	[Module.ManagedServiceProvider]: {
		code: Module.ManagedServiceProvider,
		repository: AppModule.ManagedServiceProvider,
		id: 'modu_2InR5FSR8GERxvyFUrUBtw8lvt1',
		order: 26,
		icon: 'circle-user',
		label: 'Managed Service Provider',
		desc: '',
		color: '#f59f00',
	},
	[Module.NodeJob]: {
		code: Module.NodeJob,
		repository: AppModule.NodeJob,
		id: 'modu_2PFEeoWtIpMokphFClSRd90HuoG',
		order: 27,
		icon: 'circle-user',
		label: 'Node Job',
		desc: '',
		color: '#f59f00',
	},
	[Module.Partner]: {
		code: Module.Partner,
		repository: AppModule.Partner,
		id: 'modu_2hf9ma2s2XD1aXR6NQikwkrL70U',
		order: 28,
		icon: 'circle-user',
		label: 'Partner',
		desc: '',
		color: '#f59f00',
	},
	[Module.PersonalAccessToken]: {
		code: Module.PersonalAccessToken,
		repository: AppModule.PersonalAccessToken,
		id: 'modu_2hf9menYKzdkdVlJNAXUc7Fq78k',
		order: 29,
		icon: 'circle-user',
		label: 'Personal Access Token',
		desc: '',
		color: '#f59f00',
	},
	[Module.Project]: {
		code: Module.Project,
		repository: AppModule.Project,
		id: 'modu_2hf9mbl9BXEPheTJ01FnU5775OV',
		order: 30,
		icon: 'circle-user',
		label: 'Project',
		desc: '',
		color: '#f59f00',
	},
	[Module.RequestDevice]: {
		code: Module.RequestDevice,
		repository: AppModule.RequestDevice,
		id: 'modu_2hf9magZO5TsNzBGzlwWWswgFWq',
		order: 31,
		icon: 'circle-user',
		label: 'Request Device',
		desc: '',
		color: '#f59f00',
	},
	[Module.Role]: {
		code: Module.Role,
		repository: AppModule.Role,
		id: 'modu_2InR5FqqtSq7mZR50gW6FfrqFyd',
		order: 32,
		icon: 'circle-user',
		label: 'Role',
		desc: '',
		color: '#f59f00',
	},
	[Module.ScheduleJob]: {
		code: Module.ScheduleJob,
		repository: AppModule.ScheduleJob,
		id: 'modu_2PFEg164SEty9riP2Iu69U7X0eJ',
		order: 33,
		icon: 'circle-user',
		label: 'Schedule Job',
		desc: '',
		color: '#f59f00',
	},
	[Module.SecurityLog]: {
		code: Module.SecurityLog,
		repository: AppModule.SecurityLog,
		id: 'modu_2InR5F3M1L7tmaL5Fq1hI2PhX70',
		order: 34,
		icon: 'circle-user',
		label: 'Security Log',
		desc: '',
		color: '#f59f00',
	},
	[Module.Session]: {
		code: Module.Session,
		repository: AppModule.Session,
		id: 'modu_2NJ5keH4oDBDY6GciK5yPFhq3J6',
		order: 35,
		icon: 'circle-user',
		label: 'Session',
		desc: '',
		color: '#f59f00',
	},
	[Module.Skill]: {
		code: Module.Skill,
		repository: AppModule.Skill,
		id: 'modu_2InR5EN1WISucdoek72xg77Xu9J',
		order: 36,
		icon: 'circle-user',
		label: 'Skill',
		desc: '',
		color: '#f59f00',
	},
	[Module.SmsTemplate]: {
		code: Module.SmsTemplate,
		repository: AppModule.SmsTemplate,
		id: 'modu_2InRKmF1ZW09w363LVnH91wjF0E',
		order: 37,
		icon: 'circle-user',
		label: 'Sms Template',
		desc: '',
		color: '#f59f00',
	},
	[Module.Source]: {
		code: Module.Source,
		repository: AppModule.Source,
		id: 'modu_2InRKksWQ3PGg6YGNrX3FmCQZQR',
		order: 38,
		icon: 'circle-user',
		label: 'Source',
		desc: '',
		color: '#f59f00',
	},
	[Module.Stage]: {
		code: Module.Stage,
		repository: AppModule.Stage,
		id: 'modu_2InRKiraUvI8GRusnbDbFVAazBv',
		order: 39,
		icon: 'circle-user',
		label: 'Stage',
		desc: '',
		color: '#f59f00',
	},
	[Module.SurveyAnswer]: {
		code: Module.SurveyAnswer,
		repository: AppModule.SurveyAnswer,
		id: 'modu_2hf9mfeNCfsR8h5LjCtYAklVVEU',
		order: 40,
		icon: 'circle-user',
		label: 'Survey Answer',
		desc: '',
		color: '#f59f00',
	},
	[Module.SurveyQuestion]: {
		code: Module.SurveyQuestion,
		repository: AppModule.SurveyQuestion,
		id: 'modu_2hfAkBP6tnsRaQvzWc4OkJ4kUtO',
		order: 41,
		icon: 'circle-user',
		label: 'Survey Question',
		desc: '',
		color: '#f59f00',
	},
	[Module.Tag]: {
		code: Module.Tag,
		repository: AppModule.Tag,
		id: 'modu_2InRKm7vHafYhuvEKwNBHO7dGwG',
		order: 42,
		icon: 'circle-user',
		label: 'Tag',
		desc: '',
		color: '#f59f00',
	},
	[Module.TaxPayerStatus]: {
		code: Module.TaxPayerStatus,
		repository: AppModule.TaxPayerStatus,
		id: 'modu_2InRKi7PzrpjfwmliJLPwO2ou1W',
		order: 43,
		icon: 'circle-user',
		label: 'Tax Payer Status',
		desc: '',
		color: '#f59f00',
	},
	[Module.TaxRegistration]: {
		code: Module.TaxRegistration,
		repository: AppModule.TaxRegistration,
		id: 'modu_2InRKgUjwViYYeAV4vYLTf7YBfe',
		order: 44,
		icon: 'circle-user',
		label: 'Tax Registration',
		desc: '',
		color: '#f59f00',
	},
	[Module.Team]: {
		code: Module.Team,
		repository: AppModule.Team,
		id: 'modu_2InRKi8ETld0GyyReQxnSXaKKZT',
		order: 45,
		icon: 'circle-user',
		label: 'Team',
		desc: '',
		color: '#f59f00',
	},
	[Module.Test]: {
		code: Module.Test,
		repository: AppModule.Test,
		id: 'modu_2InRKgKTyLoyceWNhGLApyIqTLG',
		order: 46,
		icon: 'circle-user',
		label: 'Test',
		desc: '',
		color: '#f59f00',
	},
	[Module.User]: {
		code: Module.User,
		repository: AppModule.User,
		id: 'modu_2InRKmNx7eM4UYTzW1DyT2CunbL',
		order: 47,
		icon: 'circle-user',
		label: 'User',
		desc: '',
		color: '#f59f00',
	},
	[Module.Vendor]: {
		code: Module.Vendor,
		repository: AppModule.Vendor,
		id: 'modu_2InRKfBIovUR9fgPQROP9mGfQdu',
		order: 48,
		icon: 'circle-user',
		label: 'Vendor',
		desc: '',
		color: '#f59f00',
	},
	[Module.Worker]: {
		code: Module.Worker,
		repository: AppModule.Worker,
		id: 'modu_2InRKeqIyXDgvhX42G5jCHaoO0L',
		order: 49,
		icon: 'circle-user',
		label: 'Worker',
		desc: '',
		color: '#f59f00',
	},
});
