const path = require('path');
const { isString, isNil } = require('lodash');
const semverMajor = require('semver/functions/major');
const {
	booleanValues,
	booleanLikeValues,
	stringToBoolean,
} = require('../common/helpers/basic.helpers');

/**
 * @param {("local"|"development"|"stage"|"demo"|"production")} ENVIRONMENTS Enum of possible environments
 */
const ENVIRONMENTS = {
	Local: 'local',
	Development: 'development',
	Stage: 'stage',
	Demo: 'demo',
	Production: 'production',
};

/**
 * @param {("loc"|"dev"|"stg"|"dmo"|"prd")} ENVIRONMENT_SHORTHANDS Enum of possible environment shorthands
 */
const ENVIRONMENT_SHORTHANDS = {
	local: 'loc',
	development: 'dev',
	stage: 'stg',
	demo: 'dmo',
	production: 'prd',
};

/** @type string */
const ENV = isString(process.env.NODE_ENV)
	? String(process.env.NODE_ENV).trim()
	: ENVIRONMENTS.Production;

/** @type {("loc"|"dev"|"stg"|"dmo"|"prd")} */
const ENV_SHORTHAND = Object.keys(ENVIRONMENT_SHORTHANDS).includes(ENV)
	? // @ts-ignore
	  ENVIRONMENT_SHORTHANDS[ENV]
	: ENVIRONMENT_SHORTHANDS.production;

/** @type boolean | string */
const IS_DEBUG =
	(!isNil(process.env.DEBUG) && booleanValues.includes(process.env.DEBUG)
		? stringToBoolean(process.env.DEBUG)
		: false) ||
	(!isNil(process.env['npm_config_debug']) &&
	booleanValues.includes(process.env['npm_config_debug'])
		? stringToBoolean(process.env['npm_config_debug'])
		: false);

/** @type boolean */
const IS_WATCH =
	!isNil(process.env.IS_WATCH) && booleanValues.includes(process.env.IS_WATCH)
		? stringToBoolean(process.env.IS_WATCH)
		: false;

/** @type boolean */
const IS_START =
	!isNil(process.env.IS_START) && booleanValues.includes(process.env.IS_START)
		? stringToBoolean(process.env.IS_START)
		: false;

/** @type boolean */
const IS_LOCAL_REDIS =
	!isNil(process.env.IS_LOCAL_REDIS) && booleanValues.includes(process.env.IS_LOCAL_REDIS)
		? stringToBoolean(process.env.IS_LOCAL_REDIS)
		: false;

/** @type string */
const APP_VERSION = isString(process.env.npm_package_version)
	? String(process.env.npm_package_version)
	: isString(process.env.APP_VERSION)
	? String(process.env.APP_VERSION).trim()
	: '1.0.0';
// console.debug('[NOTICE] APP_VERSION:', { npm_package_version: process.env.npm_package_version, APP_VERSION });
process.env.APP_VERSION = APP_VERSION;

/** @type string */
const APP_VERSION_MAJOR = String(semverMajor(APP_VERSION));

/** @type boolean */
const IS_MOBILE_APP_BUILD =
	!isNil(process.env.IS_MOBILE_APP_BUILD) &&
	booleanValues.includes(process.env.IS_MOBILE_APP_BUILD)
		? stringToBoolean(process.env.IS_MOBILE_APP_BUILD)
		: false;

/** @type boolean */
const IS_CONTINUOUS_INTEGRATION_BUILD =
	(!isNil(process.env.IS_CONTINUOUS_INTEGRATION_BUILD) &&
	booleanValues.includes(process.env.IS_CONTINUOUS_INTEGRATION_BUILD)
		? stringToBoolean(process.env.IS_CONTINUOUS_INTEGRATION_BUILD)
		: false) ||
	(!isNil(process.env.IS_CI) && booleanValues.includes(process.env.IS_CI)
		? stringToBoolean(process.env.IS_CI)
		: false);

/** @type boolean */
const IS_SANDBOX =
	!isNil(process.env.IS_SANDBOX) && booleanValues.includes(process.env.IS_SANDBOX)
		? stringToBoolean(process.env.IS_SANDBOX)
		: false;

/** @type boolean */
const IS_ANALYZE =
	!isNil(process.env.IS_ANALYZE) && booleanValues.includes(process.env.IS_ANALYZE)
		? stringToBoolean(process.env.IS_ANALYZE)
		: false;

/**
 * @TEMP FOR DEBUGGING
 *
 * Case A: serverless-webpack bundle's node_modules
 * 	- WHEN:
 * 		- serverless config ~ { custom.webpack.includeModules: {packagePath: (string), nodeModulesRelativeDir: (string)} }
 * 		- webpack config ~ externals: [ nodeExternals({ modulesDir: (string) }) ]
 * 	- RESULT
 * 		- serverless packages node_module dependencies
 * 		- No tree-shaking, minification, or optimizations
 * 	- OUTPUT
 * 		- dist/apps/api/dependencies
 *
 * Case B: webpack bundle's nodule_modules
 * 	- WHEN:
 * 		- serverless config ~ { custom.webpack.includeModules: false }
 * 		- webpack config ~ externals: []
 * 	- RESULT
 * 		- webpack packages node_module dependencies (into 1 single file per serverless handler)
 * 		- Webpack-enabled tree-shaking, minification, & optimizations
 * 	- OUTPUT
 * 		- dist/apps/api/src/{individual file per handler}
 */
/** @type boolean */
const IS_WEBPACK_BUNDLE_NODE_MODULES = stringToBoolean(
	process.env.IS_WEBPACK_BUNDLE_NODE_MODULES ??
		(['local', 'development'].includes(ENV) ? 'false' : 'true')
);

/** @type string */
const ROOT_DIRECTORY = path.resolve(
	isString(process.env.INIT_PWD)
		? String(process.env.INIT_PWD)
		: isString(process.env.INIT_CWD)
		? String(process.env.INIT_CWD)
		: path.join(__dirname, '..', path.sep, '..')
);
// console.debug('ROOT_DIRECTORY:', path.resolve(ROOT_DIRECTORY));

/** @type string */
const NODE_MODULES_DIRECTORY = path.resolve(path.join(ROOT_DIRECTORY, 'node_modules'));

/** @type string */
const DIST_DIRECTORY = path.resolve(path.join(ROOT_DIRECTORY, 'dist', 'apps'));
// console.debug('DIST_DIRECTORY:', DIST_DIRECTORY);

/**
 * @param {string} env The current environment to check
 * @return {boolean}
 */
const isEnvironmentDevelopment = (env) =>
	[ENVIRONMENTS.Local, ENVIRONMENTS.Development].includes(env);

/**
 * @param {string} env The current environment to check
 * @return {boolean}
 */
const isEnvironmentProduction = (env) =>
	[ENVIRONMENTS.Stage, ENVIRONMENTS.Demo, ENVIRONMENTS.Production].includes(env);

/**
 * @param {string[]} paths Array of path strings to join
 * @return {string}
 */
const getFromBase = (...paths) => path.resolve(path.join(ROOT_DIRECTORY, ...paths));

/**
 * @param {string} filePath Current file path to return production version
 * @return {string}
 */
const getProdFileName = (filePath = '') => {
	// console.info(`[INFO] getProdFileName(${filePath})`);
	return `${filePath.replace(/\.[^/.]+$/, '')}.prod${path.extname(filePath)}`;
};

module.exports = {
	ENVIRONMENTS,
	ENVIRONMENT_SHORTHANDS,
	ENV,
	ENV_SHORTHAND,
	IS_DEBUG,
	IS_WATCH,
	IS_START,
	IS_LOCAL_REDIS,
	APP_VERSION,
	APP_VERSION_MAJOR,
	IS_MOBILE_APP_BUILD,
	IS_CONTINUOUS_INTEGRATION_BUILD,
	IS_SANDBOX,
	IS_ANALYZE,
	IS_WEBPACK_BUNDLE_NODE_MODULES,
	ROOT_DIRECTORY,
	NODE_MODULES_DIRECTORY,
	DIST_DIRECTORY,
	isEnvironmentDevelopment,
	isEnvironmentProduction,
	getFromBase,
	getProdFileName,
};
