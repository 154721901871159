/**
 * ACRONYMS GENERATOR
 * 	https://www.dcode.fr/acronym-generator
 *
 * SHARED ACRONYMS
 * 	Adaptor = 'adp',
 * 	Billing = 'bill',
 * 	Choice = 'cho',
 * 	Default = 'def',
 * 	Durations = 'dura'.
 * 	Email = 'em',
 * 	EmailTemplate = 'emtem',
 * 	Event = 'evt',
 * 	Field = 'fld',
 * 	Filter = 'fil',
 * 	Format = 'for',
 * 	Import = 'imp',
 * 	Kind = 'knd',
 * 	List = 'li',
 * 	Onboarding = 'onb',
 * 	Partner = 'ptnr',
 * 	PreProcess = 'prepro',
 * 	Priorities = 'prio',
 * 	Progress = 'prg'
 * 	Rate = 'rt'
 * 	Scope = 'sco',
 * 	Send = 'snd',
 * 	SortList = 'soli',
 * 	Spreadsheet = 'spr',
 * 	Status = 'sta',
 * 	Task = 'tsk',
 * 	Transporter = 'tra',
 * 	Trigger = 'trg',
 * 	Type = 'ty',
 * 	Variant = 'vrt',
 * 	Worker = 'wkr'
 * 	Zone = 'zo'
 *  File = 'fi',
 *  QueryFunction = 'qryfun',
 */

export enum IdNamespace {
	// Collections
	AccessToken = 'ato',
	Administrator = 'usr',
	AdministratorType = 'admtyp',
	Automation = 'auto',
	BusinessRegistration = 'busreg',
	Billing = 'bill',
	Client = 'com',
	Company = 'com',
	ComplianceField = 'comfld',
	Department = 'dep',
	DocumentType = 'docty',
	EmailLog = 'emlog',
	EmailTemplate = 'emtem',
	Engagement = 'eng',
	EngagementProgressLog = 'engprglog',
	EngagementReport = 'engrept',
	EngagementSurvey = 'engsrv',
	EngagementTemplate = 'engtem',
	Factor = 'fact',
	Field = 'fld',
	FieldChoice = 'fldcho',
	File = 'fi',
	Form = 'form',
	Import = 'imp',
	InsurancePolicy = 'insupo',
	Jurisdiction = 'jur',
	ManagedServiceProvider = 'com',
	Partner = 'com',
	PersonalAccessToken = 'ato',
	Project = 'prj',
	RequestDevice = 'rqstdvc',
	Role = 'role',
	SecurityLog = 'seclog',
	Session = 'sessn',
	Skill = 'skill',
	SmsTemplate = 'smstem',
	Source = 'sorce',
	Stage = 'stage',
	SurveyAnswer = 'srvyans',
	SurveyQuestion = 'srvyqstn',
	Tag = 'tag',
	TaxPayerStatus = 'taxpsta',
	TaxRegistration = 'taxreg',
	Team = 'team',
	Test = 'test',
	User = 'usr',
	Vendor = 'com',
	Worker = 'usr',

	// Dictionaries
	AccessTokenExpirationType = 'atoety',
	AccessTokenType = 'atoty',
	AdministratorOnboardingTask = 'admonbtsk',
	AdministratorSortList = 'admsoli',
	AuthenticationType = 'authty',
	AutomationFilter = 'autofil',
	AutomationJobStatus = 'autojobsta',
	AutomationNodeType = 'autondty',
	AutomationSortList = 'autosoli',
	AutomationStatus = 'autosta',
	AutomationTriggerType = 'autotrgty',
	AutomationType = 'autoty',
	AverageHoursPerWeekList = 'ahouli',
	AverageNumberOfWorkersPerYearList = 'anwkryli',
	BooleanList = 'bool',
	BusinessStructure = 'bustru',
	BusinessTeamSize = 'bustesiz',
	BusinessTeamType = 'bustety',
	BusinessValuePropositionList = 'busvproli',
	ClientFilter = 'cliefil',
	ClientSortList = 'cliesoli',
	CompanySortList = 'compsoli',
	CompanyType = 'comty',
	Country = 'cou',
	Currency = 'cur',
	DataReadQueryType = 'dtrdqryty',
	// @TODO: @BREAKING Should be renamed for consistently Type/"ty", dtwroptyp -> dtwropty
	DataWriteOperationType = 'dtwroptyp',
	DataZone = 'dazo',
	DateFormat = 'dafor',
	DefaultManagedCompanyType = 'defmcty',
	DepartmentSortList = 'depsoli',
	DeviceFingerprintType = 'dfpty',
	DocumentKind = 'docknd',
	DocumentStatus = 'docsta',
	ElementType = 'elty',
	EmailTemplateFilter = 'emtemfil',
	EmailTemplateSortList = 'emtemsoli',
	EmailTemplateType = 'emtemty',
	EmailTransporter = 'emtra',
	EngagementDeterminationStatus = 'engdesta',
	EngagementFilter = 'engfil',
	EngagementManagerWizardStep = 'engmngrwzstep',
	EngagementProgressLogSortList = 'engprglogsoli',
	EngagementProgressStatus = 'engprgsta',
	EngagementProgressType = 'engprgty',
	EngagementSortList = 'engsoli',
	EngagementStatus = 'engsta',
	EngagementSurveySortList = 'engsrvysoli',
	EngagementTemplateFilter = 'engtemfil',
	EngagementTemplateSortList = 'engtemsoli',
	EngagementWorkerWizardStep = 'engwrkwzstep',
	FactorFilter = 'factfil',
	FactorSortList = 'factsoli',
	FieldEntity = 'fldenti',
	FieldStateList = 'fldstali',
	FieldType = 'fldty',
	FileFilter = 'fifil',
	FileFormat = 'fifor',
	FileProvider = 'fipro',
	FileSortList = 'fisoli',
	FileType = 'fity',
	HumanNameFormat = 'hunamefor',
	IdNamespace = 'iname',
	ImportSortList = 'impsoli',
	ImportSpreadsheet = 'impspr',
	ImportStatus = 'impsta',
	ImportType = 'impty',
	InsurancePolicyStatus = 'insuposta',
	InsurancePolicyType = 'insupoty',
	IntentionType = 'intety',
	JurisdictionCosts = 'jurcos',
	JurisdictionFilter = 'jurfil',
	JurisdictionSortList = 'jursoli',
	JurisdictionTimelines = 'jurtili',
	JurisdictionType = 'jurty',
	Language = 'lang',
	LocationType = 'locty',
	MarketplacePartner = 'marptnr',
	Module = 'modu',
	OnboardingStatus = 'onbsta',
	PartnerFilter = 'ptnrfil',
	PartnerSortList = 'ptnrsoli',
	PartnerType = 'ptnrty',
	PayRateUnit = 'payrtu',
	PhoneType = 'photy',
	PredefinedRole = 'predefrole',
	ProgressList = 'prgli',
	ProjectSortList = 'projsoli',
	QueryFunctionDuration = 'qryfundura',
	QuestionType = 'qstnty',
	QuestionTypeYesNo = 'qtyesno',
	RecordStatus = 'recsta',
	RiskWithRecommendationIgnoreDuration = 'riwireigdura',
	RiskWithRecommendationList = 'riwireli',
	RiskWithRecommendationPriority = 'riwireprio',
	RiskWithRecommendationStatus = 'riwiresta',
	RoleSortList = 'rolesoli',
	RoleType = 'rolety',
	SecurityEvent = 'secue',
	SkillType = 'skillty',
	SmsTemplateFilter = 'smstemfil',
	SmsTemplateSortList = 'smstemsoli',
	SortList = 'soli',
	SourceSortList = 'sorcesoli',
	StageSortList = 'stagesoli',
	SurveyFrequency = 'srvyfreq',
	SurveyQuestionFilter = 'srvyqstnfil',
	SurveyQuestionSortList = 'srvyqstnsoli',
	SystemLanguage = 'slang',
	TagSortList = 'tagsoli',
	TeamSortList = 'teamsoli',
	TestFilter = 'testfil',
	TestSortList = 'testsoli',
	Theme = 'theme',
	TimeFormat = 'timefor',
	Timezone = 'timezo',
	TitleList = 'tili',
	UserSortList = 'usrsoli',
	UserType = 'usrty',
	VendorFilter = 'comfil',
	WorkerFilter = 'wkrfil',
	WorkerOnboardingTask = 'wkronbtsk',
	WorkerSortList = 'wkrsoli',
	WorkerStatus = 'wkrsta',
	WorkerStep = 'wkrste',

	// Ability Accessible By Queries
	AccessTokenAccessibleByQuery = 'atoaccqry',
	AdministratorAccessibleByQuery = 'admaccsqry',
	BusinessRegistrationAccessibleByQuery = 'busregaccqry',
	ClientAccessibleByQuery = 'comaccqry',
	CompanyAccessibleByQuery = 'comaccqry',
	EmailLoginAccessibleByQuery = 'emailogaccqry',
	EmailTemplateAccessibleByQuery = 'emtemaccqry',
	EngagementAccessibleByQuery = 'engaccqry',
	EngagementProgressLogAccessibleByQuery = 'engprglogaccqry',
	EngagementReportAccessibleByQuery = 'engreptaccqry',
	EngagementSurveyAccessibleByQuery = 'engsrvaccqry',
	EngagementTemplateAccessibleByQuery = 'engtemaccqry',
	FileAccessibleByQuery = 'fiaccqry',
	ImportAccessibleByQuery = 'impaccqry',
	InsurancePolicyAccessibleByQuery = 'insupoaccqry',
	PartnerAccessibleByQuery = 'ptnraccqry',
	PersonalAccessTokenAccessibleByQuery = 'atoaccqry',
	ProjectAccessibleByQuery = 'prjaccqry',
	RequestDeviceAccessibleByQuery = 'rqstdvcaccqry',
	RoleAccessibleByQuery = 'roleaccqry',
	SessionAccessibleByQuery = 'sessnaccqry',
	SmsTemplateAccessibleByQuery = 'smstemaccqry',
	SurveyAnswerAccessibleByQuery = 'srvyansaccqry',
	TaxPayerStatusAccessibleByQuery = 'taxpstaaccqry',
	TaxRegistrationAccessibleByQuery = 'taxregaccqry',
	UserAccessibleByQuery = 'usraccqry',
	VendorAccessibleByQuery = 'comaccqry',
	WorkerAccessibleByQuery = 'usraccqry',

	// Predefined Queries
	EngagementQuery = 'engqry',
	EmailTemplatePredefinedQuery = 'emtempredefqry',

	// Predefined Query Functions
	AdministratorQueryFunction = 'admqryfun',
	EngagementQueryFunction = 'engqryfun',

	// Predefined Aggregates
	EngagementAggregate = 'engaggre',

	// Predefined Writes
	EngagementWrite = 'engwrt',

	// PreProcesses
	DataWritePreProcess = 'dtwrtprepro',
	SendEmailPreProcess = 'sndemprepro',
	SendSMSPreProcess = 'sndsmsprepro',
	EmailTemplatePreProcess = 'emtemprepro',

	// Worker Node PostProcess
	DataReadPostProcess = 'dtrdpopro',
	SendEmailPostProcess = 'sndempopro',
	SendSMSPostProcess = 'sndsmspopro',

	// Scopes
	AccessTokenScope = 'actosco',
	AdministratorScopes = 'adsco',
	AutomationJobScope = 'autojobsco',
	AutomationScope = 'autosco',
	BillingScope = 'billsco',
	BusinessRegistrationScope = 'busregsco',
	ClientScope = 'comsco',
	ComplianceFieldScope = 'comfldsco',
	DepartmentScope = 'depsco',
	DocumentTypeScope = 'doctysco',
	EmailLogScope = 'emlogsco',
	EmailTemplateScope = 'emtemsco',
	EngagementProgressLogScope = 'engprglogsco',
	EngagementReportScope = 'engreptsco',
	EngagementScope = 'engsco',
	EngagementSurveyScope = 'engsrvsco',
	EngagementTemplateScope = 'engtemsco',
	EventJobScope = 'evejobsco',
	FactorScope = 'factsco',
	FieldChoiceScope = 'fldchosco',
	FieldScope = 'fldsco',
	FileScope = 'fisco',
	FormScope = 'formsco',
	ImportScope = 'impsco',
	InsurancePolicyScope = 'insuposco',
	JurisdictionScope = 'jursco',
	ManagedServiceProviderScope = 'comsco',
	NodeJobScope = 'nodjobsco',
	PartnerScope = 'ptnrsco',
	PersonalAccessTokenScope = 'atosco',
	ProjectScope = 'prjsco',
	RequestDeviceScope = 'rqstdvcsco',
	RoleScope = 'rolesco',
	ScheduleJobScope = 'schjobsco',
	SecurityLogScope = 'seclogsco',
	SkillScope = 'skillsco',
	SmsTemplateScope = 'smstemsco',
	SourceScope = 'sorcesco',
	StageScope = 'stagesco',
	SurveyAnswerScope = 'srvyanssco',
	SurveyQuestionScope = 'srvyqstnsco',
	TagScope = 'tagsco',
	TaxPayerStatusScope = 'taxpstasco',
	TaxRegistrationScope = 'taxregsco',
	TeamScope = 'teamsco',
	TestScope = 'testsco',
	VendorScope = 'comsco',
	WorkerScope = 'usrsco',

	// Webhook Events
	AutomationEvent = 'autoevt',
	BusinessRegistrationEvent = 'busregevt',
	ClientEvent = 'comevt',
	ComplianceFieldEvent = 'comfldevt',
	DepartmentEvent = 'depevt',
	DocumentTypeEvent = 'doctyevt',
	EmailLogEvent = 'emllogevt',
	EmailTemplateEvent = 'emtemevt',
	EngagementEvent = 'engevt',
	EngagementProgressLogEvent = 'engprgevt',
	EngagementReportEvent = 'engreptevt',
	EngagementSurveyEvent = 'engsrvevt',
	EngagementTemplateEvent = 'engtemevt',
	FactorEvent = 'factevt',
	FieldChoiceEvent = 'fldchoevt',
	FieldEvent = 'fldevt',
	FileEvent = 'fievt',
	FormEvent = 'formevt',
	InsurancePolicyEvent = 'insupoevt',
	ManagedServiceProviderEvent = 'comevt',
	PartnerEvent = 'ptnrevt',
	PersonalAccessTokenEvent = 'atoevt',
	ProjectEvent = 'prjevt',
	RoleEvent = 'roleevt',
	SecurityLogEvent = 'seclogevt',
	SkillEvent = 'skillevt',
	SourceEvent = 'sorceevt',
	StageEvent = 'stageevt',
	SurveyAnswerEvent = 'srvyevt',
	SurveyQuestionEvent = 'srvyqstnevt',
	TagEvent = 'tagevt',
	TaxPayerStatusEvent = 'taxpstaevt',
	TaxRegistrationEvent = 'taxregevt',
	TeamEvent = 'teamevt',
	TestEvent = 'testevt',
	VendorEvent = 'comevt',
	WorkerEvent = 'usrevt',

	// Automation Queue Jobs
	AutomationJob = 'autojob',
	EventJob = 'evtjob',
	NodeJob = 'nodejob',
	ScheduleJob = 'schjob',

	NodeJobArray = 'nodearry',

	// Conditions
	EngagementConditions = 'engcond',

	// Transforms
	WorkerTransforms = 'wrktsf',
}
