// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'skills';
const repository = 'Skill';

export enum SkillScopes {
	'Create' = 'skills:create',
	'Read' = 'skills:read',
	'Update' = 'skills:update',
	'Delete' = 'skills:delete',
	'Manage' = 'skills:manage',
}

const namespace = 'skillsco';

export const skillScopes: AbilityScopeMap<SkillScopes> = Map<
	SkillScopes,
	AbilityScopeItem<SkillScopes>
>({
	[SkillScopes.Create]: {
		code: SkillScopes.Create,
		id: `${namespace}_2FXnHwaq5LOaxaMw2EnyHqGy9Hq`,
		order: 0,
		icon: 'circle-user',
		label: 'Skill Create',
		collection,
		repository,
	},
	[SkillScopes.Read]: {
		code: SkillScopes.Read,
		id: `${namespace}_2FXnJUIjiv4bG7solzMMYZT8ORq`,
		order: 1,
		icon: 'circle-user',
		label: 'Skill Read',
		collection,
		repository,
	},
	[SkillScopes.Update]: {
		code: SkillScopes.Update,
		id: `${namespace}_2FXnKx9YkS7Qkb3nQSNeHXXdPdL`,
		order: 2,
		icon: 'circle-user',
		label: 'Skill Update',
		collection,
		repository,
	},
	[SkillScopes.Delete]: {
		code: SkillScopes.Delete,
		id: `${namespace}_2FXnMeIIoWNP9JWGAarRnQE3UUU`,
		order: 3,
		icon: 'circle-user',
		label: 'Skill Delete',
		collection,
		repository,
	},
	[SkillScopes.Manage]: {
		code: SkillScopes.Manage,
		id: `${namespace}_2FXnGKCaCvfLcEgS8Y0gmku2RBH`,
		order: 4,
		icon: 'circle-user',
		label: 'Skill Manage',
		alias: [SkillScopes.Create, SkillScopes.Read, SkillScopes.Update, SkillScopes.Delete],
		collection,
		repository,
	},
});
