// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'forms';
const repository = 'Form';

export enum FormScopes {
	'Create' = 'forms:create',
	'Read' = 'forms:read',
	'Update' = 'forms:update',
	'Delete' = 'forms:delete',
	'Manage' = 'forms:manage',
}

const namespace = 'formsco';

export const formScopes: AbilityScopeMap<FormScopes> = Map<
	FormScopes,
	AbilityScopeItem<FormScopes>
>({
	[FormScopes.Create]: {
		code: FormScopes.Create,
		id: `${namespace}_2FXiFixAhs0LBgtY027LYlkHrcR`,
		order: 0,
		icon: 'user-gear',
		label: 'Form Create',
		collection,
		repository,
	},
	[FormScopes.Read]: {
		code: FormScopes.Read,
		id: `${namespace}_2FXiHC0GZG4UvZIgvLu6EYySQND`,
		order: 1,
		icon: 'circle-user',
		label: 'Form Read',
		collection,
		repository,
	},
	[FormScopes.Update]: {
		code: FormScopes.Update,
		id: `${namespace}_2FXiIrfOiOZ1RQ4HZiuoQyyWiGV`,
		order: 2,
		icon: 'circle-user',
		label: 'Form Update',
		collection,
		repository,
	},
	[FormScopes.Delete]: {
		code: FormScopes.Delete,
		id: `${namespace}_2FXiKla6lb4F9KGIQsRE3WOW9FW`,
		order: 3,
		icon: 'circle-user',
		label: 'Form Delete',
		collection,
		repository,
	},
	[FormScopes.Manage]: {
		code: FormScopes.Manage,
		id: `${namespace}_2FXiDxOzm86bMMnn1Ru2OhU2aHd`,
		order: 4,
		icon: 'circle-user',
		label: 'Form Manage',
		alias: [FormScopes.Create, FormScopes.Read, FormScopes.Update, FormScopes.Delete],
		collection,
		repository,
	},
});
