import { Map } from 'immutable';
import { AppModule } from '../dictionaries/modules';
import { SubjectItem } from './+types/subject-item.type';

import { accessTokenScopes } from './auth/accessTokens/accessTokens.scopes';
import { administratorScopes } from './administrators/administrators.scopes';
import { apiTokenScopes } from './apiTokens/apiTokens.scopes';
import { automationJobsScopes } from './automations/automationJobs/automationJobs.scopes';
import { automationScopes } from './automations/automations.scopes';
import { billingScopes } from './billings/billings.scopes';
import { businessRegistrationScopes } from './businessRegistrations/businessRegistrations.scopes';
import { clientScopes } from './clients/clients.scopes';
import { companyScopes } from './auth/companies/companies.scopes';
import { complianceFieldScopes } from './complianceFields/complianceFields.scopes';
import { departmentScopes } from './departments/departments.scopes';
import { emailLogScopes } from './emailLogs/emailLogs.scopes';
import { emailTemplateScopes } from './emailTemplates/emailTemplates.scopes';
import { engagementProgressLogScopes } from './engagementProgressLogs/engagementProgressLogs.scopes';
import { engagementReportScopes } from './engagementReports/engagementReports.scopes';
import { engagementScopes } from './engagements/engagements.scopes';
import { engagementTemplateScopes } from './engagementTemplates/engagementTemplates.scopes';
import { eventJobScopes } from './automations/eventJobs/eventJobs.scopes';
import { factorScopes } from './factors/factors.scopes';
import { fieldChoiceScopes } from './fieldChoices/fieldChoices.scopes';
import { fieldScopes } from './fields/fields.scopes';
import { fileScopes } from './files/files.scopes';
import { formScopes } from './forms/forms.scopes';
import { importScopes } from './imports/imports.scopes';
import { insurancePolicyScopes } from './insurancePolicies/insurancePolicies.scopes';
import { jurisdictionScopes } from './jurisdictions/jurisdictions.scopes';
import { managedServiceProviderScopes } from './managedServiceProviders/managedServiceProviders.scopes';
import { nodeJobScopes } from './automations/nodeJobs/nodeJobs.scopes';
import { partnerScopes } from './partners/partners.scopes';
import { personalAccessTokenScopes } from './personalAccessTokens/personalAccessTokens.scopes';
import { projectScopes } from './projects/projects.scopes';
import { requestDeviceScopes } from './requestDevices/requestDevices.scopes';
import { roleScopes } from './roles/roles.scopes';
import { scheduleJobScopes } from './automations/scheduleJobs/scheduleJobs.scopes';
import { securityLogScopes } from './securityLogs/securityLogs.scopes';
import { sessionScopes } from './sessions/sessions.scopes';
import { skillScopes } from './skills/skills.scopes';
import { smsTemplateScopes } from './smsTemplates/smsTemplates.scopes';
import { sourceScopes } from './sources/sources.scopes';
import { stageScopes } from './stages/stages.scopes';
import { surveyAnswerScopes } from './surveyAnswers/surveyAnswers.scopes';
import { surveyQuestionScopes } from './surveyQuestions/surveyQuestions.scopes';
import { tagScopes } from './tags/tags.scopes';
import { taxPayerStatusScopes } from './taxPayerStatuses/taxPayerStatuses.scopes';
import { taxRegistrationScopes } from './taxRegistrations/taxRegistrations.scopes';
import { teamScopes } from './teams/teams.scopes';
import { testScopes } from './tests/tests.scopes';
import { userScopes } from './auth/users/users.scopes';
import { vendorScopes } from './vendors/vendors.scopes';
import { workerScopes } from './workers/workers.scopes';

// (PascalCase) used internally to map abilities to services, repositories, & models
//	NOTE: refactored to dictionaries/modules.ts: (enum) AppModule
export type AppSubjects = AppModule;
export const AppSubjects: Record<AppModule, AppModule> = { ...AppModule };

export const appSubjects: Map<AppSubjects | `${AppSubjects}`, SubjectItem> = Map<
	AppSubjects | `${AppSubjects}`,
	SubjectItem
>({
	[AppSubjects.AccessToken]: {
		code: AppSubjects.AccessToken,
		id: 'apsub_2hZz8eOIPCYoqhAopceEG6bh5XV',
		order: 0,
		icon: 'circle-user',
		label: AppSubjects.AccessToken,
		scopes: accessTokenScopes,
	},
	[AppSubjects.Administrator]: {
		code: AppSubjects.Administrator,
		id: 'apsub_2Gt4ylxWYHYlgivGYRmDAwsnlHX',
		order: 1,
		icon: 'circle-user',
		label: AppSubjects.Administrator,
		scopes: administratorScopes,
	},
	[AppSubjects.ApiToken]: {
		code: AppSubjects.ApiToken,
		id: 'apsub_63480c6c74ff77b0cf913636',
		order: 2,
		icon: 'fa-user-gear',
		label: AppSubjects.ApiToken,
		scopes: apiTokenScopes,
	},
	[AppSubjects.Automation]: {
		code: AppSubjects.Automation,
		id: 'apsub_2Idekf7UbcXWNQHt8WFF3P1NKrh',
		order: 3,
		icon: '',
		label: AppSubjects.Automation,
		scopes: automationScopes,
	},
	[AppSubjects.AutomationJob]: {
		code: AppSubjects.AutomationJob,
		id: 'apsub_2PDGRyXW8HYtvdW7UNfwKryHVUL',
		order: 4,
		icon: '',
		label: AppSubjects.AutomationJob,
		scopes: automationJobsScopes,
	},
	[AppSubjects.Billing]: {
		code: AppSubjects.Billing,
		id: 'apsub_2iQxHrs3T2vBhg6VeR9AJKlHWFV',
		order: 5,
		icon: '',
		label: AppSubjects.Billing,
		scopes: billingScopes,
	},
	[AppSubjects.BusinessRegistration]: {
		code: AppSubjects.BusinessRegistration,
		id: 'apsub_2Gt4yjcPfnyfcANuWlSy3XcquV4',
		order: 6,
		icon: '',
		label: AppSubjects.BusinessRegistration,
		scopes: businessRegistrationScopes,
	},
	[AppSubjects.Client]: {
		code: AppSubjects.Client,
		id: 'apsub_2Gt4ynrZ64HY9ffMGdMQNNV82od',
		order: 7,
		icon: '',
		label: AppSubjects.Client,
		scopes: clientScopes,
	},
	[AppSubjects.Company]: {
		code: AppSubjects.Company,
		id: 'apsub_2Gt4yltjBKfEFcpQjl9bRH0FQ0f',
		order: 8,
		icon: '',
		label: AppSubjects.Company,
		scopes: companyScopes,
	},
	[AppSubjects.ComplianceField]: {
		code: AppSubjects.ComplianceField,
		id: 'apsub_2Gt4yoiYMu5IiAjzIZe3rDXi1d5',
		order: 9,
		icon: '',
		label: AppSubjects.ComplianceField,
		scopes: complianceFieldScopes,
	},
	[AppSubjects.Department]: {
		code: AppSubjects.Department,
		id: 'apsub_2Gt4yiJPK63EvBXxxHjpDV9dmoZ',
		order: 10,
		icon: '',
		label: AppSubjects.Department,
		scopes: departmentScopes,
	},
	[AppSubjects.EmailLog]: {
		code: AppSubjects.EmailLog,
		id: 'apsub_2R134kANNwPieDXNyWbUZXdh71J',
		order: 11,
		icon: '',
		label: AppSubjects.EmailLog,
		scopes: emailLogScopes,
	},
	[AppSubjects.EmailTemplate]: {
		code: AppSubjects.EmailTemplate,
		id: 'apsub_2Gt4yhwyzlgAa53qzvUwUlW6QbL',
		order: 12,
		icon: '',
		label: AppSubjects.EmailTemplate,
		scopes: emailTemplateScopes,
	},
	[AppSubjects.Engagement]: {
		code: AppSubjects.Engagement,
		id: 'apsub_2Gt4yjdIF2O1O6Jeo3viL3t0JLV',
		order: 13,
		icon: '',
		label: AppSubjects.Engagement,
		scopes: engagementScopes,
	},
	[AppSubjects.EngagementProgressLog]: {
		code: AppSubjects.EngagementProgressLog,
		id: 'apsub_2QUahrXNH0j4TN7HMd4XUZRMwQO',
		order: 14,
		icon: '',
		label: AppSubjects.EngagementProgressLog,
		scopes: engagementProgressLogScopes,
	},
	[AppSubjects.EngagementReport]: {
		code: AppSubjects.EngagementReport,
		id: 'apsub_2RvYyNcyXbhZI7cepAcfkSqfLbO',
		order: 15,
		icon: '',
		label: AppSubjects.EngagementReport,
		scopes: engagementReportScopes,
	},
	[AppSubjects.EngagementTemplate]: {
		code: AppSubjects.EngagementTemplate,
		id: 'apsub_2Gt4ylOx4NGpJqMUoxu3uRgTGJh',
		order: 16,
		icon: '',
		label: AppSubjects.EngagementTemplate,
		scopes: engagementTemplateScopes,
	},
	[AppSubjects.EventJob]: {
		code: AppSubjects.EventJob,
		id: 'apsub_2PDGTH9D0DrOBXyGrbAHycl0hU3',
		order: 17,
		icon: '',
		label: AppSubjects.EventJob,
		scopes: eventJobScopes,
	},
	[AppSubjects.Factor]: {
		code: AppSubjects.Factor,
		id: 'apsub_2Gt4ynFezpxiez4XIS6xZYXl9OF',
		order: 18,
		icon: '',
		label: AppSubjects.Factor,
		scopes: factorScopes,
	},
	[AppSubjects.Field]: {
		code: AppSubjects.Field,
		id: 'apsub_2Gt4yhyuC6KCOgFWfFkB9HcdR5z',
		order: 19,
		icon: '',
		label: AppSubjects.Field,
		scopes: fieldScopes,
	},
	[AppSubjects.FieldChoice]: {
		code: AppSubjects.FieldChoice,
		id: 'apsub_2Gt4ymXB7myx3PpnlCwRBjnKGJ6',
		order: 20,
		icon: '',
		label: AppSubjects.FieldChoice,
		scopes: fieldChoiceScopes,
	},
	[AppSubjects.File]: {
		code: AppSubjects.File,
		id: 'apsub_2Gt4yibYuhOAWSOvmNyfyrqhpui',
		order: 21,
		icon: '',
		label: AppSubjects.File,
		scopes: fileScopes,
	},
	[AppSubjects.Form]: {
		code: AppSubjects.Form,
		id: 'apsub_2Gt4ymAkZe2LlwrsIShmlSxWfuq',
		order: 22,
		icon: '',
		label: AppSubjects.Form,
		scopes: formScopes,
	},
	[AppSubjects.Import]: {
		code: AppSubjects.Import,
		id: 'apsub_2cqPy4c9eDJPhfRVc2qte1MaEXc',
		order: 23,
		icon: '',
		label: AppSubjects.Import,
		scopes: importScopes,
	},
	[AppSubjects.InsurancePolicy]: {
		code: AppSubjects.InsurancePolicy,
		id: 'apsub_2Gt4ykEdSWGTJEcpsNOmRIWT3UV',
		order: 24,
		icon: '',
		label: AppSubjects.InsurancePolicy,
		scopes: insurancePolicyScopes,
	},
	[AppSubjects.Jurisdiction]: {
		code: AppSubjects.Jurisdiction,
		id: 'apsub_2VjsQGHWIwApAaoTF3x1oSSWC5j',
		order: 25,
		icon: '',
		label: AppSubjects.Jurisdiction,
		scopes: jurisdictionScopes,
	},
	[AppSubjects.ManagedServiceProvider]: {
		code: AppSubjects.ManagedServiceProvider,
		id: 'apsub_2Gt4yiw6YbaHzMn2VGeRqis1Bdt',
		order: 26,
		icon: '',
		label: AppSubjects.ManagedServiceProvider,
		scopes: managedServiceProviderScopes,
	},
	[AppSubjects.NodeJob]: {
		code: AppSubjects.NodeJob,
		id: 'apsub_2PDGUVuZskpW7hdnHjFwOmwWsdi',
		order: 27,
		icon: '',
		label: AppSubjects.NodeJob,
		scopes: nodeJobScopes,
	},
	[AppSubjects.Partner]: {
		code: AppSubjects.Partner,
		id: 'apsub_2gsiCPNyWG86EHf8zTqnsiXsc5p',
		order: 28,
		icon: '',
		label: AppSubjects.Partner,
		scopes: partnerScopes,
	},
	[AppSubjects.PersonalAccessToken]: {
		code: AppSubjects.PersonalAccessToken,
		id: 'apsub_63481b87254166448d3313f4',
		order: 29,
		icon: 'fa-user-gear',
		label: AppSubjects.PersonalAccessToken,
		scopes: personalAccessTokenScopes,
	},
	[AppSubjects.Project]: {
		code: AppSubjects.Project,
		id: 'apsub_2KZO3JEc3GEVHZwu2xnFUrOfdfq',
		order: 30,
		icon: '',
		label: AppSubjects.Project,
		scopes: projectScopes,
	},
	[AppSubjects.RequestDevice]: {
		code: AppSubjects.RequestDevice,
		id: 'apsub_2NGCZGvRk3dM805gK4jZKzwY088',
		order: 31,
		icon: '',
		label: AppSubjects.RequestDevice,
		scopes: requestDeviceScopes,
	},
	[AppSubjects.Role]: {
		code: AppSubjects.Role,
		id: 'apsub_2Gt4ymzGTaiONwQ0mdZUgSuhWIv',
		order: 32,
		icon: '',
		label: AppSubjects.Role,
		scopes: roleScopes,
	},
	[AppSubjects.ScheduleJob]: {
		code: AppSubjects.ScheduleJob,
		id: 'apsub_2PDGW82rAAIfnPkOk8wy7JrQCkg',
		order: 33,
		icon: '',
		label: AppSubjects.ScheduleJob,
		scopes: scheduleJobScopes,
	},
	[AppSubjects.SecurityLog]: {
		code: AppSubjects.SecurityLog,
		id: 'apsub_2Gt4ymLBu7YF0XqMTr9SmBk5XlX',
		order: 34,
		icon: '',
		label: AppSubjects.SecurityLog,
		scopes: securityLogScopes,
	},
	[AppSubjects.Session]: {
		code: AppSubjects.Session,
		id: 'apsub_2NIYoqzOcRuHNHPqr9CJ9Ynl255',
		order: 35,
		icon: '',
		label: AppSubjects.Session,
		scopes: sessionScopes,
	},
	[AppSubjects.Skill]: {
		code: AppSubjects.Skill,
		id: 'apsub_2Gt4ynp96zqiQ35PfLRZvSj8kjI',
		order: 36,
		icon: '',
		label: AppSubjects.Skill,
		scopes: skillScopes,
	},
	[AppSubjects.SmsTemplate]: {
		code: AppSubjects.SmsTemplate,
		id: 'apsub_2Gt4yiMncOfhMuIQMaljcAVMeNG',
		order: 37,
		icon: '',
		label: AppSubjects.SmsTemplate,
		scopes: smsTemplateScopes,
	},
	[AppSubjects.Source]: {
		code: AppSubjects.Source,
		id: 'apsub_2Gt4ylLoMeLncZFloPwN8mBzttj',
		order: 38,
		icon: '',
		label: AppSubjects.Source,
		scopes: sourceScopes,
	},
	[AppSubjects.Stage]: {
		code: AppSubjects.Stage,
		id: 'apsub_2Gt4yjUAqDOpXoxJm1fTwGYfXQ1',
		order: 39,
		icon: '',
		label: AppSubjects.Stage,
		scopes: stageScopes,
	},
	[AppSubjects.SurveyAnswer]: {
		code: AppSubjects.SurveyAnswer,
		id: 'apsub_2MOeqQ28hvIqkxWQt7RjHJvCyvM',
		order: 40,
		icon: '',
		label: AppSubjects.SurveyAnswer,
		scopes: surveyAnswerScopes,
	},
	[AppSubjects.SurveyQuestion]: {
		code: AppSubjects.SurveyQuestion,
		id: 'apsub_2MOer4rdmr2YC5tjtJGePwHyspD',
		order: 41,
		icon: '',
		label: AppSubjects.SurveyQuestion,
		scopes: surveyQuestionScopes,
	},
	[AppSubjects.Tag]: {
		code: AppSubjects.Tag,
		id: 'apsub_2Gt4yoWH4gmc1YMz7mJqvgZVhAQ',
		order: 42,
		icon: '',
		label: AppSubjects.Tag,
		scopes: tagScopes,
	},
	[AppSubjects.TaxPayerStatus]: {
		code: AppSubjects.TaxPayerStatus,
		id: 'apsub_2Gt4yjYp648ctxC4V8J6yjr2l4D',
		order: 43,
		icon: '',
		label: AppSubjects.TaxPayerStatus,
		scopes: taxPayerStatusScopes,
	},
	[AppSubjects.TaxRegistration]: {
		code: AppSubjects.TaxRegistration,
		id: 'apsub_2Gt4yjyC8ZKjukuwGHgmV0BEaBJ',
		order: 44,
		icon: '',
		label: AppSubjects.TaxRegistration,
		scopes: taxRegistrationScopes,
	},
	[AppSubjects.Team]: {
		code: AppSubjects.Team,
		id: 'apsub_2Gt4yiWXi0xnUI51wxjynG7NS0h',
		order: 45,
		icon: '',
		label: AppSubjects.Team,
		scopes: teamScopes,
	},
	[AppSubjects.Test]: {
		code: AppSubjects.Test,
		id: 'apsub_2Gt4ymzrUgCa6BvVPExkw2bovZx',
		order: 46,
		icon: '',
		label: AppSubjects.Test,
		scopes: testScopes,
	},
	[AppSubjects.User]: {
		code: AppSubjects.User,
		id: 'apsub_2Gt4ypM8MdLnJKkE8fWwnaCH3PG',
		order: 47,
		icon: '',
		label: AppSubjects.User,
		scopes: userScopes,
	},
	[AppSubjects.Vendor]: {
		code: AppSubjects.Vendor,
		id: 'apsub_2Gt4yiAc5IPmKpAM3RpFzoKJ7R6',
		order: 48,
		icon: '',
		label: AppSubjects.Vendor,
		scopes: vendorScopes,
	},
	[AppSubjects.Worker]: {
		code: AppSubjects.Worker,
		id: 'apsub_2Gt4yofvvf1Csqs3C0gDcennito',
		order: 49,
		icon: '',
		label: AppSubjects.Worker,
		scopes: workerScopes,
	},
});
