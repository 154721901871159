// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum JurisdictionCosts {
	ONE_HUNDRED = 'one-hundred',
	ONE_HUNDRED_FIFTY = 'one-hundred-fifty',
	TWO_HUNDRED = 'two-hundred',
	TWO_HUNDRED_FIFTY = 'two-hundred-fifty',
	THREE_HUNDRED = 'three-hundred',
	THREE_HUNDRED_FIFTY = 'three-hundred-fifty',
	FOUR_HUNDRED = 'four-hundred',
	FOUR_HUNDRED_FIFTY = 'four-hundred-fifty',
	FIVE_HUNDRED = 'five-hundred',
	SEVEN_HUNDRED_FIFTY = 'seven-hundred-fifty',
	ONE_THOUSAND = 'one-thousand',
	ONE_THOUSAND_TWO_HUNDRED_FIFTY = 'one-thousand-two-hundred-fifty',
	ONE_THOUSAND_FIVE_HUNDRED = 'one-thousand-five-hundred',
}

export interface JurisdictionCostsItem extends BaseDictionary {
	code: JurisdictionCosts;
	id: `${IdNamespace.JurisdictionCosts}_${string}`;
	order: number;
	label: string;
	amount: number;
}

export const jurisdictionCosts = Map<
	JurisdictionCosts | `${JurisdictionCosts}`,
	JurisdictionCostsItem
>({
	[JurisdictionCosts.ONE_HUNDRED]: {
		code: JurisdictionCosts.ONE_HUNDRED,
		id: 'jurcos_2ipIOBKEcDQ8bMb6D1TpLw0XpHZ',
		order: 0,
		// label: i18n.t('jurisdictionCosts.oneHundred', { ns: 'dictionaries' }),
		label: '100 dollars',
		amount: 100.0,
	},
	[JurisdictionCosts.ONE_HUNDRED_FIFTY]: {
		code: JurisdictionCosts.ONE_HUNDRED_FIFTY,
		id: 'jurcos_2ipIO7zy2yQpp0xvnHAAnt5glh2',
		order: 1,
		// label: i18n.t('jurisdictionCosts.oneHundredFifty', { ns: 'dictionaries' }),
		label: '150 dollars',
		amount: 150.0,
	},
	[JurisdictionCosts.TWO_HUNDRED]: {
		code: JurisdictionCosts.TWO_HUNDRED,
		id: 'jurcos_2ipIO4zbvfiIqZoSGZw5maN538c',
		order: 2,
		// label: i18n.t('jurisdictionCosts.twoHundred', { ns: 'dictionaries' }),
		label: '200 dollars',
		amount: 200.0,
	},
	[JurisdictionCosts.TWO_HUNDRED_FIFTY]: {
		code: JurisdictionCosts.TWO_HUNDRED_FIFTY,
		id: 'jurcos_2ipIO9Zzb1czuRr2g6unUmVyFJT',
		order: 3,
		// label: i18n.t('jurisdictionCosts.twoHundredFifty', { ns: 'dictionaries' }),
		label: '250 dollars',
		amount: 250.0,
	},
	[JurisdictionCosts.THREE_HUNDRED]: {
		code: JurisdictionCosts.THREE_HUNDRED,
		id: 'jurcos_2ipIO8I2Ai0BXcqxi0uyUNC5Dh3',
		order: 4,
		// label: i18n.t('jurisdictionCosts.threeHundred', { ns: 'dictionaries' }),
		label: '300 dollars',
		amount: 300.0,
	},
	[JurisdictionCosts.THREE_HUNDRED_FIFTY]: {
		code: JurisdictionCosts.THREE_HUNDRED_FIFTY,
		id: 'jurcos_2ipIOBNFNHvyzmxJ7e07n6YFTNF',
		order: 5,
		// label: i18n.t('jurisdictionCosts.threeHundredFifty', { ns: 'dictionaries' }),
		label: '350 dollars',
		amount: 350.0,
	},
	[JurisdictionCosts.FOUR_HUNDRED]: {
		code: JurisdictionCosts.FOUR_HUNDRED,
		id: 'jurcos_2ipIO9WCfpfysbCQqCwNZrsRXfx',
		order: 6,
		// label: i18n.t('jurisdictionCosts.fourHundred', { ns: 'dictionaries' }),
		label: '400 dollars',
		amount: 400.0,
	},
	[JurisdictionCosts.FOUR_HUNDRED_FIFTY]: {
		code: JurisdictionCosts.FOUR_HUNDRED_FIFTY,
		id: 'jurcos_2ipIO6u4Lbvo2ilKVMZTGCBRiWW',
		order: 7,
		// label: i18n.t('jurisdictionCosts.fourHundredFifty', { ns: 'dictionaries' }),
		label: '450 dollars',
		amount: 450.0,
	},
	[JurisdictionCosts.FIVE_HUNDRED]: {
		code: JurisdictionCosts.FIVE_HUNDRED,
		id: 'jurcos_2b1452MdH3pKTXmnI3J1jmmEG83',
		order: 8,
		// label: i18n.t('jurisdictionCosts.fiveHundred', { ns: 'dictionaries' }),
		label: '500 dollars',
		amount: 500.0,
	},
	[JurisdictionCosts.SEVEN_HUNDRED_FIFTY]: {
		code: JurisdictionCosts.SEVEN_HUNDRED_FIFTY,
		id: 'jurcos_2ipIW5dHdAkkJ8OxE1fcXUZ7qth',
		order: 9,
		// label: i18n.t('jurisdictionCosts.sevenHundredFifty', { ns: 'dictionaries' }),
		label: '750 dollars',
		amount: 750.0,
	},
	[JurisdictionCosts.ONE_THOUSAND]: {
		code: JurisdictionCosts.ONE_THOUSAND,
		id: 'jurcos_2b1451FKm5YCpH1lzu0C4JSHJH4',
		order: 10,
		// label: i18n.t('jurisdictionCosts.oneThousand', { ns: 'dictionaries' }),
		label: '1000 dollars',
		amount: 1_000.0,
	},
	[JurisdictionCosts.ONE_THOUSAND_TWO_HUNDRED_FIFTY]: {
		code: JurisdictionCosts.ONE_THOUSAND_TWO_HUNDRED_FIFTY,
		id: 'jurcos_2ipIW2QyzQ0aLYeFDBttrlO90dF',
		order: 11,
		// label: i18n.t('jurisdictionCosts.oneThousandTwoHundredFifty', { ns: 'dictionaries' }),
		label: '1,250 dollars',
		amount: 1_250.0,
	},
	[JurisdictionCosts.ONE_THOUSAND_FIVE_HUNDRED]: {
		code: JurisdictionCosts.ONE_THOUSAND_FIVE_HUNDRED,
		id: 'jurcos_2b8zw3ju2kOLPxynsMKLtiP45oQ',
		order: 12,
		// label: i18n.t('jurisdictionCosts.oneThousandFiveHundred', { ns: 'dictionaries' }),
		label: '1,500 dollars',
		amount: 1_500.0,
	},
});
