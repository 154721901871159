// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'surveyQuestions';
const repository = 'SurveyQuestion';

export enum SurveyQuestionScopes {
	'Create' = 'surveyQuestions:create',
	'Read' = 'surveyQuestions:read',
	'Update' = 'surveyQuestions:update',
	'Delete' = 'surveyQuestions:delete',
	'Manage' = 'surveyQuestions:manage',
}

const NS: string = IdNamespace.SurveyQuestionScope;

export const surveyQuestionScopes: AbilityScopeMap<SurveyQuestionScopes> = Map<
	SurveyQuestionScopes,
	AbilityScopeItem<SurveyQuestionScopes>
>({
	[SurveyQuestionScopes.Create]: {
		code: SurveyQuestionScopes.Create,
		id: `${NS}_2MOeHyRo4cVb3JO88oOafhnXaXX`,
		order: 0,
		icon: 'circle-user',
		label: 'SurveyQuestion Create',
		collection,
		repository,
	},
	[SurveyQuestionScopes.Read]: {
		code: SurveyQuestionScopes.Read,
		id: `${NS}_2MOeIzJ0LG5rVF8JHxKe6c9eUMX`,
		order: 1,
		icon: 'circle-user',
		label: 'SurveyQuestion Read',
		collection,
		repository,
	},
	[SurveyQuestionScopes.Update]: {
		code: SurveyQuestionScopes.Update,
		id: `${NS}_2MOeJwyaRNzdL0TkKMnRe35UrUh`,
		order: 2,
		icon: 'circle-user',
		label: 'SurveyQuestion Update',
		collection,
		repository,
	},
	[SurveyQuestionScopes.Delete]: {
		code: SurveyQuestionScopes.Delete,
		id: `${NS}_2MOeKYf7cBetMKmbVluUWNSXiFD`,
		order: 3,
		icon: 'circle-user',
		label: 'SurveyQuestion Delete',
		collection,
		repository,
	},
	[SurveyQuestionScopes.Manage]: {
		code: SurveyQuestionScopes.Manage,
		id: `${NS}_2MOeHKS7PHubUdgJRtvY9gxv5m5`,
		order: 4,
		icon: 'circle-user',
		label: 'SurveyQuestion Manage',
		alias: [
			SurveyQuestionScopes.Create,
			SurveyQuestionScopes.Read,
			SurveyQuestionScopes.Update,
			SurveyQuestionScopes.Delete,
		],
		collection,
		repository,
	},
});
