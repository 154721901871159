// https://yeun.github.io/open-color

export enum ActiveColor {
	DRAFT = '#fcc419', // YELLOW 5
	LIVE = '#51cf66', // GREEN 5
}

export enum StatusColor {
	CRITICAL = '#ff6b6b', // RED 5
	HIGH = '#ff922b', // ORANGE 5
	MEDIUM = '#fcc419', // YELLOW 5
	LOW = '#f06595', // PINK 5
}

export enum ProgressColor {
	INCOMPLETE = '#adb5bd', // GRAY 5
	MISSING = '#fcc419', // YELLOW 5
	IN_PROGRESS = '#22b8cf', // CYAN 5
	PENDING = '#5c7cfa', // INDIGO 5
	IN_REVIEW = '#845ef7', // VIOLET 5
	SNOOZED = '#cc5de8', // GRAPE 5
	FAIL = '#ff6b6b', // RED 5
	PASS = '#51cf66', // GREEN 5
	CANCELLED = '#20c997', // TEAL 5
	EXPIRED = '#ff922b', // ORANGE 5
}

export enum ProgressIcon {
	INCOMPLETE = 'square-question',
	MISSING = 'square-question',
	IN_PROGRESS = 'pen-field',
	PENDING = 'pen-field',
	IN_REVIEW = 'file-magnifying-glass',
	SNOOZED = 'alarm-snooze',
	FAIL = 'file-xmark',
	PASS = 'file-check',
	CANCELLED = 'ban',
	EXPIRED = 'hourglass-end',
}
