// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum PhoneType {
	HOME = 'home',
	WORK = 'work',
	CELL = 'cell',
	OFFICE = 'office',
	TOLL_FREE = 'toll-free',
	OTHER = 'other',
}

export interface PhoneTypeItem extends BaseDictionary {
	code: PhoneType;
	id: `${IdNamespace.PhoneType}_${string}`;
	order: number;
	label: string;
	icon: string;
}

export const phoneTypes = Map<PhoneType | `${PhoneType}`, PhoneTypeItem>({
	[PhoneType.HOME]: {
		code: PhoneType.HOME,
		id: 'photy_2FeOxwSvN6UfX7svECUaVhrTn1I',
		order: 0,
		// label: i18n.t('phone_types.label.home', { ns: 'dictionaries' }),
		label: 'Home',
		icon: 'phone',
	},
	[PhoneType.WORK]: {
		code: PhoneType.WORK,
		id: 'photy_2FeOxqUBCByMXSamcabpqfwpAGW',
		order: 1,
		// label: i18n.t('phone_types.label.work', { ns: 'dictionaries' }),
		label: 'Work',
		icon: 'phone',
	},
	[PhoneType.CELL]: {
		code: PhoneType.CELL,
		id: 'photy_2FeOxx8oXI4rXyK46zKI1BIyCjk',
		order: 2,
		// label: i18n.t('phone_types.label.cell', { ns: 'dictionaries' }),
		label: 'Cell',
		icon: 'phone',
	},
	[PhoneType.OFFICE]: {
		code: PhoneType.OFFICE,
		id: 'photy_2FeOxwurzq9pKRqfmMj1qtFOOyX',
		order: 3,
		// label: i18n.t('phone_types.label.office', { ns: 'dictionaries' }),
		label: 'Office',
		icon: 'phone',
	},
	[PhoneType.TOLL_FREE]: {
		code: PhoneType.TOLL_FREE,
		id: 'photy_2FeOxvO7uIblNtTZ9C65w7mwUGA',
		order: 4,
		// label: i18n.t('phone_types.label.toll_free', { ns: 'dictionaries' }),
		label: 'Toll-Free',
		icon: 'phone',
	},
	[PhoneType.OTHER]: {
		code: PhoneType.OTHER,
		id: 'photy_2FeOxpywZcDR2OUW3eukJtzmadb',
		order: 5,
		// label: i18n.t('phone_types.label.other', { ns: 'dictionaries' }),
		label: 'Other',
		icon: 'phone',
	},
});
