import { merge } from 'immutable';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe/assert';
import type { AppModule, ModuleKeys } from '../dictionaries/modules';
import type { AbilityScopeEnum, AbilityScopeMap } from './+types/scope-item.type';
import type { AppSubjects } from './app-subjects.dictionary';

import { AdministratorScopes, administratorScopes } from './administrators/administrators.scopes';
import { ApiTokenScopes, apiTokenScopes } from './apiTokens/apiTokens.scopes';
import { AccessTokenScopes, accessTokenScopes } from './auth/accessTokens/accessTokens.scopes';
import { CompanyScopes, companyScopes } from './auth/companies/companies.scopes';
import { UserScopes, userScopes } from './auth/users/users.scopes';
import {
	AutomationJobScopes,
	automationJobsScopes,
} from './automations/automationJobs/automationJobs.scopes';
import { AutomationScopes, automationScopes } from './automations/automations.scopes';
import { EventJobScopes, eventJobScopes } from './automations/eventJobs/eventJobs.scopes';
import { NodeJobScopes, nodeJobScopes } from './automations/nodeJobs/nodeJobs.scopes';
import {
	ScheduleJobScopes,
	scheduleJobScopes,
} from './automations/scheduleJobs/scheduleJobs.scopes';
import { BillingScopes, billingScopes } from './billings/billings.scopes';
import {
	BusinessRegistrationScopes,
	businessRegistrationScopes,
} from './businessRegistrations/businessRegistrations.scopes';
import { ClientScopes, clientScopes } from './clients/clients.scopes';
import {
	ComplianceFieldScopes,
	complianceFieldScopes,
} from './complianceFields/complianceFields.scopes';
import { DepartmentScopes, departmentScopes } from './departments/departments.scopes';
import { EmailLogScopes, emailLogScopes } from './emailLogs/emailLogs.scopes';
import { EmailTemplateScopes, emailTemplateScopes } from './emailTemplates/emailTemplates.scopes';
import {
	EngagementProgressLogScopes,
	engagementProgressLogScopes,
} from './engagementProgressLogs/engagementProgressLogs.scopes';
import {
	EngagementReportScopes,
	engagementReportScopes,
} from './engagementReports/engagementReports.scopes';
import {
	EngagementTemplateScopes,
	engagementTemplateScopes,
} from './engagementTemplates/engagementTemplates.scopes';
import { EngagementScopes, engagementScopes } from './engagements/engagements.scopes';
import { FactorScopes, factorScopes } from './factors/factors.scopes';
import { FieldChoiceScopes, fieldChoiceScopes } from './fieldChoices/fieldChoices.scopes';
import { FieldScopes, fieldScopes } from './fields/fields.scopes';
import { FileScopes, fileScopes } from './files/files.scopes';
import { FormScopes, formScopes } from './forms/forms.scopes';
import { ImportScopes, importScopes } from './imports/imports.scopes';
import {
	InsurancePolicyScopes,
	insurancePolicyScopes,
} from './insurancePolicies/insurancePolicies.scopes';
import { JurisdictionScopes, jurisdictionScopes } from './jurisdictions/jurisdictions.scopes';
import {
	ManagedServiceProviderScopes,
	managedServiceProviderScopes,
} from './managedServiceProviders/managedServiceProviders.scopes';
import { PartnerScopes, partnerScopes } from './partners/partners.scopes';
import {
	PersonalAccessTokenScopes,
	personalAccessTokenScopes,
} from './personalAccessTokens/personalAccessTokens.scopes';
import { ProjectScopes, projectScopes } from './projects/projects.scopes';
import { RequestDeviceScopes, requestDeviceScopes } from './requestDevices/requestDevices.scopes';
import { RoleScopes, roleScopes } from './roles/roles.scopes';
import { SecurityLogScopes, securityLogScopes } from './securityLogs/securityLogs.scopes';
import { SessionScopes, sessionScopes } from './sessions/sessions.scopes';
import { SkillScopes, skillScopes } from './skills/skills.scopes';
import { SmsTemplateScopes, smsTemplateScopes } from './smsTemplates/smsTemplates.scopes';
import { SourceScopes, sourceScopes } from './sources/sources.scopes';
import { StageScopes, stageScopes } from './stages/stages.scopes';
import { SurveyAnswerScopes, surveyAnswerScopes } from './surveyAnswers/surveyAnswers.scopes';
import {
	SurveyQuestionScopes,
	surveyQuestionScopes,
} from './surveyQuestions/surveyQuestions.scopes';
import { TagScopes, tagScopes } from './tags/tags.scopes';
import {
	TaxPayerStatusScopes,
	taxPayerStatusScopes,
} from './taxPayerStatuses/taxPayerStatuses.scopes';
import {
	TaxRegistrationScopes,
	taxRegistrationScopes,
} from './taxRegistrations/taxRegistrations.scopes';
import { TeamScopes, teamScopes } from './teams/teams.scopes';
import { TestScopes, testScopes } from './tests/tests.scopes';
import { VendorScopes, vendorScopes } from './vendors/vendors.scopes';
import { WorkerScopes, workerScopes } from './workers/workers.scopes';

export type AbilityScopesEnumMap =
	| typeof AccessTokenScopes
	| typeof AdministratorScopes
	| typeof ApiTokenScopes
	| typeof AutomationScopes
	| typeof AutomationJobScopes
	| typeof BillingScopes
	| typeof BusinessRegistrationScopes
	| typeof ClientScopes
	| typeof CompanyScopes
	| typeof ComplianceFieldScopes
	| typeof DepartmentScopes
	| typeof EmailLogScopes
	| typeof EmailTemplateScopes
	| typeof EngagementScopes
	| typeof EngagementProgressLogScopes
	| typeof EngagementReportScopes
	| typeof EngagementTemplateScopes
	| typeof EventJobScopes
	| typeof FactorScopes
	| typeof FieldScopes
	| typeof FieldChoiceScopes
	| typeof FileScopes
	| typeof FormScopes
	| typeof ImportScopes
	| typeof InsurancePolicyScopes
	| typeof JurisdictionScopes
	| typeof ManagedServiceProviderScopes
	| typeof NodeJobScopes
	| typeof PartnerScopes
	| typeof PersonalAccessTokenScopes
	| typeof ProjectScopes
	| typeof RequestDeviceScopes
	| typeof RoleScopes
	| typeof ScheduleJobScopes
	| typeof SecurityLogScopes
	| typeof SessionScopes
	| typeof SkillScopes
	| typeof SmsTemplateScopes
	| typeof SourceScopes
	| typeof StageScopes
	| typeof SurveyAnswerScopes
	| typeof SurveyQuestionScopes
	| typeof TagScopes
	| typeof TaxPayerStatusScopes
	| typeof TaxRegistrationScopes
	| typeof TeamScopes
	| typeof TestScopes
	| typeof UserScopes
	| typeof VendorScopes
	| typeof WorkerScopes;

export type AppAbilityItem = [AppSubjects, AbilityScopesEnumMap];

/**
 * Object of all AppModule scope enums
 *
 * @return {
 * 	AccessToken: enum,
 * 	Administrator: enum,
 * 	...
 * }
 */
export const abilityScopesEnumMap = {
	AccessToken: AccessTokenScopes,
	Administrator: AdministratorScopes,
	ApiToken: ApiTokenScopes,
	Automation: AutomationScopes,
	AutomationJob: AutomationJobScopes,
	Billing: BillingScopes,
	BusinessRegistration: BusinessRegistrationScopes,
	Client: ClientScopes,
	Company: CompanyScopes,
	ComplianceField: ComplianceFieldScopes,
	Department: DepartmentScopes,
	EmailLog: EmailLogScopes,
	EmailTemplate: EmailTemplateScopes,
	Engagement: EngagementScopes,
	EngagementProgressLog: EngagementProgressLogScopes,
	EngagementReport: EngagementReportScopes,
	EngagementTemplate: EngagementTemplateScopes,
	EventJob: EventJobScopes,
	Factor: FactorScopes,
	Field: FieldScopes,
	FieldChoice: FieldChoiceScopes,
	File: FileScopes,
	Form: FormScopes,
	Import: ImportScopes,
	InsurancePolicy: InsurancePolicyScopes,
	Jurisdiction: JurisdictionScopes,
	ManagedServiceProvider: ManagedServiceProviderScopes,
	NodeJob: NodeJobScopes,
	Partner: PartnerScopes,
	PersonalAccessToken: PersonalAccessTokenScopes,
	Project: ProjectScopes,
	RequestDevice: RequestDeviceScopes,
	Role: RoleScopes,
	ScheduleJob: ScheduleJobScopes,
	SecurityLog: SecurityLogScopes,
	Session: SessionScopes,
	Skill: SkillScopes,
	SmsTemplate: SmsTemplateScopes,
	Source: SourceScopes,
	Stage: StageScopes,
	SurveyAnswer: SurveyAnswerScopes,
	SurveyQuestion: SurveyQuestionScopes,
	Tag: TagScopes,
	TaxPayerStatus: TaxPayerStatusScopes,
	TaxRegistration: TaxRegistrationScopes,
	Team: TeamScopes,
	Test: TestScopes,
	User: UserScopes,
	Vendor: VendorScopes,
	Worker: WorkerScopes,
};
// console.debug('[DEBUG AbilityScopes] AbilityScopesEnumMap(object):', { count: Object.keys(AbilityScopesEnumMap).length }, AbilityScopesEnumMap);

export type AbilityScopesEnumMapsKeys = keyof typeof abilityScopesEnumMap;

// compile error if the enum of `Module` does not match enum `AbilityScopesEnumMapsKeys`
assert<Equals<ModuleKeys, AbilityScopesEnumMapsKeys>>();

/**
 * Object of all AppModule scope Immutable Map's
 *
 * @return {
 * 	AccessToken: Map<string, AbilityScopeItem>,
 * 	Administrator: Map<string, AbilityScopeItem>,
 * 	...
 * }
 */
export const abilityScopes: Record<AppModule, AbilityScopeMap> = {
	AccessToken: accessTokenScopes,
	Administrator: administratorScopes,
	ApiToken: apiTokenScopes,
	Automation: automationScopes,
	AutomationJob: automationJobsScopes,
	Billing: billingScopes,
	BusinessRegistration: businessRegistrationScopes,
	Client: clientScopes,
	Company: companyScopes,
	ComplianceField: complianceFieldScopes,
	Department: departmentScopes,
	EmailLog: emailLogScopes,
	EmailTemplate: emailTemplateScopes,
	Engagement: engagementScopes,
	EngagementProgressLog: engagementProgressLogScopes,
	EngagementReport: engagementReportScopes,
	EngagementTemplate: engagementTemplateScopes,
	EventJob: eventJobScopes,
	Factor: factorScopes,
	Field: fieldScopes,
	FieldChoice: fieldChoiceScopes,
	File: fileScopes,
	Form: formScopes,
	Import: importScopes,
	InsurancePolicy: insurancePolicyScopes,
	Jurisdiction: jurisdictionScopes,
	ManagedServiceProvider: managedServiceProviderScopes,
	NodeJob: nodeJobScopes,
	Partner: partnerScopes,
	PersonalAccessToken: personalAccessTokenScopes,
	Project: projectScopes,
	RequestDevice: requestDeviceScopes,
	Role: roleScopes,
	ScheduleJob: scheduleJobScopes,
	SecurityLog: securityLogScopes,
	Session: sessionScopes,
	Skill: skillScopes,
	SmsTemplate: smsTemplateScopes,
	Source: sourceScopes,
	Stage: stageScopes,
	SurveyAnswer: surveyAnswerScopes,
	SurveyQuestion: surveyQuestionScopes,
	Tag: tagScopes,
	TaxPayerStatus: taxPayerStatusScopes,
	TaxRegistration: taxRegistrationScopes,
	Team: teamScopes,
	Test: testScopes,
	User: userScopes,
	Vendor: vendorScopes,
	Worker: workerScopes,
};
// console.debug('[DEBUG AbilityScopes] abilityScopes(object):', { count: Object.keys(abilityScopes).length }, abilityScopes);

/**
 * Immutable Map of all merged scope Map's
 *
 * @return Map<string, AbilityScopeItem>
 */
export const abilityScopesMap: AbilityScopeMap = merge(
	accessTokenScopes,
	administratorScopes,
	apiTokenScopes,
	automationScopes,
	automationJobsScopes,
	billingScopes,
	businessRegistrationScopes,
	clientScopes,
	companyScopes,
	complianceFieldScopes,
	departmentScopes,
	emailLogScopes,
	emailTemplateScopes,
	engagementScopes,
	engagementProgressLogScopes,
	engagementReportScopes,
	engagementTemplateScopes,
	eventJobScopes,
	factorScopes,
	fieldScopes,
	fieldChoiceScopes,
	fileScopes,
	formScopes,
	importScopes,
	insurancePolicyScopes,
	jurisdictionScopes,
	managedServiceProviderScopes,
	nodeJobScopes,
	partnerScopes,
	personalAccessTokenScopes,
	projectScopes,
	requestDeviceScopes,
	roleScopes,
	scheduleJobScopes,
	securityLogScopes,
	sessionScopes,
	skillScopes,
	smsTemplateScopes,
	sourceScopes,
	stageScopes,
	surveyAnswerScopes,
	surveyQuestionScopes,
	tagScopes,
	taxPayerStatusScopes,
	taxRegistrationScopes,
	teamScopes,
	testScopes,
	userScopes,
	vendorScopes,
	workerScopes
);
// console.debug('[DEBUG AbilityScopes] abilityScopesMap(Map):', abilityScopesMap);
