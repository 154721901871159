// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum FileType {
	APPROVAL_LETTER = 'approval-letter',
	ARTICLES_OF_INCORPORATION = 'articles-of-incorporation',
	AUTHORIZATION_LETTER = 'authorization-letter',
	BILLING_PAYMENT_CONTRACT = 'billing-payment-contract',
	BUSINESS_LICENSE = 'business-license',
	CERTIFICATE_OF_FORMATION = 'certificate-of-formation',
	CERTIFICATE_OF_INSURANCE = 'certificate-of-insurance',
	CLIENT_LETTER = 'client-letter',
	CLIENT_REQUEST_FORM = 'client-request-form',
	CONSULTATION = 'consultation',
	CORPORATION_NUMBER = 'corporation-number',
	DIRECTION_LETTER = 'direction-letter',
	DISABILITY_CERTIFICATE = 'disability-certificate',
	DISABILITY_INFO = 'disability-info',
	DISMISSALS = 'dismissals',
	DRIVERS_LICENSE = 'drivers-license',
	EMPLOYEE_WEEKLY_LOG = 'employee-weekly-log',
	EMPLOYER_IDENTIFICATION_NUMBER = 'employer-identification-number',
	EXTERNAL_RECORD_SUMMARY = 'external-record-summary',
	IMMUNIZATION_HISTORY = 'immunization-history',
	INSURANCE_CARD = 'insurance-card',
	INSURANCE_LETTER_MEMO = 'insurance-letter-memo',
	INSURANCE_REFERRAL = 'insurance-referral',
	LEGAL_DOCUMENT = 'legal-document',
	LEGAL_LETTER_MEMO = 'legal-letter-memo',
	MEDICAL_REPORT = 'medical-report',
	MISC_FORM = 'misc-form',
	MISC_HOSPITAL_DOCUMENT = 'misc-hospital-document',
	OFFICE_CONSULT_NOTE = 'office-consult-note',
	OFFICE_PROCEDURE_REPORT = 'office-procedure-report',
	OTHER_FILE_TYPE = 'other-file-type',
	OTHER_OFFICE_LETTER = 'other-office-letter',
	OTHER_OFFICE_NOTE = 'other-office-note',
	POLICE_REPORT = 'police-report',
	PROTECTION_LETTER = 'protection-letter',
	REFERRAL_CONTACT_SHEET = 'referral-contact-sheet',
	REFERRAL_LETTER = 'referral-letter',
	REGISTRATION_CONFIRMATION_NOTICE = 'registration-confirmation-notice',
	RELEASE_OF_INFORMATION = 'release-of-information',
	RESUME = 'resume',
	SELLERS_PERMIT = 'sellers-permit',
	SERVICE_SCHEDULE = 'service-schedule',
	STATEMENT_OF_INFORMATION = 'statement-of-information',
	TAX_PAYER_STATUS = 'tax-payer-status',
	TEAM_MEETING_MINUTES = 'team-meeting-minutes',
	TEN_NINETY_NINE = 'ten-ninety-nine',
	VAT_SALES_REGISTRATION = 'vat-sales-registration',
}

export interface FileTypeItem extends BaseDictionary {
	code: FileType;
	id: `${IdNamespace.FileType}_${string}`;
	label: string;
	description?: string;
	isProofOfBusinessRegistration?: boolean;
	isProofOfInsurancePolicy?: boolean;
	isProofOfTaxPayerStatus?: boolean;
	isProofOfTaxRegistration?: boolean;
}

export const fileTypes = Map<FileType | `${FileType}`, FileTypeItem>({
	[FileType.APPROVAL_LETTER]: {
		code: FileType.APPROVAL_LETTER,
		id: 'fity_2FeOJDkTpO7laLYWBekWvqLKoHj',
		label: 'Approval Letter',
	},
	[FileType.ARTICLES_OF_INCORPORATION]: {
		code: FileType.ARTICLES_OF_INCORPORATION,
		id: 'fity_2FeOJAc20kV9JBzS8ifQHA6U7xs',
		label: 'Articles Of Incorporation',
		description:
			"This document includes essential information about the corporation, such as the company's name, purpose, and information about shares.",
		isProofOfBusinessRegistration: true,
	},
	[FileType.AUTHORIZATION_LETTER]: {
		code: FileType.AUTHORIZATION_LETTER,
		id: 'fity_2FeOJEjQlZ2hAM6dZmNhiL8oz5r',
		label: 'Authorization Letter',
	},
	[FileType.BILLING_PAYMENT_CONTRACT]: {
		code: FileType.BILLING_PAYMENT_CONTRACT,
		id: 'fity_2FeOJ9NfXuQIUFlf44Oa73nBGQQ',
		label: 'Billing/Payment Contract',
	},
	[FileType.BUSINESS_LICENSE]: {
		code: FileType.BUSINESS_LICENSE,
		id: 'fity_2Od6oVRNAp84QtctXd5UvOrZMBe',
		label: 'Business License',
		isProofOfBusinessRegistration: true,
	},
	[FileType.CLIENT_REQUEST_FORM]: {
		code: FileType.CLIENT_REQUEST_FORM,
		id: 'fity_2FeOJATTXD7SzLoNVckQmC4Fdas',
		label: 'Client Request Form',
	},
	[FileType.CERTIFICATE_OF_INSURANCE]: {
		code: FileType.CERTIFICATE_OF_INSURANCE,
		id: 'fity_2FeOJCcFAlr8W4yoMBc0eTRrnO6',
		label: 'Certificate of Insurance',
		isProofOfInsurancePolicy: true,
	},
	[FileType.CERTIFICATE_OF_FORMATION]: {
		code: FileType.CERTIFICATE_OF_FORMATION,
		id: 'fity_2Od6oSsJvMSTQcrcvtH4siCw8W6',
		label: 'Certificate of Formation (aka. Articles of Organization)',
		description:
			"For limited liability companies (LLCs), the Certificate of Formation or Articles of Organization confirm the registration of the LLC. This document includes information about the LLC's name, purpose, and management structure.",
		isProofOfBusinessRegistration: true,
	},
	[FileType.CONSULTATION]: {
		code: FileType.CONSULTATION,
		id: 'fity_2FeOJCHh0XKJgWuJC47NsXahWnj',
		label: 'Consultation',
	},
	[FileType.CORPORATION_NUMBER]: {
		code: FileType.CORPORATION_NUMBER,
		id: 'fity_2FeOJDpyTPglA3CwK2SzXeB36Ih',
		label: 'Corporation Number',
		isProofOfBusinessRegistration: true,
	},
	[FileType.DIRECTION_LETTER]: {
		code: FileType.DIRECTION_LETTER,
		id: 'fity_2FeOJ7cvFnFwvTCzGaHJAmx6PiK',
		label: 'Direction Letter',
	},
	[FileType.DISABILITY_CERTIFICATE]: {
		code: FileType.DISABILITY_CERTIFICATE,
		id: 'fity_2FeOJ99RrYEUdFQtoB4pSntqkb5',
		label: 'Disability Certificate',
	},
	[FileType.DISABILITY_INFO]: {
		code: FileType.DISABILITY_INFO,
		id: 'fity_2FeOJ7VMlX5dh7LkwYVLCykAiaG',
		label: 'Disability Info',
	},
	[FileType.DISMISSALS]: {
		code: FileType.DISMISSALS,
		id: 'fity_2FeOJ8bqAX7F8H1e4U0X4Mrxgx7',
		label: 'Dismissals',
	},
	[FileType.DRIVERS_LICENSE]: {
		code: FileType.DRIVERS_LICENSE,
		id: 'fity_2FeOJ8KCXemfW8sKiC1WF7lwdTQ',
		label: "Driver's License",
	},
	[FileType.TEN_NINETY_NINE]: {
		code: FileType.TEN_NINETY_NINE,
		id: 'fity_2Up9S1UIFwy1B0lxL55fITH00JT',
		label: '1099',
		isProofOfBusinessRegistration: true,
	},
	[FileType.EMPLOYEE_WEEKLY_LOG]: {
		code: FileType.EMPLOYEE_WEEKLY_LOG,
		id: 'fity_2FeOJ9dGZciAMQEklV179S2hlck',
		label: 'Employee Weekly Log',
	},
	[FileType.EMPLOYER_IDENTIFICATION_NUMBER]: {
		code: FileType.EMPLOYER_IDENTIFICATION_NUMBER,
		id: 'fity_2Od6oZ2xq9BpZbIGmaIRhZlBqtO',
		label: 'Employer Identification Number (EIN)',
		description:
			'If your business has employees, you will need an EIN from the Internal Revenue Service (IRS). While not specific to California, having an EIN serves as proof that your business is operating in compliance with federal regulations.',
		isProofOfBusinessRegistration: true,
	},
	[FileType.EXTERNAL_RECORD_SUMMARY]: {
		code: FileType.EXTERNAL_RECORD_SUMMARY,
		id: 'fity_2FeOJEKYVH3FRj3Id6vh2XA1i5d',
		label: 'External Record Summary',
	},
	[FileType.IMMUNIZATION_HISTORY]: {
		code: FileType.IMMUNIZATION_HISTORY,
		id: 'fity_2FeOJ8SJ3iZpEPQcA5KzmGTqqvL',
		label: 'Immunization History',
	},
	[FileType.INSURANCE_CARD]: {
		code: FileType.INSURANCE_CARD,
		id: 'fity_2FeOJ9NODBXFKF35vqIeM25iPWz',
		label: 'Insurance Card',
	},
	[FileType.INSURANCE_LETTER_MEMO]: {
		code: FileType.INSURANCE_LETTER_MEMO,
		id: 'fity_2FeOJ7qAT6iXKqTAMAcvYODKRbZ',
		label: 'Insurance Letter/Memo',
	},
	[FileType.INSURANCE_REFERRAL]: {
		code: FileType.INSURANCE_REFERRAL,
		id: 'fity_2FeOJA8v6SW29CpRgTLdjGpVDYi',
		label: 'Insurance Referral',
	},
	[FileType.LEGAL_DOCUMENT]: {
		code: FileType.LEGAL_DOCUMENT,
		id: 'fity_2FeOJEpCAW2cWzDIfdvlCAm1yKW',
		label: 'Legal Document',
	},
	[FileType.LEGAL_LETTER_MEMO]: {
		code: FileType.LEGAL_LETTER_MEMO,
		id: 'fity_2FeOJEXqukH95KuwNcOQcKB7gmE',
		label: 'Legal Letter/Memo',
	},
	[FileType.MEDICAL_REPORT]: {
		code: FileType.MEDICAL_REPORT,
		id: 'fity_2FeOJA40oJcWNenvs3C0ddT4qm2',
		label: 'Medical Report',
	},
	[FileType.MISC_FORM]: {
		code: FileType.MISC_FORM,
		id: 'fity_2FeOJ7jf74oClJ1JqlY4f4OCZEO',
		label: 'Misc. Form',
	},
	[FileType.MISC_HOSPITAL_DOCUMENT]: {
		code: FileType.MISC_HOSPITAL_DOCUMENT,
		id: 'fity_2FeOJC5njj8RZfmnwE2CjYjMbht',
		label: 'Misc. Hospital Document',
	},
	[FileType.OFFICE_CONSULT_NOTE]: {
		code: FileType.OFFICE_CONSULT_NOTE,
		id: 'fity_2FeOJBQ6SZY8D2qJ8UgYQ0eoK2V',
		label: 'Office Consult Note',
	},
	[FileType.OFFICE_PROCEDURE_REPORT]: {
		code: FileType.OFFICE_PROCEDURE_REPORT,
		id: 'fity_2FeOJAa636qmbs45GTfuT624pMw',
		label: 'Office Procedure Report',
	},
	[FileType.OTHER_OFFICE_LETTER]: {
		code: FileType.OTHER_OFFICE_LETTER,
		id: 'fity_2FeOJEXXiaHZOZ7A8tqn5fOIcSb',
		label: 'Other Office Letter',
	},
	[FileType.OTHER_OFFICE_NOTE]: {
		code: FileType.OTHER_OFFICE_NOTE,
		id: 'fity_2FeOJDLt3EkIqKdOZUxOPfNpZMp',
		label: 'Other Office Note',
	},
	[FileType.CLIENT_LETTER]: {
		code: FileType.CLIENT_LETTER,
		id: 'fity_2FeOJ9kXLx8N3ywVw8Orubp9gPF',
		label: 'Client Letter',
	},
	[FileType.POLICE_REPORT]: {
		code: FileType.POLICE_REPORT,
		id: 'fity_2FeOJD1RmALuOK7SfsCGhYpcSNe',
		label: 'Police Report',
	},
	[FileType.PROTECTION_LETTER]: {
		code: FileType.PROTECTION_LETTER,
		id: 'fity_2FeOJBmwWCmOGWeiZF9SH14e7rT',
		label: 'Protection Letter',
	},
	[FileType.REFERRAL_CONTACT_SHEET]: {
		code: FileType.REFERRAL_CONTACT_SHEET,
		id: 'fity_2FeOJAIxf1jpwQfRyNmGlsJj3YV',
		label: 'Referral Contact Sheet',
	},
	[FileType.REFERRAL_LETTER]: {
		code: FileType.REFERRAL_LETTER,
		id: 'fity_2FeOJCjQwTa8M8QLYqrR2qZKVrF',
		label: 'Referral Letter',
	},
	[FileType.REGISTRATION_CONFIRMATION_NOTICE]: {
		code: FileType.REGISTRATION_CONFIRMATION_NOTICE,
		id: 'fity_2FeOJ8ElZq1uOcPV9R0w5Qcxo0q',
		label: 'Registration Confirmation Notice',
		isProofOfTaxRegistration: true,
	},
	[FileType.RELEASE_OF_INFORMATION]: {
		code: FileType.RELEASE_OF_INFORMATION,
		id: 'fity_2FeOJDkNrfVDJVSmLaH3FIsQq7Q',
		label: 'Release of Information',
	},
	[FileType.RESUME]: {
		code: FileType.RESUME,
		id: 'fity_2FeOJDPGzWgMWffW1eRolt4rGhV',
		label: 'Resume',
	},
	[FileType.SELLERS_PERMIT]: {
		code: FileType.SELLERS_PERMIT,
		id: 'fity_2Od6oZq3JudY90uWhsHAz7He6Z6',
		label: "Seller's Permit",
		description:
			"If your business involves selling tangible personal property in California, you will need a seller's permit from the California Department of Tax and Fee Administration (CDTFA). This permit serves as proof of registration for businesses engaging in sales activities.",
		isProofOfBusinessRegistration: true,
	},
	[FileType.SERVICE_SCHEDULE]: {
		code: FileType.SERVICE_SCHEDULE,
		id: 'fity_2FeOJAdlgmlroO0TcBThcw34Pet',
		label: 'Service Schedule',
	},
	[FileType.STATEMENT_OF_INFORMATION]: {
		code: FileType.STATEMENT_OF_INFORMATION,
		id: 'fity_2Od6oYUVzJKtV5U8MW39H8NPB8F',
		label: 'Statement of Information',
		description:
			'All corporations and LLCs in California are required to file a Statement of Information (Form SI-550 for corporations and Form LLC-12 for LLCs) with the Secretary of State. This document provides updated information about the business, including the names and addresses of officers or managers, and serves as an additional proof of registration.',
		isProofOfBusinessRegistration: true,
	},
	[FileType.TAX_PAYER_STATUS]: {
		code: FileType.TAX_PAYER_STATUS,
		id: 'fity_2FeOJ9Wy2xwKRSAGUuH04gOYRqW',
		label: 'Tax Payer Status',
		isProofOfTaxPayerStatus: true,
	},
	[FileType.TEAM_MEETING_MINUTES]: {
		code: FileType.TEAM_MEETING_MINUTES,
		id: 'fity_2FeOJ8j3NfIbpP0YhIAvAnqpJIk',
		label: 'Team Meeting Minutes',
	},
	[FileType.VAT_SALES_REGISTRATION]: {
		code: FileType.VAT_SALES_REGISTRATION,
		id: 'fity_2FeOJ8uXkb6ViU5rM8K9WAdLV2Q',
		label: 'VAT / Sales Registration',
		isProofOfTaxRegistration: true,
	},
	[FileType.OTHER_FILE_TYPE]: {
		code: FileType.OTHER_FILE_TYPE,
		id: 'fity_2FeOJAXIbsYdmP3DZrau3vdGi0F',
		label: 'Other File Type',
	},
});
