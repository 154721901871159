import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'complianceFields';
const repository = 'ComplianceField';

export enum ComplianceFieldScopes {
	'Create' = 'complianceFields:create',
	'Read' = 'complianceFields:read',
	'Update' = 'complianceFields:update',
	'Delete' = 'complianceFields:delete',
	'Manage' = 'complianceFields:manage',
}

const namespace = 'comfldsco';

export const complianceFieldScopes: AbilityScopeMap<ComplianceFieldScopes> = Map<
	ComplianceFieldScopes,
	AbilityScopeItem<ComplianceFieldScopes>
>({
	[ComplianceFieldScopes.Create]: {
		code: ComplianceFieldScopes.Create,
		id: `${namespace}_2FX3qSDuN15LINt40gh3IJ8kxMl`,
		order: 0,
		icon: 'circle-user',
		label: 'ComplianceField Create',
		collection,
		repository,
	},
	[ComplianceFieldScopes.Read]: {
		code: ComplianceFieldScopes.Read,
		id: `${namespace}_2FX3whsqPSXqLvjH2Z6UnkQ48ro`,
		order: 1,
		icon: 'circle-user',
		label: 'ComplianceField Read',
		collection,
		repository,
	},
	[ComplianceFieldScopes.Update]: {
		code: ComplianceFieldScopes.Update,
		id: `${namespace}_2FX3zu3uWRHb15amDOPr5rAwupR`,
		order: 2,
		icon: 'circle-user',
		label: 'ComplianceField Update',
		collection,
		repository,
	},
	[ComplianceFieldScopes.Delete]: {
		code: ComplianceFieldScopes.Delete,
		id: `${namespace}_2FX42Q3LR59NmwihFfDgAr4zKyu`,
		order: 3,
		icon: 'circle-user',
		label: 'ComplianceField Delete',
		collection,
		repository,
	},
	[ComplianceFieldScopes.Manage]: {
		code: ComplianceFieldScopes.Manage,
		id: `${namespace}_2FX3obhNtN5FpJtlL4qfHgheFbO`,
		order: 4,
		icon: 'circle-user',
		label: 'ComplianceField Manage',
		alias: [
			ComplianceFieldScopes.Create,
			ComplianceFieldScopes.Read,
			ComplianceFieldScopes.Update,
			ComplianceFieldScopes.Delete,
		],
		collection,
		repository,
	},
});
