// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'tags';
const repository = 'Tag';

export enum TagScopes {
	'Create' = 'tags:create',
	'Read' = 'tags:read',
	'Update' = 'tags:update',
	'Delete' = 'tags:delete',
	'Manage' = 'tags:manage',
}

const namespace = 'tagsco';

export const tagScopes: AbilityScopeMap<TagScopes> = Map<TagScopes, AbilityScopeItem<TagScopes>>({
	[TagScopes.Create]: {
		code: TagScopes.Create,
		id: `${namespace}_2FXoMYQ30bftN4TWIpf0Q4F4mCb`,
		order: 0,
		icon: 'circle-user',
		label: 'Tag Create',
		collection,
		repository,
	},
	[TagScopes.Read]: {
		code: TagScopes.Read,
		id: `${namespace}_2FXoP1vGGRHyObIsvSiCQRIzl02`,
		order: 1,
		icon: 'circle-user',
		label: 'Tag Read',
		collection,
		repository,
	},
	[TagScopes.Update]: {
		code: TagScopes.Update,
		id: `${namespace}_2FXoQtSitWbcOBjXKue1C8ZUAs8`,
		order: 2,
		icon: 'circle-user',
		label: 'Tag Update',
		collection,
		repository,
	},
	[TagScopes.Delete]: {
		code: TagScopes.Delete,
		id: `${namespace}_2FXoSuton0CzjHF9URMCQrl3S4c`,
		order: 3,
		icon: 'circle-user',
		label: 'Tag Delete',
		collection,
		repository,
	},
	[TagScopes.Manage]: {
		code: TagScopes.Manage,
		id: `${namespace}_2FXoL1n0758Erly4cecmc0uf8N0`,
		order: 4,
		icon: 'circle-user',
		label: 'Tag Manage',
		alias: [TagScopes.Create, TagScopes.Read, TagScopes.Update, TagScopes.Delete],
		collection,
		repository,
	},
});
