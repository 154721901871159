// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { type SortList, SortListItem } from './types/dictionaries.type';
import { sortList } from './sort-list';

export enum AutomationSortList {
	NAME = 'name',
}

export interface AutomationSortListItem extends Omit<SortListItem, 'id' | 'code'> {
	code: SortList | AutomationSortList;
	id: `${IdNamespace.SortList}_${string}` | `${IdNamespace.AutomationSortList}_${string}`;
}

export const automationSortList: Map<
	SortList | `${SortList}` | AutomationSortList | `${AutomationSortList}`,
	AutomationSortListItem
> = Map<AutomationSortList, AutomationSortListItem>({
	[AutomationSortList.NAME]: {
		code: AutomationSortList.NAME,
		id: 'autosoli_2IdjwMX1Blkuv76FCrrWtlAAfMc',
		order: 3,
		// label: i18n.t('automationSortList.name', { ns: 'dictionaries' }),
		label: 'Name',
		directionIcons: {
			asc: 'sort-alpha-up-alt',
			desc: 'sort-alpha-down-alt',
		},
		function: {
			asc: {
				name: 'asc',
				_createdDate: 'asc',
			},
			desc: {
				name: 'desc',
				_createdDate: 'desc',
			},
		},
	},
}).merge(sortList);
