// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'insurancePolicies';
const repository = 'InsurancePolicy';

export enum InsurancePolicyScopes {
	'Create' = 'insurancePolicies:create',
	'Read' = 'insurancePolicies:read',
	'Update' = 'insurancePolicies:update',
	'Delete' = 'insurancePolicies:delete',
	'Manage' = 'insurancePolicies:manage',
}

const namespace = 'insuposco';

export const insurancePolicyScopes: AbilityScopeMap<InsurancePolicyScopes> = Map<
	InsurancePolicyScopes,
	AbilityScopeItem<InsurancePolicyScopes>
>({
	[InsurancePolicyScopes.Create]: {
		code: InsurancePolicyScopes.Create,
		id: `${namespace}_2FXj3K8Z7HqvK2Ipjm69iFdORCF`,
		order: 0,
		icon: 'circle-user',
		label: 'InsurancePolicy Create',
		collection,
		repository,
	},
	[InsurancePolicyScopes.Read]: {
		code: InsurancePolicyScopes.Read,
		id: `${namespace}_2FXj54KYj8PdAe66lv6DFBuclq9`,
		order: 1,
		icon: 'circle-user',
		label: 'InsurancePolicy Read',
		collection,
		repository,
	},
	[InsurancePolicyScopes.Update]: {
		code: InsurancePolicyScopes.Update,
		id: `${namespace}_2FXj6wXV4UupNRZ13n5iqPJ5EEP`,
		order: 2,
		icon: 'circle-user',
		label: 'InsurancePolicy Update',
		collection,
		repository,
	},
	[InsurancePolicyScopes.Delete]: {
		code: InsurancePolicyScopes.Delete,
		id: `${namespace}_2FXj8pBECe6OPmT5uoJ2oxjtisB`,
		order: 3,
		icon: 'circle-user',
		label: 'InsurancePolicy Delete',
		collection,
		repository,
	},
	[InsurancePolicyScopes.Manage]: {
		code: InsurancePolicyScopes.Manage,
		id: `${namespace}_2FXj1PSi8IHQDBFTfQFLnGe8Ohf`,
		order: 4,
		icon: 'circle-user',
		label: 'InsurancePolicy Manage',
		alias: [
			InsurancePolicyScopes.Create,
			InsurancePolicyScopes.Read,
			InsurancePolicyScopes.Update,
			InsurancePolicyScopes.Delete,
		],
		collection,
		repository,
	},
});
