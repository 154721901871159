// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'departments';
const repository = 'Department';

export enum DepartmentScopes {
	'Create' = 'departments:create',
	'Read' = 'departments:read',
	'Update' = 'departments:update',
	'Delete' = 'departments:delete',
	'Manage' = 'departments:manage',
}

const namespace = 'depsco';

export const departmentScopes: AbilityScopeMap<DepartmentScopes> = Map<
	DepartmentScopes,
	AbilityScopeItem<DepartmentScopes>
>({
	[DepartmentScopes.Create]: {
		code: DepartmentScopes.Create,
		id: `${namespace}_2FX4lpgOYdX28QT5MDUvo8EeCez`,
		order: 0,
		icon: 'circle-user',
		label: 'Department Create',
		collection,
		repository,
	},
	[DepartmentScopes.Read]: {
		code: DepartmentScopes.Read,
		id: `${namespace}_2FX4oQgs4VuwrMdxz0APwzUa4ng`,
		order: 1,
		icon: 'circle-user',
		label: 'Department Update',
		collection,
		repository,
	},
	[DepartmentScopes.Update]: {
		code: DepartmentScopes.Update,
		id: `${namespace}_2FX4xxxVaA854q13keIxgupm3aZ`,
		order: 2,
		icon: 'circle-user',
		label: 'Department Update',
		collection,
		repository,
	},
	[DepartmentScopes.Delete]: {
		code: DepartmentScopes.Delete,
		id: `${namespace}_2FX4znfmsdvurDA5hgNHh8KLYHg`,
		order: 3,
		icon: 'circle-user',
		label: 'Department Delete',
		collection,
		repository,
	},
	[DepartmentScopes.Manage]: {
		code: DepartmentScopes.Manage,
		id: `${namespace}_2FX4kG3kinz4KoetuE6aMpXjf8B`,
		order: 4,
		icon: 'circle-user',
		label: 'Department Manage',
		alias: [
			DepartmentScopes.Create,
			DepartmentScopes.Read,
			DepartmentScopes.Update,
			DepartmentScopes.Delete,
		],
		collection,
		repository,
	},
});
