// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import type { UnionToArray } from '../libs/types/union-to-array.type';

export enum CompanyType {
	INTERNAL = 'internal',
	CLIENT = 'client',
	VENDOR = 'vendor',
	MANAGED_SERVICE_PROVIDER = 'managed-service-provider',
	PARTNER = 'partner',
}

export type CompanyTypeCount = UnionToArray<keyof typeof CompanyType>['length'];

export enum CompanyTypeModels {
	Internal = 'Internal',
	Client = 'Client',
	Vendor = 'Vendor',
	ManagedServiceProvider = 'ManagedServiceProvider',
	Partner = 'Partner',
}

export interface CompanyTypeItem extends BaseDictionary {
	code: CompanyType;
	id: `${IdNamespace.CompanyType}_${string}`;
	order: number;
	icon: string;
	label: string;
	collection: string;
	repository: string;
	description: string;
}

export const companyTypes = Map<CompanyType | `${CompanyType}`, CompanyTypeItem>({
	[CompanyType.INTERNAL]: {
		code: CompanyType.INTERNAL,
		id: 'comty_2UV6VQI5Ip0TdUnNSHOUFnkaxJj',
		order: 0,
		icon: 'user-gear',
		label: 'Internal',
		collection: 'companies',
		repository: 'Company',
		description: 'The (Internal) Independently company-type.',
	},
	[CompanyType.CLIENT]: {
		code: CompanyType.CLIENT,
		id: 'comty_2FeKRPvNyFqUKuHSmV46rnGOJ0Z',
		order: 1,
		icon: 'user-gear',
		label: 'Client',
		collection: 'clients',
		repository: 'Client',
		description:
			'Any business or enterprise that is retaining the services of any individual Workers (synonyms: "Accounts", "Employers", & "Enterprises").',
	},
	[CompanyType.VENDOR]: {
		code: CompanyType.VENDOR,
		id: 'comty_2FeKROEbLINoeDfVX56Xxkn1ed8',
		order: 2,
		icon: 'circle-user',
		label: 'Vendor',
		collection: 'vendors',
		repository: 'Vendor',
		description:
			'A person or company entity providing services and/or supplying Workers to Client Organizations (such as "Staffing Vendor").',
	},
	[CompanyType.MANAGED_SERVICE_PROVIDER]: {
		code: CompanyType.MANAGED_SERVICE_PROVIDER,
		id: 'comty_2FeKRSWF9K2dpV9Dv8U2I7mmjww',
		order: 3,
		icon: 'circle-user',
		label: 'Managed Service Provider',
		collection: 'managedServiceProviders',
		repository: 'ManagedServiceProvider',
		description:
			'An outsourced company entity responsible for delivering services (such as operational, network, application structure, and/or security) through defined ongoing support and administration.',
	},
	[CompanyType.PARTNER]: {
		code: CompanyType.PARTNER,
		id: 'comty_2foDGOwziXNwSudJ4JQZnJEL8iI',
		order: 4,
		icon: 'circle-user',
		label: 'Partner',
		collection: 'partners',
		repository: 'Partner',
		description:
			'A person or company with an agreement to to resell or refer Independently (such as "Referrer", "Reseller", or "Affiliate").',
	},
});
