// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'files';
const repository = 'File';

export enum FileScopes {
	'Create' = 'files:create',
	'Read' = 'files:read',
	'Update' = 'files:update',
	'Download' = 'files:download',
	'DownloadRevision' = 'files:downloadRevision',
	'Delete' = 'files:delete',
	'Manage' = 'files:manage',
	'ViewGrid' = 'files:viewGrid',
}

const namespace = 'filesco';

export const fileScopes: AbilityScopeMap<FileScopes> = Map<
	FileScopes,
	AbilityScopeItem<FileScopes>
>({
	[FileScopes.Create]: {
		code: FileScopes.Create,
		id: `${namespace}_2FX67K4YbOtFWhl1FC8wZRYsRBK`,
		order: 0,
		icon: 'circle-user',
		label: 'File Create',
		collection,
		repository,
	},
	[FileScopes.Read]: {
		code: FileScopes.Read,
		id: `${namespace}_2FX6AichKhSlXXAreDHGo3y8aqc`,
		order: 1,
		icon: 'circle-user',
		label: 'File Read',
		collection,
		repository,
	},
	[FileScopes.Update]: {
		code: FileScopes.Update,
		id: `${namespace}_2FX6C4iJ3govcmBMsCr1B40iQL9`,
		order: 2,
		icon: 'circle-user',
		label: 'File Update',
		collection,
		repository,
	},
	[FileScopes.Delete]: {
		code: FileScopes.Delete,
		id: `${namespace}_2FX6ElL0Tb0nVaiOjWjuUIUXBux`,
		order: 3,
		icon: 'circle-user',
		label: 'File Delete',
		collection,
		repository,
	},
	[FileScopes.Download]: {
		code: FileScopes.Download,
		id: `${namespace}_2FX6HBsYhmnO0JQgDSa4xjH0VWc`,
		order: 4,
		icon: 'circle-user',
		label: 'File Download',
		collection,
		repository,
	},
	[FileScopes.DownloadRevision]: {
		code: FileScopes.DownloadRevision,
		id: `${namespace}_2Gt5Sjsg70ue8L2ZUHTOI36IkCg`,
		order: 5,
		icon: 'circle-user',
		label: 'File Download Revision',
		collection,
		repository,
	},
	[FileScopes.Manage]: {
		code: FileScopes.Manage,
		id: `${namespace}_2FX633KDpZgAt4zt1MSLvqK8N9F`,
		order: 6,
		icon: 'circle-user',
		label: 'File Manage',
		alias: [
			FileScopes.Create,
			FileScopes.Read,
			FileScopes.Update,
			FileScopes.Download,
			FileScopes.DownloadRevision,
			FileScopes.Delete,
		],
		collection,
		repository,
	},
	[FileScopes.ViewGrid]: {
		code: FileScopes.ViewGrid,
		id: `${namespace}_2OdCDOBG5XSth8Yq6eJ77y0j3no`,
		order: 7,
		icon: 'circle-user',
		label: 'File View Grid',
		collection,
		repository,
	},
});
