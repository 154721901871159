/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { LanguageItem, languages } from '@dictionaries/languages';
import { isClientSide } from 'utils/app.util';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { warn } from 'libs/whoops';
import { isUndefined } from 'lodash';
import { initReactI18next } from 'react-i18next';
import { vanillaStore } from 'stores/vanilla.store';
import SuperTokens from 'supertokens-auth-react';
import { LocalizationConfig } from './configs/localization.config';

void i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	.init(LocalizationConfig, (err, t) => {
		const { setLocale } = vanillaStore.getState();
		let language: LanguageItem | undefined;

		languages.forEach((item: LanguageItem) => {
			if (item.plugins.i18n === i18n.languages[0]) language = item;
		});

		if (isUndefined(language)) {
			warn(`Missing/unknown ${i18n.languages[0]} language locale {String}`, {
				extra: {
					language,
				},
			});
			return;
		}

		if (!isUndefined(language)) setLocale(language);
	});

i18n.on('missingKey', (lngs, namespace, key, res) => {
	if (key !== '') warn(`Missing/unknown key: ${key}`);

	try {
		throw Error(`Missing/unknown key: ${key}`);
	} catch (err: unknown) {
		console.error(err);
	}
});

i18n.on('languageChanged', (lng) => {
	// console.info(`[INFO] i18n.languageChanged() [isClientSide: ${String(isClientSide())}]`, lng);

	if (isClientSide()) {
		void SuperTokens.changeLanguage(lng);
	}
});

i18n.on('loaded', () => {
	SuperTokens.loadTranslation({});
});

export default i18n;
