// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'engagementTemplates';
const repository = 'engagementTemplate';

export enum EngagementTemplateScopes {
	'Create' = 'engagementTemplates:create',
	'Read' = 'engagementTemplates:read',
	'Update' = 'engagementTemplates:update',
	'Delete' = 'engagementTemplates:delete',
	'Manage' = 'engagementTemplates:manage',
}

const namespace = 'engtemsco';

export const engagementTemplateScopes: AbilityScopeMap<EngagementTemplateScopes> = Map<
	EngagementTemplateScopes,
	AbilityScopeItem<EngagementTemplateScopes>
>({
	[EngagementTemplateScopes.Create]: {
		code: EngagementTemplateScopes.Create,
		id: `${namespace}_2FXdsR5wO4MljDSN8CbPzrV8fAR`,
		order: 0,
		icon: 'circle-user',
		label: 'EngagementTemplate Create',
		collection,
		repository,
	},
	[EngagementTemplateScopes.Read]: {
		code: EngagementTemplateScopes.Read,
		id: `${namespace}_2FXdvZnE8Ma07ZUz1ZdFzNrZM5A`,
		order: 1,
		icon: 'circle-user',
		label: 'EngagementTemplate Read',
		collection,
		repository,
	},
	[EngagementTemplateScopes.Update]: {
		code: EngagementTemplateScopes.Update,
		id: `${namespace}_2FXdxHLud0HeEbjL57Ov1hwq3OI`,
		order: 2,
		icon: 'circle-user',
		label: 'EngagementTemplate Update',
		collection,
		repository,
	},
	[EngagementTemplateScopes.Delete]: {
		code: EngagementTemplateScopes.Delete,
		id: `${namespace}_2FXdzlmgV7BA0ZN2tgYWJ2xofED`,
		order: 3,
		icon: 'circle-user',
		label: 'EngagementTemplate Delete',
		collection,
		repository,
	},
	[EngagementTemplateScopes.Manage]: {
		code: EngagementTemplateScopes.Manage,
		id: `${namespace}_2FXdqORA64cmeSjUe9CYP32YPTq`,
		order: 4,
		icon: 'circle-user',
		label: 'EngagementTemplate Manage',
		alias: [
			EngagementTemplateScopes.Create,
			EngagementTemplateScopes.Read,
			EngagementTemplateScopes.Update,
			EngagementTemplateScopes.Delete,
		],
		collection,
		repository,
	},
});
