import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'smsTemplates';
const repository = 'SmsTemplate';

export enum SmsTemplateScopes {
	'Create' = 'smsTemplates:create',
	'Read' = 'smsTemplates:read',
	'Update' = 'smsTemplates:update',
	'Delete' = 'smsTemplates:delete',
	'Manage' = 'smsTemplates:manage',
}

const NS: string = IdNamespace.SmsTemplateScope;

export const smsTemplateScopes: AbilityScopeMap<SmsTemplateScopes> = Map<
	SmsTemplateScopes,
	AbilityScopeItem<SmsTemplateScopes>
>({
	[SmsTemplateScopes.Create]: {
		code: SmsTemplateScopes.Create,
		id: `${NS}_2Gt5biHS99QEkljwHEuvCeQ4RQv`,
		order: 0,
		icon: 'circle-user',
		label: 'SmsTemplate Create',
		collection,
		repository,
	},
	[SmsTemplateScopes.Read]: {
		code: SmsTemplateScopes.Read,
		id: `${NS}_2Gt5biApJ8MfBFN2cgXGX5UJKX0`,
		order: 1,
		icon: 'circle-user',
		label: 'SmsTemplate Read',
		collection,
		repository,
	},
	[SmsTemplateScopes.Update]: {
		code: SmsTemplateScopes.Update,
		id: `${NS}_2Gt5biuHI6nEGt5N52E2bRFW8ys`,
		order: 2,
		icon: 'circle-user',
		label: 'SmsTemplate Update',
		collection,
		repository,
	},
	[SmsTemplateScopes.Delete]: {
		code: SmsTemplateScopes.Delete,
		id: `${NS}_2Gt5bgorQGNnjibWWdOyID6uV0D`,
		order: 3,
		icon: 'circle-user',
		label: 'SmsTemplate Delete',
		collection,
		repository,
	},
	[SmsTemplateScopes.Manage]: {
		code: SmsTemplateScopes.Manage,
		id: `${NS}_2Gt5bgGR01wwYB6CBjEjik2fGHH`,
		order: 4,
		icon: 'circle-user',
		label: 'SmsTemplate Manage',
		alias: [
			SmsTemplateScopes.Create,
			SmsTemplateScopes.Read,
			SmsTemplateScopes.Update,
			SmsTemplateScopes.Delete,
		],
		collection,
		repository,
	},
});
