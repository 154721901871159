import type { Primitive } from 'type-fest';
import { toArray } from 'lodash';
import { values } from 'remeda';
import { typeGuard } from 'tsafe/typeGuard';

export const getEnumNamesAndValues = <T extends number>(e: any) => {
	return getEnumNames(e).map((n) => ({ name: n, value: e[n] as T }));
};

export const getEnumNames = (e: any) => {
	return getEnumObjValues(e).filter((v) => typeof v === 'string') as string[];
};

export const getEnumValues = <T extends number>(e: any) => {
	return getEnumObjValues(e).filter((v) => typeof v === 'number') as T[];
};

export const getEnumSelectList = <T extends number, U>(
	e: any,
	stringConverter: (arg: U) => string
) => {
	const selectList = new Map<T, string>();
	getEnumValues(e).forEach((val) =>
		selectList.set(val as T, stringConverter(val as unknown as U))
	);
	return selectList;
};

export const getEnumSelectListAsArray = <T extends number, U>(
	e: any,
	stringConverter: (arg: U) => string
) => {
	return Array.from(getEnumSelectList(e, stringConverter), (value) => ({
		value: value[0] as T,
		presentation: value[1],
	}));
};

export const getEnumObjValues = <T = Primitive>(e: any): T[] => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return Object.keys(e).map((k) => e[k]);
};

export const existValueInEnum = <EnumT extends Primitive>(e: any, value: any): value is EnumT => {
	const isExist: boolean =
		Object.keys(e)
			.filter((k) => isNaN(Number(k)))
			.filter((k) => e[k] === value).length > 0;

	return typeGuard<EnumT>(value, isExist);
};

export const getEnumAsMappedObject = <T extends Primitive>(
	e: any
): Array<{ id: string; value: T }> =>
	Object.entries(e).map(([key, value]) => ({ id: key, value: <T>value }));

export const getEnumValuesAsMappedArray = <T extends Primitive>(e: any): T[] => {
	const objects = getEnumAsMappedObject<T>(e).map((arr) => arr.value);
	return toArray(objects);
};

export const getEnumAsObject = <K extends string, V extends Primitive>(e: any): Record<K, V> => {
	return getEnumAsMappedObject<string>(e).reduce<Record<string, V>>((map, el) => {
		map[el.id] = el.value as V;
		return map;
	}, {});
};
