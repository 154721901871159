// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { type SortList, SortListItem } from './types/dictionaries.type';
import { sortList } from './sort-list';

export enum FactorSortList {
	LABEL = 'label',
}

export interface FactorSortListItem extends Omit<SortListItem, 'id' | 'code'> {
	code: SortList | FactorSortList;
	id: `${IdNamespace.SortList}_${string}` | `${IdNamespace.FactorSortList}_${string}`;
}

export const factorSortList: Map<
	SortList | `${SortList}` | FactorSortList | `${FactorSortList}`,
	FactorSortListItem
> = Map<FactorSortList, FactorSortListItem>({
	[FactorSortList.LABEL]: {
		code: FactorSortList.LABEL,
		id: 'factsoli_2gNWik1n2SyISskx7Lwg9FkrDJ2',
		order: 3,
		// label: i18n.t('factorSortList.label', { ns: 'dictionaries' }),
		label: 'Label',
		directionIcons: {
			asc: 'sort-alpha-up-alt',
			desc: 'sort-alpha-down-alt',
		},
		function: {
			asc: {
				label: 'asc',
				_createdDate: 'asc',
			},
			desc: {
				label: 'desc',
				_createdDate: 'desc',
			},
		},
	},
}).merge(sortList);
