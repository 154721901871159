import { mainTheme } from '@indly/themes/main.theme';
import { Box, Button, TextField } from '@mui/material';
import { t } from 'i18next';
import { isString } from 'lodash';
import type { ComponentType, ReactElement } from 'react';
import type {
	NormalisedConfig,
	SignInUpEmailFormProps,
} from 'supertokens-auth-react/lib/build/recipe/passwordless/types';
import type { RecipeInterface } from 'supertokens-web-js/recipe/passwordless';
import { useImmer, type Updater } from 'use-immer';

const PasswordlessEmailForm = ({
	DefaultComponent,
	...props
}: {
	DefaultComponent: ComponentType<SignInUpEmailFormProps>;
	clearError: () => void;
	onError: (error: string) => void;
	error: string | undefined;
	recipeImplementation: RecipeInterface;
	config: NormalisedConfig;
	onSuccess?: () => void;
}): ReactElement | null => {
	// console.debug('[DEBUG] PasswordlessEmailForm props:', props);

	const emailUrlParam: string | null = new URLSearchParams(window.location.search).get('email');
	// console.debug('[DEBUG] emailUrlParam:', emailUrlParam);

	const [email, setEmail]: [string, Updater<string>] = useImmer(
		isString(emailUrlParam) ? decodeURIComponent(emailUrlParam) : ''
	);

	const login = () => {
		props.recipeImplementation
			.createCode({
				email,
				userContext: {},
			})
			.catch((err) => {
				const reader = err.body?.getReader();

				if (reader) {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					reader.read().then(({ value }: { value: BufferSource }) => {
						// const decoded = new TextDecoder('utf-8').decode(value);
						// const json = JSON.parse(decoded);

						// json.errors[0].constraints.isEmail
						props.onError('valid_email');
					});
				}
			});
	};

	return (
		<Box>
			<TextField
				fullWidth
				variant='outlined'
				size='small'
				value={email}
				onChange={(e) => {
					setEmail(e.target.value);
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						login();
					}
				}}
				onPaste={(e) => {
					e.preventDefault();
					setEmail(e.clipboardData.getData('text/plain').trim());
				}}
				placeholder={t('please_enter_email') ?? ''}
				sx={{
					marginBottom: mainTheme.spacing(),
				}}
			/>
			<Button
				variant='contained'
				fullWidth
				onClick={login}
				sx={{
					borderRadius: mainTheme.shape.smallRadius,
				}}
			>
				{t('login')}
			</Button>
		</Box>
	);
};

export default PasswordlessEmailForm;
