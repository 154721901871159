// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { type SortList, SortListItem } from './types/dictionaries.type';
import { sortList } from './sort-list';

export enum JurisdictionSortList {
	COUNTRY = 'country',
}

export interface JurisdictionSortListItem extends Omit<SortListItem, 'id' | 'code'> {
	code: SortList | JurisdictionSortList;
	id: `${IdNamespace.SortList}_${string}` | `${IdNamespace.JurisdictionSortList}_${string}`;
}

export const jurisdictionSortList: Map<
	SortList | `${SortList}` | JurisdictionSortList | `${JurisdictionSortList}`,
	JurisdictionSortListItem
> = Map<JurisdictionSortList, JurisdictionSortListItem>({
	[JurisdictionSortList.COUNTRY]: {
		code: JurisdictionSortList.COUNTRY,
		id: 'jursoli_2VvVB9CAMiHXF1euTRkMsKivEgQ',
		order: 3,
		// label: i18n.t('jurisdictionSortList.country', { ns: 'dictionaries' }),
		label: 'Country',
		directionIcons: {
			asc: 'sort-alpha-up-alt',
			desc: 'sort-alpha-down-alt',
		},
		function: {
			asc: {
				country: 'asc',
				_createdDate: 'asc',
			},
			desc: {
				country: 'desc',
				_createdDate: 'desc',
			},
		},
	},
}).merge(sortList);
