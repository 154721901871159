// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { type SortList, SortListItem } from './types/dictionaries.type';
import { sortList } from './sort-list';

export enum SmsTemplatesSortList {
	NAME = 'name',
}

export interface SmsTemplateSortListItem extends Omit<SortListItem, 'id' | 'code'> {
	code: SortList | SmsTemplatesSortList;
	id: `${IdNamespace.SortList}_${string}` | `${IdNamespace.SmsTemplateSortList}_${string}`;
}

export const smsTemplatesSortList: Map<
	SortList | `${SortList}` | SmsTemplatesSortList | `${SmsTemplatesSortList}`,
	SmsTemplateSortListItem
> = Map<SortList | SmsTemplatesSortList, SmsTemplateSortListItem>({
	[SmsTemplatesSortList.NAME]: {
		code: SmsTemplatesSortList.NAME,
		id: 'smstemsoli_2GvG4MTzyf8MRLvkNCy3Zyilljc',
		order: 3,
		// label: i18n.t('smsTemplatesSortList.name', { ns: 'dictionaries' }),
		label: 'Name',
		directionIcons: {
			asc: 'sort-alpha-up-alt',
			desc: 'sort-alpha-down-alt',
		},
		function: {
			asc: {
				name: 'asc',
				_createdDate: 'asc',
			},
			desc: {
				name: 'desc',
				_createdDate: 'desc',
			},
		},
	},
}).merge(sortList);
