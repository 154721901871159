// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'sessions';
const repository = 'Session';

export enum SessionScopes {
	'Read' = 'sessions:read',
}

const namespace = 'sesssco';

export const sessionScopes: AbilityScopeMap<SessionScopes> = Map<
	SessionScopes,
	AbilityScopeItem<SessionScopes>
>({
	[SessionScopes.Read]: {
		code: SessionScopes.Read,
		id: `${namespace}_2NIYWZ9LDnpTjYrObbJKyX8J3df`,
		order: 0,
		icon: 'circle-user',
		label: 'Session Update',
		collection,
		repository,
	},
});
