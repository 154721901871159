export const AUTH_RESPONSE_SUCCESS_STATUSES: number[] = [200, 201];

export const USER_INFO_AUTH_RESPONSE_UNAUTHORISED_STATUSES: number[] = [401, 403];

export const API_MAGIC_LINK_VERIFY_PATH: string = '/auth/signinup/code/consume';

export const API_SESSION_USER_INFO_PATH: string = '/user/info';

export const API_SUCCESS_ALLOWED_PASSTHROUGH_HEADERS: string[] = [
	'access-control-allow-credentials',
	'access-control-allow-origin',
	'access-control-expose-headers',
	'front-token',
	'id-refresh-token',
	'set-cookie',
];

export const SUPERTOKENS_REDIRECT_URL_PARAM: string = 'redirectToPath';

export const MAGIC_LINK_PATH: string = '/auth/verify';

export const LOGIN_PAGE_PATH: string = '/auth';

export const DEFAULT_PAGE_PATH: string = '/profile/actionItems';
