import { appAbilityRules, type AppAbility, type SubjectAbility } from '@abilities/app.ability';
import { systemLanguages, type SystemLanguageItem } from '@dictionaries/system-languages';
import type { UserType } from '@dictionaries/user-types';
import type {
	BaseUserCompanyWithAuthEmbeds,
	Company,
	CompanyWhitelabel,
	JurisdictionCountry,
	JurisdictionCountryState,
	Role,
	UserTokens,
	UserWithAuthEmbedsWithoutCompanies,
} from '@openapi/models';
import type {
	WritableCompany,
	WritableCompanyKeys,
} from 'api/modules/companies/types/company.type';
import { AppConfig } from 'configs/app.config';
import produce from 'immer';
import { setTag as setExceptionTag } from 'libs/whoops';
import { isNil, merge, pick, setPath } from 'remeda';
import type { PartialDeep, Primitive } from 'type-fest';
import { createStore } from 'zustand/vanilla';
import type { RootStoreType } from './types/stores.type';

// import { SystemLocaleSchemaKey } from '@dictionaries/languages';
// import { getCompanyAllEnabledAllowedLanguageKeys } from 'api/helpers/modules/companies/get-company-all-enabled-allowed-lannguage-keys.company.helper';

export const vanillaStore = createStore<RootStoreType>((set) => ({
	user: null,
	setUser: (user: UserWithAuthEmbedsWithoutCompanies | null) =>
		set(
			produce((state: RootStoreType) => {
				state.user = user;
			})
		),
	mergeUser: (user: UserWithAuthEmbedsWithoutCompanies) =>
		set(
			produce((state: RootStoreType) => {
				if (!isNil(state.user)) {
					const values = pick<
						UserWithAuthEmbedsWithoutCompanies,
						keyof UserWithAuthEmbedsWithoutCompanies
					>(user, ['name', 'settings', 'role', 'email']);

					state.user = merge<
						UserWithAuthEmbedsWithoutCompanies,
						UserWithAuthEmbedsWithoutCompanies
					>(state.user, values);
				}
			})
		),
	userCompany: null,
	setUserCompany: (userCompany: string | null) =>
		set(
			produce((state: RootStoreType) => {
				state.userCompany = userCompany;
			})
		),
	userType: null,
	setUserType: (userType: UserType | null) =>
		set(
			produce((state: RootStoreType) => {
				state.userType = userType;
			})
		),

	company: null,
	// // #NOTE: Cached to only re-filter when the root-store company is changed
	// companyEnabledLocales: [SystemLocaleSchemaKey.EN],
	setCompany: (currentCompany: PartialDeep<Company> | null) =>
		set(
			produce((state: RootStoreType) => {
				state.company = currentCompany;
				// state.companyEnabledLocales = getCompanyAllEnabledAllowedLanguageKeys(currentCompany);
			})
		),
	setPathCompany: (path: WritableCompanyKeys, value: Primitive) =>
		set(
			produce((state: RootStoreType) => {
				if (!isNil(state.company)) {
					// @ts-expect-error: @TODO: Fix type
					setPath<WritableCompany, WritableCompanyKeys>(state.company, path, value);
				}
			})
		),
	mergeCompany: (company: PartialDeep<Company>) =>
		set(
			produce((state: RootStoreType) => {
				if (!isNil(state.company)) {
					state.company = merge<PartialDeep<Company>, PartialDeep<Company>>(
						state.company,
						company
					);
				}
			})
		),
	userCompanies: null,
	setUserCompanies: (userCompanies: Array<BaseUserCompanyWithAuthEmbeds> | null) =>
		set(
			produce((state: RootStoreType) => {
				state.userCompanies = userCompanies;
			})
		),

	systemIsAdministratorRoles: [],
	setSystemIsAdministratorRoles: (systemIsAdministratorRoles: Array<PartialDeep<Role>>) =>
		set(
			produce((state: RootStoreType) => {
				state.systemIsAdministratorRoles = systemIsAdministratorRoles;
			})
		),

	role: null,
	ability: null,
	setRole: (currentRole: PartialDeep<Role> | null) =>
		set(
			produce((state: RootStoreType) => {
				// console.log('[DEBUG VanillaStore] setRole() role:', currentRole);

				// Construct ability Object
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { ability }: { ability: AppAbility } = appAbilityRules({
					label: currentRole?.label,
					description: currentRole?.description,
					abilities: (currentRole?.abilities ?? []) as unknown as Array<SubjectAbility>,
				});
				// console.debug('[DEBUG VanillaStore] ability.rules:', JSON.stringify(ability.rules));

				state.ability = ability;
				state.role = currentRole;
			})
		),

	isUserSettingsOpen: false,
	setIsUserSettingsOpen: (newVal: boolean) =>
		set(
			produce((state: RootStoreType) => {
				state.isUserSettingsOpen = newVal;
			})
		),
	locale: systemLanguages.get(AppConfig.settings.locale) as SystemLanguageItem,
	setLocale: (newLocale: SystemLanguageItem) =>
		set(
			produce((state: RootStoreType) => {
				state.locale = newLocale;
				setExceptionTag('locale', newLocale.code);
			})
		),
	timezone: AppConfig.settings.timezone,
	setTimezone: (newTimeZone: string) =>
		set(
			produce((state: RootStoreType) => {
				state.timezone = newTimeZone;
			})
		),
	setupCompleteOpen: false,
	setSetupCompleteOpen: (newVal: boolean) =>
		set(
			produce((state: RootStoreType) => {
				state.setupCompleteOpen = newVal;
			})
		),
	engagementLabel: '',
	setEngagementLabel: (label: string) =>
		set(
			produce((state: RootStoreType) => {
				state.engagementLabel = label;
			})
		),
	companyLabel: '',
	setCompanyLabel: (label: string) =>
		set(
			produce((state: RootStoreType) => {
				state.companyLabel = label;
			})
		),
	tokens: {},
	setTokens: (newTokens: PartialDeep<UserTokens>) =>
		set(
			produce((state: RootStoreType) => {
				state.tokens = newTokens;
			})
		),
	whitelistedJurisdictions: [],
	setWhitelistedJurisdictions: (
		whitelistedJurisdictions: Array<
			PartialDeep<JurisdictionCountry> | PartialDeep<JurisdictionCountryState>
		>
	) =>
		set(
			produce((state: RootStoreType) => {
				state.whitelistedJurisdictions = whitelistedJurisdictions;
			})
		),
	isImportModalOpen: false,
	setIsImportModalOpen: (newVal: boolean) =>
		set(
			produce((state: RootStoreType) => {
				state.isImportModalOpen = newVal;
			})
		),
	whitelabelSettings: { isEnabled: false },
	setWhitelabelSettings: (
		whitelabelSettings: Pick<
			CompanyWhitelabel,
			'isEnabled' | 'name' | '_rectangleLogoUrl' | '_squareLogoUrl'
		> | null
	) => {
		set(
			produce((state: RootStoreType) => {
				if (isNil(whitelabelSettings)) {
					state.whitelabelSettings = { isEnabled: false };
					return;
				}

				state.whitelabelSettings = whitelabelSettings;
			})
		);
	},
}));
