/* eslint-disable no-console */
import routesMetadata from 'routes/configs/routes-metadata.config';
import SuperTokens from 'supertokens-website';

export const isAuthSessionExists = async (): Promise<boolean> => {
	const loggedIn: boolean = await SuperTokens.doesSessionExist();

	console.debug(
		`[DEBUG] isAuthSessionExists() loggedIn:`,
		JSON.stringify(loggedIn),
		new Error().stack.split('at ')[1].trim()
	);

	return loggedIn;
};

export const isPathPublic = (): boolean => {
	// console.info('[INFO] isPathPublic() path:', window.location.pathname.replace(/^\/+/, ''));

	const path: string = window.location.pathname.replace(/^\/+/, '');
	return routesMetadata?.[path]?.isPublic ?? false;
};
