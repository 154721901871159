// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { GLOBAL_ICONS } from '../libs/icons/global.icon';

export enum DefaultManagedCompanyType {
	CLIENT = 'client',
	OPTIONAL_VENDOR = 'optional-vendor',
	ALWAYS_ASK = 'always-ask',
}

export interface DefaultManagedCompanyTypeItem extends BaseDictionary {
	code: DefaultManagedCompanyType;
	id: `${IdNamespace.DefaultManagedCompanyType}_${string}`;
	icon: string;
	label: string;
	description: string;
}

export const defaultManagedCompanyTypes = Map<
	DefaultManagedCompanyType | `${DefaultManagedCompanyType}`,
	DefaultManagedCompanyTypeItem
>({
	[DefaultManagedCompanyType.CLIENT]: {
		code: DefaultManagedCompanyType.CLIENT,
		id: 'defmcty_2XxpWPzRNnoJHeR4kHgf0PSv6LO',
		// label: i18n.t('DefaultManagedCompanyType.CLIENT', { ns: 'dictionaries' }),
		icon: GLOBAL_ICONS.client,
		label: 'Client',
		description: 'Automatically assign all new Engagements to the Client company',
	},
	[DefaultManagedCompanyType.OPTIONAL_VENDOR]: {
		code: DefaultManagedCompanyType.OPTIONAL_VENDOR,
		id: 'defmcty_2XxpWP6rxsiZi2K1Z3AojT9eVJH',
		// label: i18n.t('DefaultManagedCompanyType.OPTIONAL_VENDOR', { ns: 'dictionaries' }),
		icon: GLOBAL_ICONS.vendor,
		label: 'Optional Vendor',
		description:
			'(If selected) Automatically map all Engagements to the selected Vendor company',
	},
	[DefaultManagedCompanyType.ALWAYS_ASK]: {
		code: DefaultManagedCompanyType.ALWAYS_ASK,
		id: 'defmcty_2XxpWQ9ENoXutamGCRHlLhfG1SK',
		// label: i18n.t('DefaultManagedCompanyType.ALWAYS_ASK', { ns: 'dictionaries' }),
		icon: 'square-question',
		label: 'Always Ask',
		description: '(If Vendor is selected) Always ask the managed company on each Engagement',
	},
});
