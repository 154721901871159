// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum TimeFormat {
	TWELVE_HOUR = '12-hour',
	TWENTY_FOUR_HOUR = '24-hour',
}

export interface TimeFormatItem extends BaseDictionary {
	code: TimeFormat;
	id: `${IdNamespace.TimeFormat}_${string}`;
	order: number;
	label: string;
	format: string;
}

export const timeFormats = Map<TimeFormat | `${TimeFormat}`, TimeFormatItem>({
	[TimeFormat.TWELVE_HOUR]: {
		code: TimeFormat.TWELVE_HOUR,
		id: 'timefor_2FeQHJ04KrSkbAIqyqhadosHKAu',
		order: 1,
		// label: i18n.t('12_hour', { ns: 'app' }),
		label: '12 Hour',
		format: 'hh:mm a',
	},
	[TimeFormat.TWENTY_FOUR_HOUR]: {
		code: TimeFormat.TWENTY_FOUR_HOUR,
		id: 'timefor_2FeQHI13lZFPVns0ooaFuBN43IF',
		order: 2,
		// label: i18n.t('24_hour', { ns: 'app' }),
		label: '24 Hour',
		format: 'HH:mm',
	},
});
