// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'requestDevices';
const repository = 'requestDevice';

export enum RequestDeviceScopes {
	'Read' = 'requestDevices:read',
}

const namespace = IdNamespace.RequestDeviceScope;

export const requestDeviceScopes: AbilityScopeMap<RequestDeviceScopes> = Map<
	RequestDeviceScopes,
	AbilityScopeItem<RequestDeviceScopes>
>({
	[RequestDeviceScopes.Read]: {
		code: RequestDeviceScopes.Read,
		id: `rqstdvcsco_2NGD4fOPFjsE9tUEQVeXzecpA6Y`,
		order: 0,
		icon: 'circle-user',
		label: 'Device Update',
		collection,
		repository,
	},
});
