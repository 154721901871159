import { RouteMetadata } from 'routes/types/routes.type';

export const SettingsRoutesMetadata: Record<string, RouteMetadata> = {
	settings: {
		isSettingsPage: true,
	},
	administrators: {
		isAdministratorPage: true,
	},
	hiringManagers: {
		isHiringManagerPage: true,
	},
};
