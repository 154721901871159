// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'surveyAnswers';
const repository = 'SurveyAnswer';

export enum SurveyAnswerScopes {
	'Create' = 'surveyAnswers:create',
	'Read' = 'surveyAnswers:read',
	'Update' = 'surveyAnswers:update',
	'Delete' = 'surveyAnswers:delete',
	'Manage' = 'surveyAnswers:manage',
	'SetExplanation' = 'surveyAnswers:setExplanation',
	'ReadExplanation' = 'surveyAnswers:readExplanation',
}

const NS: string = IdNamespace.SurveyAnswerScope;

export const surveyAnswerScopes: AbilityScopeMap<SurveyAnswerScopes> = Map<
	SurveyAnswerScopes,
	AbilityScopeItem<SurveyAnswerScopes>
>({
	[SurveyAnswerScopes.Create]: {
		code: SurveyAnswerScopes.Create,
		id: `${NS}_2MObvxwtijfGCGZLhi3qIwCQBw9`,
		order: 0,
		icon: 'circle-user',
		label: 'SurveyAnswer Create',
		collection,
		repository,
	},
	[SurveyAnswerScopes.Read]: {
		code: SurveyAnswerScopes.Read,
		id: `${NS}_2MObwmTt0oVv3t5u1gelEnqWvuB`,
		order: 1,
		icon: 'circle-user',
		label: 'SurveyAnswer Read',
		collection,
		repository,
	},
	[SurveyAnswerScopes.Update]: {
		code: SurveyAnswerScopes.Update,
		id: `${NS}_2MOby4ND581inO5rVr6L5xejiNZ`,
		order: 2,
		icon: 'circle-user',
		label: 'SurveyAnswer Update',
		collection,
		repository,
	},
	[SurveyAnswerScopes.Delete]: {
		code: SurveyAnswerScopes.Delete,
		id: `${NS}_2MObyX6QLKbV7U8fgBOmUzdwi31`,
		order: 3,
		icon: 'circle-user',
		label: 'SurveyAnswer Delete',
		collection,
		repository,
	},
	[SurveyAnswerScopes.Manage]: {
		code: SurveyAnswerScopes.Manage,
		id: `${NS}_2MOburWAAIvi7Anug3j9XLiUjpA`,
		order: 4,
		icon: 'circle-user',
		label: 'SurveyAnswer Manage',
		alias: [
			SurveyAnswerScopes.Create,
			SurveyAnswerScopes.Read,
			SurveyAnswerScopes.Update,
			SurveyAnswerScopes.Delete,
		],
		collection,
		repository,
	},
	[SurveyAnswerScopes.SetExplanation]: {
		code: SurveyAnswerScopes.SetExplanation,
		id: `${NS}_2UPOuOmDSCvMgDdCPHGiOXY3GnH`,
		order: 5,
		icon: 'circle-user',
		label: 'SurveyAnswer set explanation',
		collection,
		repository,
	},
	[SurveyAnswerScopes.ReadExplanation]: {
		code: SurveyAnswerScopes.ReadExplanation,
		id: `${NS}_2UPOuNRkxcSKLdh49RtYmO2Y9D5`,
		order: 6,
		icon: 'circle-user',
		label: 'SurveyAnswer read explanation',
		collection,
		repository,
	},
});
