import { SystemRoutesMetadata } from 'features/system/routes/system.routes-metadata';
import { AdministratorsRoutesMetadata } from 'features/administrators/routes/administrators.routes-metadata';
import { BillingRoutesMetadata } from 'features/billing/routes/billing.routes-metadata';
import { ClientsRoutesMetadata } from 'features/clients/routes/clients.routes-metadata';
import { CustomizationsRoutesMetadata } from 'features/customizations/routes/customizations.routes-metadata';
import { EngagementTemplatesRoutesMetadata } from 'features/engagementTemplates/routes/engagement-templates.routes-metadata';
import { FilesRoutesMetadata } from 'features/files/routes/files.routes-metadata';
import { GettingStartedRoutesMetadata } from 'features/gettingStarted/routes/getting-started.routes-metadata';
import { IntegrationsRoutesMetadata } from 'features/integrations/routes/integrations.routes-metadata';
import { AuthRoutesMetadata } from 'features/login/routes/auth.routes-metadata';
import { MarketplaceRoutesMetadata } from 'features/marketplace/routes/marketplace.routes-metadata';
import { OnboardingRoutesMetadata } from 'features/onboarding/routes/onboarding.routes-metadata';
import { ReportsRoutesMetadata } from 'features/reports/routes/reports.routes-metadata';
import { SettingsRoutesMetadata } from 'features/settings/routes/settings.routes-metadata';
import { WorkersRoutesMetadata } from 'features/workers/routes/workers.routes-metadata';
import { RouteMetadata } from '../types/routes.type';

const routesMetadata: Record<string, RouteMetadata> = {
	...AuthRoutesMetadata,
	...OnboardingRoutesMetadata,
	...WorkersRoutesMetadata,
	...AdministratorsRoutesMetadata,
	...CustomizationsRoutesMetadata,
	...ReportsRoutesMetadata,
	...MarketplaceRoutesMetadata,
	...IntegrationsRoutesMetadata,
	...BillingRoutesMetadata,
	...SettingsRoutesMetadata,
	...ClientsRoutesMetadata,
	...FilesRoutesMetadata,
	...EngagementTemplatesRoutesMetadata,
	...GettingStartedRoutesMetadata,
	...SystemRoutesMetadata,
};

export default routesMetadata;
