// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary, Hex } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum QueryFunctionDuration {
	TWENTY_FOUR_HOURS = 'twenty-four-hours',
	THREE_DAYS = 'three-days',
	FIVE_DAYS = 'five-days',
	ONE_WEEK = 'one-week',
}
export interface QueryFunctionDurationItem extends BaseDictionary {
	code: QueryFunctionDuration;
	id: `${IdNamespace.QueryFunctionDuration}_${string}`;
	order: number;
	label: string;
	description: string;
	duration: number; // (in seconds)
}

export const queryFunctionDurations = Map<
	QueryFunctionDuration | `${QueryFunctionDuration}`,
	QueryFunctionDurationItem
>({
	[QueryFunctionDuration.TWENTY_FOUR_HOURS]: {
		code: QueryFunctionDuration.TWENTY_FOUR_HOURS,
		id: `qryfundura_2V7yuAZ8uxpPN4LZdjddF4bMw3a`,
		order: 0,
		label: '24 hours',
		description: '',
		duration: 86_400,
	},
	[QueryFunctionDuration.THREE_DAYS]: {
		code: QueryFunctionDuration.THREE_DAYS,
		id: `qryfundura_2V7yu9qGbZ930BduVNfeRYjIEU0`,
		order: 1,
		label: '3 Days',
		description: '',
		duration: 259_200,
	},
	[QueryFunctionDuration.FIVE_DAYS]: {
		code: QueryFunctionDuration.FIVE_DAYS,
		id: `qryfundura_2V7yu58wTtSfh13NHCD4FspQULN`,
		order: 2,
		label: '5 Days',
		description: '',
		duration: 432_000,
	},
	[QueryFunctionDuration.ONE_WEEK]: {
		code: QueryFunctionDuration.ONE_WEEK,
		id: `qryfundura_2V7yu996lTsWzvYgFZ3joct1nrb`,
		order: 3,
		label: '1 week',
		description: '',
		duration: 604_800,
	},
});
