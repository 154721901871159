// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum AccessTokenExpirationType {
	THIRTY_DAYS = 'thirty-days',
	SIXTY_DAYS = 'sixty-days',
	NINETY_DAYS = 'ninety-days',
	CUSTOM = 'custom',
	NEVER = 'never',
}

export interface AccessTokenExpirationTypeItem extends BaseDictionary {
	code: AccessTokenExpirationType;
	id: `${IdNamespace.AccessTokenExpirationType}_${string}`;
	order: number;
	icon: string;
	label: string;
}

export const accessTokenExpirationTypes = Map<
	AccessTokenExpirationType | `${AccessTokenExpirationType}`,
	AccessTokenExpirationTypeItem
>({
	[AccessTokenExpirationType.THIRTY_DAYS]: {
		code: AccessTokenExpirationType.THIRTY_DAYS,
		id: 'atoety_2FeFRG2aR6ZowfXQ5wB2V8ddaZL',
		order: 0,
		icon: 'clock-one',
		// label: i18n.t('thirtyDays', { ns: 'app' }),
		label: '30 Days',
	},
	[AccessTokenExpirationType.SIXTY_DAYS]: {
		code: AccessTokenExpirationType.SIXTY_DAYS,
		id: 'atoety_2FeFRFNHohFOHMj5wcXmTlx6r2z',
		order: 1,
		icon: 'clock-three',
		// label: i18n.t('sixtyDays', { ns: 'app' }),
		label: '60 Days',
	},
	[AccessTokenExpirationType.NINETY_DAYS]: {
		code: AccessTokenExpirationType.NINETY_DAYS,
		id: 'atoety_2FeFRFnS3bZg3LPKFI1i01i44kP',
		order: 2,
		icon: 'clock-seven',
		// label: i18n.t('ninetyDays', { ns: 'app' }),
		label: '90 Days',
	},
	[AccessTokenExpirationType.CUSTOM]: {
		code: AccessTokenExpirationType.CUSTOM,
		id: 'atoety_2FeFRDA3YCpLOIxga376qjy0Sia',
		order: 3,
		icon: 'calendar-clock',
		// label: i18n.t('custom', { ns: 'app' }),
		label: 'Custom',
	},
	[AccessTokenExpirationType.NEVER]: {
		code: AccessTokenExpirationType.NEVER,
		id: 'atoety_2FeFRFugWdOqrzwkUwRmoeeB5Tb',
		order: 4,
		icon: 'calendar-exclamation',
		// label: i18n.t('never', { ns: 'app' }),
		label: 'Never',
	},
});
