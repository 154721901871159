// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'automations';
const repository = 'Automation';

export enum AutomationScopes {
	'Create' = 'automations:create',
	'Read' = 'automations:read',
	'Update' = 'automations:update',
	'Delete' = 'automations:delete',
	'Manage' = 'automations:manage',
}

const namespace = IdNamespace.AutomationScope;

export const automationScopes: AbilityScopeMap<AutomationScopes> = Map<
	AutomationScopes,
	AbilityScopeItem<AutomationScopes>
>({
	[AutomationScopes.Create]: {
		code: AutomationScopes.Create,
		id: `${namespace}_2IdekfUkpf9jUefOTpF2AgwZu6C`,
		order: 0,
		icon: 'circle-user',
		label: 'Automation Create',
		collection,
		repository,
	},
	[AutomationScopes.Read]: {
		code: AutomationScopes.Read,
		id: `${namespace}_2IdekcZ9S6imxqn2KiF6OTqogat`,
		order: 1,
		icon: 'circle-user',
		label: 'Automation Read',
		collection,
		repository,
	},
	[AutomationScopes.Update]: {
		code: AutomationScopes.Update,
		id: `${namespace}_2IdekdSL8kBLl9YXCPQHv9lAuKm`,
		order: 2,
		icon: 'circle-user',
		label: 'Automation Update',
		collection,
		repository,
	},
	[AutomationScopes.Delete]: {
		code: AutomationScopes.Delete,
		id: `${namespace}_2IdekfDKOK85ZA4F5NbECytNPQy`,
		order: 3,
		icon: 'circle-user',
		label: 'Automation Delete',
		collection,
		repository,
	},
	[AutomationScopes.Manage]: {
		code: AutomationScopes.Manage,
		id: `${namespace}_2IdekaguyuWVxvMfY1hoa16Ke20`,
		order: 4,
		icon: 'circle-user',
		label: 'Automation Manage',
		alias: [
			AutomationScopes.Create,
			AutomationScopes.Read,
			AutomationScopes.Update,
			AutomationScopes.Delete,
		],
		collection,
		repository,
	},
});
