// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'users';
const repository = 'Worker';

export enum WorkerScopes {
	'Create' = 'workers:create',
	'Read' = 'workers:read',
	'Update' = 'workers:update',
	'Delete' = 'workers:delete',
	'Manage' = 'workers:manage',
	'Import' = 'workers:import',
	'UpdateDeleted' = 'workers:updateDeleted',
}

const NS: string = IdNamespace.WorkerScope;

export const workerScopes: AbilityScopeMap<WorkerScopes> = Map<
	WorkerScopes,
	AbilityScopeItem<WorkerScopes>
>({
	[WorkerScopes.Create]: {
		code: WorkerScopes.Create,
		id: `${NS}_2FXsNrIDZA2O0vMqrAdzYMuD8RJ`,
		order: 0,
		icon: 'circle-user',
		label: 'Worker Create',
		collection,
		repository,
		// tiers: ['bronze', 'silver', 'gold']
	},
	[WorkerScopes.Read]: {
		code: WorkerScopes.Read,
		id: `${NS}_2FXsPBFDNeTTgzb7fbwOGwdoOtP`,
		order: 1,
		icon: 'circle-user',
		label: 'Worker Read',
		collection,
		repository,
	},
	[WorkerScopes.Update]: {
		code: WorkerScopes.Update,
		id: `${NS}_2FXsRWgIxMQOK1seO5LkpT6t96j`,
		order: 2,
		icon: 'circle-user',
		label: 'Worker Update',
		collection,
		repository,
	},
	[WorkerScopes.Delete]: {
		code: WorkerScopes.Delete,
		id: `${NS}_2FXsUns6GiA3mqqIMkhoOsejZoh`,
		order: 3,
		icon: 'circle-user',
		label: 'Worker Delete',
		collection,
		repository,
	},
	[WorkerScopes.Manage]: {
		code: WorkerScopes.Manage,
		id: `${NS}_2FXsMHofJq2DhKRc9WJqykFABfT`,
		order: 4,
		icon: 'circle-user',
		label: 'Worker Manage',
		alias: [WorkerScopes.Create, WorkerScopes.Read, WorkerScopes.Update, WorkerScopes.Delete],
		collection,
		repository,
		// tiers: ['bronze', 'silver', 'gold']
	},
	[WorkerScopes.Import]: {
		code: WorkerScopes.Import,
		id: `${NS}_2cy3bGy2lYIn5rEp4EmuZMoo0VW`,
		order: 5,
		icon: 'circle-user',
		label: 'Worker Import',
		collection,
		repository,
	},
	[WorkerScopes.UpdateDeleted]: {
		code: WorkerScopes.UpdateDeleted,
		id: `${NS}_2fKYPFDGDF98VOpmSeEYIxez8QP`,
		order: 6,
		icon: 'circle-user',
		label: 'Worker Update Deleted',
		collection,
		repository,
	},
});
