// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'companies';
const repository = 'Vendor';

export enum VendorScopes {
	'Create' = 'vendors:create',
	'Read' = 'vendors:read',
	'Update' = 'vendors:update',
	'Delete' = 'vendors:delete',
	'Manage' = 'vendors:manage',
	'Import' = 'vendors:import',
	'EditDueDateOffset' = 'vendors:editDueDateOffset',
}

const namespace = 'comsco';

export const vendorScopes: AbilityScopeMap<VendorScopes> = Map<
	VendorScopes,
	AbilityScopeItem<VendorScopes>
>({
	[VendorScopes.Create]: {
		code: VendorScopes.Create,
		id: `${namespace}_2FXqjAdkqt6648Jw5npN1PXziNs`,
		order: 0,
		icon: 'circle-user',
		label: 'Vendor Create',
		collection,
		repository,
	},
	[VendorScopes.Read]: {
		code: VendorScopes.Read,
		id: `${namespace}_2FXqkPlQ3nx1zJlV0BHUS09HEGC`,
		order: 1,
		icon: 'circle-user',
		label: 'Vendor Read',
		collection,
		repository,
	},
	[VendorScopes.Update]: {
		code: VendorScopes.Update,
		id: `${namespace}_2FXqleb0IL7jlkcBhToGhXa5A54`,
		order: 2,
		icon: 'circle-user',
		label: 'Vendor Update',
		collection,
		repository,
	},
	[VendorScopes.Delete]: {
		code: VendorScopes.Delete,
		id: `${namespace}_2FXqnGed5UXbnMzlpqnEkK7ecdL`,
		order: 3,
		icon: 'circle-user',
		label: 'Vendor Delete',
		collection,
		repository,
	},
	[VendorScopes.Manage]: {
		code: VendorScopes.Manage,
		id: `${namespace}_2FXqhoIxv2YmzXnnZUIboii0aqj`,
		order: 4,
		icon: 'circle-user',
		label: 'Vendor Manage',
		alias: [VendorScopes.Create, VendorScopes.Read, VendorScopes.Update, VendorScopes.Delete],
		collection,
		repository,
	},
	[VendorScopes.Import]: {
		code: VendorScopes.Import,
		id: `${namespace}_2dP2Agi5MNajWSI9fxEI8u1ksC4`,
		order: 5,
		icon: 'circle-user',
		label: 'Vendor Import',
		collection,
		repository,
	},
	[VendorScopes.EditDueDateOffset]: {
		code: VendorScopes.EditDueDateOffset,
		id: `${namespace}_2WBnOSkD3WuojjO0rwYG47KOdSP`,
		order: 6,
		icon: 'circle-user',
		label: 'Vendor Edit Due Date Offset',
		collection,
		repository,
	},
});
