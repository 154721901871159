// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { BaseDictionary } from './types/dictionaries.type';

export enum PartnerType {
	REFERRER = 'referrer',
	RESELLER = 'reseller',
}

export interface PartnerTypeItem extends BaseDictionary {
	code: PartnerType;
	id: `${IdNamespace.PartnerType}_${string}`;
	order: number;
	icon: string;
	label: string;
	description: string;
}

export const partnerTypes = Map<PartnerType | `${PartnerType}`, PartnerTypeItem>({
	[PartnerType.REFERRER]: {
		code: PartnerType.REFERRER,
		id: 'ptnrty_2gB7dJgDRMWyVmwiptP8P4esrq8',
		order: 0,
		icon: 'pen-ruler',
		// label: i18n.t('partnerTypes.label.referrer', { ns: 'dictionaries' }),
		label: 'Referrer',
		// description: i18n.t('partnerTypes.description.referrer', { ns: 'dictionaries' }),
		description:
			'A person or company with an agreement to refer customers to Independently (aka. "Affiliate")',
	},
	[PartnerType.RESELLER]: {
		code: PartnerType.RESELLER,
		id: 'ptnrty_2gB7rG9eRAavw2LypbKTEpQJdRr',
		order: 1,
		icon: 'pen-ruler',
		// label: i18n.t('partnerTypes.label.reseller', { ns: 'dictionaries' }),
		label: 'Reseller',
		// description: i18n.t('partnerTypes.description.reseller', { ns: 'dictionaries' }),
		description:
			'A person or company with an agreement to resell Independently products and services to their customers',
	},
});
