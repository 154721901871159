// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'tests';
const repository = 'Test';

export enum TestScopes {
	'Create' = 'tests:create',
	'Read' = 'tests:read',
	'Update' = 'tests:update',
	'Delete' = 'tests:delete',
	'Manage' = 'tests:manage',
}

const namespace = 'testsco';

export const testScopes: AbilityScopeMap<TestScopes> = Map<
	TestScopes,
	AbilityScopeItem<TestScopes>
>({
	[TestScopes.Create]: {
		code: TestScopes.Create,
		id: `${namespace}_2FXqJlsGZk9VVLjHeYTn5rcMztK`,
		order: 0,
		icon: 'circle-user',
		label: 'Test Create',
		collection,
		repository,
	},
	[TestScopes.Read]: {
		code: TestScopes.Read,
		id: `${namespace}_2FXqLd3P7dXq8dV2Yt00rNn8Gfh`,
		order: 1,
		icon: 'circle-user',
		label: 'Test Read',
		collection,
		repository,
	},
	[TestScopes.Update]: {
		code: TestScopes.Update,
		id: `${namespace}_2FXqMx477nugN1UVQyHtpnBkLEh`,
		order: 2,
		icon: 'circle-user',
		label: 'Test Update',
		collection,
		repository,
	},
	[TestScopes.Delete]: {
		code: TestScopes.Delete,
		id: `${namespace}_2FXqPMcevcPA2OVcUWwM07UpotJ`,
		order: 3,
		icon: 'circle-user',
		label: 'Test Delete',
		collection,
		repository,
	},
	[TestScopes.Manage]: {
		code: TestScopes.Manage,
		id: `${namespace}_2FXqI2GSSBoFzUBGixjQb8guXsv`,
		order: 4,
		icon: 'circle-user',
		label: 'Test Manage',
		alias: [TestScopes.Create, TestScopes.Read, TestScopes.Update, TestScopes.Delete],
		collection,
		repository,
	},
});
