import { DeviceFingerprintType } from '@dictionaries/device-fingerprint-types';
import { mainTheme } from '@indly/themes/main.theme';
import type {
	DeviceIdentity,
	DeviceIdentityCL,
	DeviceIdentityFP,
} from '@shared/common/auth/constants/auth.constant';
import { AuthDeviceFingerprintHeaders } from '@shared/common/auth/constants/auth.constant';
import PasswordlessCloseTabScreen from 'auth/components/PasswordlessCloseTabScreen.component';
import PasswordlessEmailForm from 'auth/components/PasswordlessEmailForm.component';
import PasswordlessLinkClickedScreen from 'auth/components/PasswordlessLinkClickedScreen.component';
import PasswordlessSignInUpHeader from 'auth/components/PasswordlessSignInUpHeader.component';
import PasswordlessUserInputCodeFormHeader from 'auth/components/PasswordlessUserInputCodeFormHeader.component';
import { LOGIN_PAGE_PATH } from 'auth/constants/auth.constant';
import { API_VERSIONED_PREFIX } from 'constants/api.constant';
import { getDeviceIdentity } from 'libs/device/device.lib';
import i18n from 'libs/localization/i18n-instance.service';
import { isNil } from 'lodash';
import { isEmpty, isString } from 'remeda';
import {
	GetRedirectionURLContext,
	OnHandleEventContext,
	type PreAndPostAPIHookAction,
} from 'supertokens-auth-react/lib/build/recipe/passwordless/types';
import type { RecipePreAPIHookContext } from 'supertokens-auth-react/lib/build/recipe/recipeModule/types';
import { TranslationFunc } from 'supertokens-auth-react/lib/build/translation/translationHelpers';
import { SuperTokensConfig as SuperTokensConfigProps } from 'supertokens-auth-react/lib/build/types';
import Passwordless from 'supertokens-auth-react/recipe/passwordless';
import Session from 'supertokens-auth-react/recipe/session';
import { Pattern, match } from 'ts-pattern';

// console.info(`[INIT] sueprtokens.config [isServerSide: ${JSON.stringify(typeof window === 'undefined')}]...`);
// console.debug(`[DEBUG] SuperTokensConfig() window.location.origin:`, `${window.location.origin}`);

export const SuperTokensConfig: SuperTokensConfigProps = {
	enableDebugLogs: false,
	// learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
	appInfo: {
		appName: 'independently',
		apiDomain: `${window.location.origin}`,
		websiteDomain: `${window.location.origin}`,
		apiBasePath: `${API_VERSIONED_PREFIX}/auth`,
		websiteBasePath: `${LOGIN_PAGE_PATH}`,
	},
	languageTranslations: {
		translationFunc: i18n.t.bind(i18n) as TranslationFunc,
	},
	recipeList: [
		Passwordless.init({
			contactMethod: 'EMAIL',
			preAPIHook: async (context: RecipePreAPIHookContext<PreAndPostAPIHookAction>) => {
				// console.info(`[INFO] Passwordless.preAPIHook()`, context);

				const {
					url,
					requestInit,
					action,
				}: { url: string; requestInit: RequestInit; action: PreAndPostAPIHookAction } =
					context;

				const deviceIdentity: DeviceIdentityCL | DeviceIdentityFP = await getDeviceIdentity(
					{ function: 'preAPIHook' }
				);
				// console.debug('[DEBUG Supertokens] post-getDeviceIdentity()', { ...deviceIdentity });
				// console.debug('[DEBUG Supertokens] A-preMerge requestInit.headers:', Object.keys(requestInit.headers ?? {}).length, { ...requestInit.headers });

				requestInit.headers = {
					...requestInit.headers,
					[AuthDeviceFingerprintHeaders.DeviceFingerprintType]: String(
						deviceIdentity.varient
					),
					[AuthDeviceFingerprintHeaders.DeviceFingerprintId]: String(deviceIdentity.id),
					...match(deviceIdentity)
						.with(
							{ varient: DeviceFingerprintType.FP5ICAX3QY },
							(
								browserId: Extract<
									DeviceIdentity,
									{ varient: DeviceFingerprintType.FP5ICAX3QY }
								>
							) => ({
								[AuthDeviceFingerprintHeaders.DeviceFingerprintRequestId]: String(
									browserId.requestId
								),
								...(!isNil(browserId.confidence?.score) && {
									[AuthDeviceFingerprintHeaders.DeviceFingerprintConfidenceScore]:
										String(browserId.confidence.score),
								}),
								...(isString(browserId.confidence?.comment) &&
									!isEmpty(browserId.confidence.comment) && {
										[AuthDeviceFingerprintHeaders.DeviceFingerprintConfidenceComment]:
											String(browserId.confidence?.comment),
									}),
							})
						)
						.with(
							{ varient: DeviceFingerprintType.CLIPT52GYE },
							(
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								browserId: Extract<
									DeviceIdentity,
									{ varient: DeviceFingerprintType.CLIPT52GYE }
								>
							) => ({})
						)
						.exhaustive(),
				};
				// console.debug('[DEBUG Supertokens] B-postMerge requestInit.headers:', Object.keys(requestInit.headers ?? {}).length, { ...requestInit.headers });

				void match(action)
					.with('PASSWORDLESS_CREATE_CODE', () => {})
					.with('PASSWORDLESS_CONSUME_CODE', () => {})
					.with('PASSWORDLESS_RESEND_CODE', () => {})
					.with('EMAIL_EXISTS', () => {})
					.with('PHONE_NUMBER_EXISTS', () => {})
					.exhaustive();

				// events such as sign out are in the
				// session recipe pre API hook (See the info box below)
				return { requestInit, url };
			},
			getRedirectionURL: async (
				context: GetRedirectionURLContext
			): Promise<string | undefined> =>
				match(context)
					.with(
						{
							action: 'SUCCESS',
							redirectToPath: Pattern.select('redirect', Pattern.string),
						},
						// eslint-disable-next-line arrow-body-style
						({ redirect }: { redirect: string }) => {
							// console.debug('[DEBUG] Case A: redirecting to redirect:', decodeURIComponent(redirect));

							return decodeURIComponent(redirect);
						}
					)
					// eslint-disable-next-line arrow-body-style
					.otherwise(() => {
						// console.debug('[DEBUG] Case Default: return undefined to let the default behavior play out');

						return undefined;
					}),
			onHandleEvent: (context: OnHandleEventContext): void => {
				// console.info(`[INFO] Passwordless.onHandleEvent():`, context);

				void match(context.action)
					.with('SESSION_ALREADY_EXISTS', () => {})
					.with('PASSWORDLESS_RESTART_FLOW', () => {})
					.with('PASSWORDLESS_CODE_SENT', () => {})
					.with('SUCCESS', () => {
						// const { id, email } = context.user; // PasswordlessUser
						// console.debug(`[DEBUG] onHandleEvent:`, 'SUCCESS!', context);
						// console.debug(context.user);
						// const { setUser, setCompany } = vanillaStore.getState();
						// if (!isUndefined(user)) setUser(user);
						// if (!isUndefined(companies)) setCompany(companies?.[0]);
					})
					.exhaustive();
			},
			useShadowDom: false,
			override: {
				functions: (originalImplementation) => ({
					...originalImplementation,
					consumeCode: async (input) => {
						// console.info(`[INFO] override Passwordless.consumeCode(), input:`, input);

						void match(input)
							.with(
								{
									userInputCode: Pattern.string,
								},
								(i) => {
									// console.debug('[DEBUG] Changing userInputCode from:', i.userInputCode, 'to:', i.userInputCode.replace(/[^a-zA-Z0-9]/g, '').toUpperCase());

									// eslint-disable-next-line no-param-reassign
									i.userInputCode = i.userInputCode
										.replace(/[^a-zA-Z0-9]/g, '')
										.toUpperCase();
								}
							)
							.otherwise(() => void 0);

						// Then call the default behavior as show below
						return originalImplementation.consumeCode(input);
					},
				}),
				components: {
					PasswordlessSignInUpHeader_Override: PasswordlessSignInUpHeader,
					PasswordlessEmailForm_Override: PasswordlessEmailForm,
					PasswordlessUserInputCodeFormHeader_Override:
						PasswordlessUserInputCodeFormHeader,
					PasswordlessLinkClickedScreen_Override: PasswordlessLinkClickedScreen,
					PasswordlessCloseTabScreen_Override: PasswordlessCloseTabScreen,
				},
			},
			signInUpFeature: {
				disableDefaultUI: true,
				emailOrPhoneFormStyle: {
					button: {
						backgroundColor: mainTheme.palette.blue.main,
						border: 'none',
					},
					label: {
						display: 'none',
					},
				},
				userInputCodeFormStyle: {
					button: {
						backgroundColor: mainTheme.palette.blue.main,
						border: 'none',
					},
					label: {
						display: 'none',
					},
				},
			},
			palette: {
				background: 'none',
				error: mainTheme.palette.red.main,
				textTitle: mainTheme.palette.black.main,
				textLabel: mainTheme.palette.black.main,
				textInput: mainTheme.palette.black.main,
				textPrimary: mainTheme.palette.black.main,
				textLink: mainTheme.palette.black.main,
				inputBackground: mainTheme.palette.white.main,
			},
		}),
		Session.init({}),
	],
};
