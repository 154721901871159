// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { SortList, SortListItem } from './types/dictionaries.type';

export const sortList = Map<SortList | `${SortList}`, SortListItem>({
	[SortList.DATE_CREATED]: {
		code: SortList.DATE_CREATED,
		id: 'soli_2FeQ2SztKKZqlCxbobiVzTb2mXy',
		order: 0,
		// label: i18n.t('sortList.date_created', { ns: 'dictionaries' }),
		label: 'Date Created',
		directionIcons: {
			asc: 'sort-numeric-up',
			desc: 'sort-numeric-down',
		},
		function: {
			asc: { _createdDate: 'asc' },
			desc: { _createdDate: 'desc' },
		},
	},
	[SortList.DATE_LAST_UPDATED]: {
		code: SortList.DATE_LAST_UPDATED,
		id: 'soli_2FeQ2UmhAL6za9RfaBdMHpQvJ4D',
		order: 1,
		// label: i18n.t('sortList.date_last_updated', { ns: 'dictionaries' }),
		label: 'Date Last Updated',
		directionIcons: {
			asc: 'sort-numeric-up-alt',
			desc: 'sort-numeric-down-alt',
		},
		function: {
			asc: { _updatedDate: 'asc' },
			desc: { _updatedDate: 'desc' },
		},
	},
	[SortList.EXTERNAL_ID]: {
		code: SortList.EXTERNAL_ID,
		id: 'soli_2FeQ2YVfxWS4j9O6rRHr6lH4QYW',
		order: 2,
		// label: i18n.t('sortList.id', { ns: 'dictionaries' }),
		label: 'External Id',
		directionIcons: {
			asc: 'sort-size-up-alt',
			desc: 'sort-size-down-alt',
		},
		function: {
			asc: {
				externalId: 'asc',
				_createdDate: 'asc',
			},
			desc: {
				externalId: 'desc',
				_createdDate: 'desc',
			},
		},
	},
});
