// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../../+types/scope-item.type';

const collection = 'users';
const repository = 'User';

export enum UserScopes {
	'Create' = 'users:create',
	'Read' = 'users:read',
	'Update' = 'users:update',
	'Delete' = 'users:delete',
	'Manage' = 'users:manage',
}

export const userScopes: AbilityScopeMap<UserScopes> = Map<
	UserScopes,
	AbilityScopeItem<UserScopes>
>({
	[UserScopes.Create]: {
		code: UserScopes.Create,
		id: 'usesco_2Gt5PAIMRsF2fsDZwygcppN5U6U',
		order: 0,
		icon: 'circle-user',
		label: 'User Create',
		collection,
		repository,
	},
	[UserScopes.Read]: {
		code: UserScopes.Read,
		id: 'usesco_2Gt5PCuuZRpXIQtk2AiJpUIsAnW',
		order: 1,
		icon: 'circle-user',
		label: 'User Read',
		collection,
		repository,
	},
	[UserScopes.Update]: {
		code: UserScopes.Update,
		id: 'usesco_2Gt5P7q3S7PNNFPJXiMxxuoFOOn',
		order: 2,
		icon: 'circle-user',
		label: 'User Update',
		collection,
		repository,
	},
	[UserScopes.Delete]: {
		code: UserScopes.Delete,
		id: 'usesco_2Gt5PCOK0tnXJvJ3QkKY9tPScKc',
		order: 3,
		icon: 'circle-user',
		label: 'User Delete',
		collection,
		repository,
	},
	[UserScopes.Manage]: {
		code: UserScopes.Manage,
		id: 'usesco_2Gt5P82iWYmUzuSIg2PMqHdmvws',
		order: 4,
		icon: 'circle-user',
		label: 'User Manage',
		alias: [UserScopes.Create, UserScopes.Read, UserScopes.Update, UserScopes.Delete],
		collection,
		repository,
	},
});
