/* eslint-disable no-console */
import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let RENDER_COUNT = 0;

const ClientSideRendering = ({ children }: any): any | null => {
	// eslint-disable-next-line no-plusplus
	RENDER_COUNT++;

	const [csrReady, setCsrReady] = useState(false);

	// NOTE: this will NEVER fire on Server Side, but it will (of course) run in the Browser
	useEffect(() => {
		// console.log(`ClientSideRendering: 🔵 useEffect: RENDER_COUNT=${ RENDER_COUNT }, csrReady=${ String(csrReady) }`);

		setCsrReady(true);
	}, []); // one-shot function. ie. will call ONLY once

	// console.log(`ClientSideRendering: RENDER_COUNT=${ RENDER_COUNT }, csrReady=${ String(csrReady) } ${ csrReady ? '🟢' : '❌' }`);

	return csrReady ? children : null;
};

export default ClientSideRendering;
