import rootLocales from '@locales/en/common.json';
import averageHoursPerWeekList from '@locales/en/dictionaries/averageHoursPerWeekList.json';
import dateFormats from '@locales/en/dictionaries/dateFormats.json';
import intentionTypes from '@locales/en/dictionaries/intentionTypes.json';
import engagementStatuses from '@locales/en/dictionaries/engagementStatuses.json';
import fileTypes from '@locales/en/dictionaries/fileTypes.json';
import payRateUnits from '@locales/en/dictionaries/payRateUnits.json';
import phoneTypes from '@locales/en/dictionaries/phoneTypes.json';
import sortList from '@locales/en/dictionaries/sortList.json';
import systemLanguages from '@locales/en/dictionaries/systemLanguages.json';
import themes from '@locales/en/dictionaries/themes.json';
import workerFilters from '@locales/en/dictionaries/workerFilters.json';
import workerSteps from '@locales/en/dictionaries/workerSteps.json';
import administrators from 'features/administrators/locales/administrators.json';
import clients from 'features/clients/locales/clients.json';
import automations from 'features/customizations/locales/automations.json';
import customizations from 'features/customizations/locales/customizations.json';
import files from 'features/files/locales/files.json';
import engagementTemplates from 'features/engagementTemplates/locales/engagementTemplates.json';
import integrations from 'features/integrations/locales/integrations.json';
import login from 'features/login/locales/login.json';
import onboarding from 'features/onboarding/locales/onboarding.json';
import settings from 'features/settings/locales/settings.json';
import info from 'features/workers/locales/info.json';
import workers from 'features/workers/locales/workers.json';
import type { InitOptions, Resource } from 'i18next';
import locales from 'locales/en/common.locale.json';
import components from 'locales/en/components.locale.json';
import dates from 'locales/en/dates.locale.json';
import fields from 'locales/en/fields.locale.json';
import mainButton from 'locales/en/mainButton.locale.json';
import modals from 'locales/en/modals.locale.json';
import sidebar from 'locales/en/sidebar.locale.json';
import supertokens from 'locales/en/supertokens.locale.json';
import tooltips from 'locales/en/tooltips.locale.json';
import { merge } from 'lodash';

// @TODO: Need to abstract out variables to get either server-side OR client-side...
// console.debug('[DEBUG] localization.ts ENV: ', ENV);

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const resourcesBundle: Resource = merge.apply({}, [
	rootLocales,
	locales,
	supertokens,
	sidebar,
	components,
	dates,
	workers,
	administrators,
	files,
	engagementTemplates,
	systemLanguages,
	workerFilters,
	themes,
	engagementStatuses,
	fields,
	info,
	workerSteps,
	sortList,
	dateFormats,
	payRateUnits,
	averageHoursPerWeekList,
	login,
	settings,
	customizations,
	integrations,
	clients,
	onboarding,
	intentionTypes,
	modals,
	mainButton,
	tooltips,
	phoneTypes,
	fileTypes,
	automations,
]);

// console.log('ENV: ');
// console.log(ENV);

export const LocalizationConfig: InitOptions = {
	debug: false, // @TODO: ENV || false,
	ns: ['app'],
	resources: resourcesBundle,
	// lng: 										 : 'fr-CA', // @TEMP FOR DEBUGGING - MANUALLY ASSIGN LANGUAGE
	fallbackLng: {
		// Map all French locales to 'fr-CA'
		fr: ['fr-CA', 'en'],
		'fr-BE': ['fr-CA', 'en'],
		'fr-CH': ['fr-CA', 'en'],
		'fr-FR': ['fr-CA', 'en'],
		'fr-LU': ['fr-CA', 'en'],
		'fr-MC': ['fr-CA', 'en'],
		default: ['en'],
	},
	supportedLngs: ['en', 'fr-CA'],
	nonExplicitSupportedLngs: false, // Allow locales ie. 'en-US'
	load: 'currentOnly', // Only load the given lng ie. 'en-US' not 'all' --> ['en-US', 'en', 'dev']
	lowerCaseLng: false,
	saveMissing: true,

	// Language detector used in browser environment for i18next
	//    https://github.com/i18next/i18next-browser-languageDetector
	detection: {
		// order and from where user language should be detected
		order: ['querystring', 'cookie', 'navigator'],

		// keys or params to lookup language from
		lookupQuerystring: 'lng',
		lookupCookie: 'independentlyai_lng',

		// cache user language on
		caches: ['cookie'],
		excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
	},
};
