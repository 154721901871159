// import { APP_VERSION_MAJOR as VERSION } from '../../../../shared/configs/environment.config';

export const API_PREFIX: string = '/api';

export const API_VERSIONED_PREFIX: string = `${API_PREFIX}/v3`;
// console.debug('[DEBUG] API_VERSIONED_PREFIX:', API_VERSIONED_PREFIX);

export const REFRESH_API_PATH = '/session/refresh';

export const SIGNOUT_API_PATH = '/signout';
