// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'engagements';
const repository = 'Engagement';

export enum EngagementScopes {
	'Create' = 'engagements:create',
	'Read' = 'engagements:read',
	'Update' = 'engagements:update',
	'Delete' = 'engagements:delete',
	'Manage' = 'engagements:manage',
	'Import' = 'engagements:import',
	'UpdateEngagedClassification' = 'engagements:updateEngagedClassification',
	'SetVendorIntention' = 'engagements:setVendorIntention',
	'ReadVendorIntention' = 'engagements:readVendorIntention',
	'SetWorkerIntention' = 'engagements:setWorkerIntention',
	'ReadWorkerIntention' = 'engagements:readWorkerIntention',
	'SetHiringManagerIntention' = 'engagements:setHiringManagerIntention',
	'ReadHiringManagerIntention' = 'engagements:readHiringManagerIntention',
	'CreateSurveys' = 'engagements:createSurveys',
	'ReadSurveys' = 'engagements:readSurveys',
	'SnoozeActionItem' = 'engagements:snoozeActionItem',
	'DeclineActionItem' = 'engagements:declineActionItem',
	'ResolveActionItem' = 'engagements:resolveActionItem',
	'ReviewSurveyAnswers' = 'engagements:reviewSurveyAnswers',
	'ReadPartnerCompany' = 'engagements:readPartnerCompany',
	'UpdatePartnerCompany' = 'engagements:updatePartnerCompany',
	'ReadBilledCompany' = 'engagements:readBilledCompany',
	'UpdateBilledCompany' = 'engagements:updateBilledCompany',
	'ReadClassificationScoring' = 'engagements:readClassificationScoring',
	'CreateClassificationReport' = 'engagements:createClassificationReport',
	'PreviewClassificationReport' = 'engagements:previewClassificationReport',
	'ReadClassificationReport' = 'engagements:readClassificationReport',
	'UpdateDueDate' = 'engagements:updateDueDate',
	'UpdateCompliance' = 'engagements:updateCompliance',
	'UpdateDeleted' = 'engagements:updateDeleted',
	'ViewBillingUsage' = 'engagements:viewBillingUsage',
}

const namespace = 'engsco';

export const engagementScopes: AbilityScopeMap<EngagementScopes> = Map<
	EngagementScopes,
	AbilityScopeItem<EngagementScopes>
>({
	[EngagementScopes.Create]: {
		code: EngagementScopes.Create,
		id: `${namespace}_2FX8K1O3R1xMknhyIIdIYx6B3Xc`,
		order: 0,
		icon: 'circle-user',
		label: 'Engagement Create',
		collection,
		repository,
	},
	[EngagementScopes.Read]: {
		code: EngagementScopes.Read,
		id: `${namespace}_2FX8MLgFvodsbSdBzPrgf6JMtkG`,
		order: 1,
		icon: 'circle-user',
		label: 'Engagement Read',
		collection,
		repository,
	},
	[EngagementScopes.Update]: {
		code: EngagementScopes.Update,
		id: `${namespace}_2FX8a9EC6vOukcHVAVYOYbtHsxN`,
		order: 2,
		icon: 'circle-user',
		label: 'Engagement Update',
		collection,
		repository,
	},
	[EngagementScopes.Delete]: {
		code: EngagementScopes.Delete,
		id: `${namespace}_2FX8bmVwPWoyfy8901mVtRFc9Va`,
		order: 3,
		icon: 'circle-user',
		label: 'Engagement Delete',
		collection,
		repository,
	},
	[EngagementScopes.Manage]: {
		code: EngagementScopes.Manage,
		id: `${namespace}_2FX8I3Fbo0blnTgxqiJAOgpEAgv`,
		order: 4,
		icon: 'circle-user',
		label: 'Engagement Manage',
		alias: [
			EngagementScopes.Create,
			EngagementScopes.Read,
			EngagementScopes.Update,
			EngagementScopes.Delete,
		],
		collection,
		repository,
		// include: [EngagementScopes.Create, ]
	},
	[EngagementScopes.Import]: {
		code: EngagementScopes.Import,
		id: `${namespace}_2akAiDH4A9Z4k0Wc2U6xq1NwTgd`,
		order: 5,
		icon: 'circle-user',
		label: 'Engagement Import',
		collection,
		repository,
	},
	[EngagementScopes.UpdateEngagedClassification]: {
		code: EngagementScopes.UpdateEngagedClassification,
		id: `${namespace}_2fzutENVUNl9vuGsEFb5hrtqNMp`,
		order: 6,
		icon: 'circle-user',
		label: 'Engagement update engagedClassification',
		collection,
		repository,
	},
	[EngagementScopes.SetVendorIntention]: {
		code: EngagementScopes.SetVendorIntention,
		id: `${namespace}_2UPRxhhT3wLJI9Q9wE9ExyrWI6b`,
		order: 7,
		icon: 'circle-user',
		label: 'Engagement set vendor intention',
		collection,
		repository,
	},
	[EngagementScopes.ReadVendorIntention]: {
		code: EngagementScopes.ReadVendorIntention,
		id: `${namespace}_2UPRxem0ieVXVqDw51a2lQWKy5c`,
		order: 8,
		icon: 'circle-user',
		label: 'Engagement read vendor intention',
		collection,
		repository,
	},
	[EngagementScopes.SetWorkerIntention]: {
		code: EngagementScopes.SetWorkerIntention,
		id: `${namespace}_2UPRxcnssGJ3mE8B6crCYVgteAc`,
		order: 9,
		icon: 'circle-user',
		label: 'Engagement set worker intention',
		collection,
		repository,
	},
	[EngagementScopes.ReadWorkerIntention]: {
		code: EngagementScopes.ReadWorkerIntention,
		id: `${namespace}_2UPRxaunezZL1z8jbeGKfbDySIo`,
		order: 10,
		icon: 'circle-user',
		label: 'Engagement read worker intention',
		collection,
		repository,
	},
	[EngagementScopes.SetHiringManagerIntention]: {
		code: EngagementScopes.SetHiringManagerIntention,
		id: `${namespace}_2UPRxeGj43UbhnoEaX9Gtb2CLuX`,
		order: 11,
		icon: 'circle-user',
		label: 'Engagement set hiring manager intention',
		collection,
		repository,
	},
	[EngagementScopes.ReadHiringManagerIntention]: {
		code: EngagementScopes.ReadHiringManagerIntention,
		id: `${namespace}_2UPRxbJqRvNRfI9BUpubc1KT1sL`,
		order: 12,
		icon: 'circle-user',
		label: 'Engagement read hiring manager intention',
		collection,
		repository,
	},
	[EngagementScopes.CreateSurveys]: {
		code: EngagementScopes.CreateSurveys,
		id: `${namespace}_2RfsUc9aPDLx9xiwtjMLU1N35TW`,
		order: 13,
		icon: 'circle-user',
		label: 'Engagement Create Surveys',
		collection,
		repository,
	},
	[EngagementScopes.ReadSurveys]: {
		code: EngagementScopes.ReadSurveys,
		id: `${namespace}_2No3GNnasmSNCoS7X6ba2zzRrQ6`,
		order: 14,
		icon: 'circle-user',
		label: 'Engagement Read Surveys',
		collection,
		repository,
	},
	[EngagementScopes.ReviewSurveyAnswers]: {
		code: EngagementScopes.ReviewSurveyAnswers,
		id: `${namespace}_2T7vkgGcy2ROIoJEQbAgPqE7vZJ`,
		order: 15,
		icon: 'circle-user',
		label: 'Engagement Review-and-Confirm Survey Answers',
		collection,
		repository,
	},
	[EngagementScopes.ReadPartnerCompany]: {
		code: EngagementScopes.ReadPartnerCompany,
		id: `${namespace}_2iRfjGqo4UL6xMmUmEOOCRKmtzs`,
		order: 16,
		icon: 'circle-user',
		label: 'Engagement Read Partner Company',
		collection,
		repository,
	},
	[EngagementScopes.UpdatePartnerCompany]: {
		code: EngagementScopes.UpdatePartnerCompany,
		id: `${namespace}_2iRfjEpAaTekJvIPeXvr4hh6e2j`,
		order: 17,
		icon: 'circle-user',
		label: 'Engagement Set Partner Company',
		collection,
		repository,
	},
	[EngagementScopes.ReadBilledCompany]: {
		code: EngagementScopes.ReadBilledCompany,
		id: `${namespace}_2iQbQQwNQeBqy7SucmI2ofwsr64`,
		order: 18,
		icon: 'circle-user',
		label: 'Engagement Read Billed Company',
		collection,
		repository,
	},
	[EngagementScopes.UpdateBilledCompany]: {
		code: EngagementScopes.UpdateBilledCompany,
		id: `${namespace}_2iQbQVkInZ2Hndz04VRiw6uuGiK`,
		order: 19,
		icon: 'circle-user',
		label: 'Engagement Set Billed Company',
		collection,
		repository,
	},
	[EngagementScopes.SnoozeActionItem]: {
		code: EngagementScopes.SnoozeActionItem,
		id: `${namespace}_2TRwMHbHp8VsrMPYh77nCf0M7af`,
		order: 20,
		icon: 'circle-user',
		label: 'Snooze Engagement Action Item',
		collection,
		repository,
	},
	[EngagementScopes.DeclineActionItem]: {
		code: EngagementScopes.DeclineActionItem,
		id: `${namespace}_2TRwMJ0dBkHaZyRYTQ4lQJCQdEQ`,
		order: 21,
		icon: 'circle-user',
		label: 'Decline Engagement Action Item',
		collection,
		repository,
	},
	[EngagementScopes.ResolveActionItem]: {
		code: EngagementScopes.ResolveActionItem,
		id: `${namespace}_2TRwMM00VKMZUA2KIyjog7nPqMb`,
		order: 22,
		icon: 'circle-user',
		label: 'Resolve Engagement Action Item',
		collection,
		repository,
	},
	[EngagementScopes.PreviewClassificationReport]: {
		code: EngagementScopes.PreviewClassificationReport,
		id: `${namespace}_2RtRoQdEaKth7MPWd52YiVnOof0`,
		order: 23,
		icon: 'circle-user',
		label: 'Preview Engagement Classification Report',
		collection,
		repository,
	},
	[EngagementScopes.ReadClassificationScoring]: {
		code: EngagementScopes.ReadClassificationScoring,
		id: `${namespace}_2jDso0efYuyc7dMrDi7DwfSUwbc`,
		order: 24,
		icon: 'circle-user',
		label: 'Read Engagement Classification Scoring',
		collection,
		repository,
	},
	[EngagementScopes.CreateClassificationReport]: {
		code: EngagementScopes.CreateClassificationReport,
		id: `${namespace}_2RtRoV25DIBrMOgyLxidKc2vvs2`,
		order: 25,
		icon: 'circle-user',
		label: 'Create Engagement Classification Report',
		collection,
		repository,
	},
	[EngagementScopes.ReadClassificationReport]: {
		code: EngagementScopes.ReadClassificationReport,
		id: `${namespace}_2RtRoWx1Vcfvq9SRHjmnPbgBAea`,
		order: 26,
		icon: 'circle-user',
		label: 'Read Engagement Classification Report',
		collection,
		repository,
	},
	[EngagementScopes.UpdateDueDate]: {
		code: EngagementScopes.UpdateDueDate,
		id: `${namespace}_2SwlqxVSxlvbyjCGSl0JZEQgkhO`,
		order: 27,
		icon: 'circle-user',
		label: 'Update Engagement Due Date',
		collection,
		repository,
	},
	[EngagementScopes.UpdateCompliance]: {
		code: EngagementScopes.UpdateCompliance,
		id: `${namespace}_2No3GOhiKGCYd2CG8zPGYXChxGj`,
		order: 28,
		icon: 'circle-user',
		label: 'Engagement Update Compliance',
		collection,
		repository,
	},
	[EngagementScopes.UpdateDeleted]: {
		code: EngagementScopes.UpdateDeleted,
		id: `${namespace}_2fKYPFdEkzdkK6mdwVO8pl6M7za`,
		order: 29,
		icon: 'circle-user',
		label: 'Engagement Update Deleted',
		collection,
		repository,
	},
	[EngagementScopes.ViewBillingUsage]: {
		code: EngagementScopes.ViewBillingUsage,
		id: `${namespace}_2iXeQHNKtnBCSvtBDXZY2L0YYfY`,
		order: 30,
		icon: 'circle-user',
		label: 'View Engagement Billing Usage',
		collection,
		repository,
	},
});
