/* eslint-disable @typescript-eslint/no-unused-vars */
import { fingerprintJsConfig } from '@configs/fingerprintjs.config';
import { DeviceFingerprintType } from '@dictionaries/device-fingerprint-types';
import FingerprintJS, {
	GetResult as FingerprintJSResult,
	type Agent as FingerprintJSAgent,
} from '@fingerprintjs/fingerprintjs-pro';
import type {
	DeviceIdentityCL,
	DeviceIdentityFP,
} from '@shared/common/auth/constants/auth.constant';
import { ENV, isEnvironmentDevelopment } from '@shared/configs/environment.config';
import { ClientJS } from 'clientjs';
import { throwError } from 'libs/whoops';
import { isNil, isObject, isString } from 'lodash';
import { match } from 'ts-pattern';

const _getDeviceFingerprintId = async (
	metadata: Record<string, unknown> = {}
): Promise<FingerprintJSResult | null> => {
	let fingerprintResult: FingerprintJSResult | null = null;

	try {
		const fingerprintJSAgent: FingerprintJSAgent = await FingerprintJS.load(
			fingerprintJsConfig
		);
		fingerprintResult = await fingerprintJSAgent.get();
		// console.debug('[DEBUG DeviceLib] post-fingerprintJS.get() fingerprintResult:', fingerprintResult);
	} catch (error) {
		if (error.message === FingerprintJS.ERROR_CSP_BLOCK) {
			throwError(`[ERROR DeviceLib] FingerprintJS blocked by CSP policy`, {
				extra: {
					...metadata,
					...fingerprintResult,
					message: error.message,
				},
			});
		} else {
			throwError(`[ERROR DeviceLib] Unknown FingerprintJS error occurred.`, {
				extra: {
					...metadata,
					...fingerprintResult,
					message: error.message,
				},
			});
		}
	}

	if (!isObject(fingerprintResult) || !isString(fingerprintResult.visitorId)) {
		throwError(`[ERROR DeviceLib] FingerprintJS invalid visitorId (not a string)`, {
			extra: {
				...metadata,
				...fingerprintResult,
			},
		});
	}

	return fingerprintResult;
};

const _getDeviceClientId = async (
	metadata: Record<string, unknown> = {}
): Promise<{ id: string }> => {
	let clientId: number | null = null;

	try {
		const client = new ClientJS();
		clientId = client.getFingerprint();
		// console.debug('[DEBUG DeviceLib] post-client.getFingerprin() clientId:', { getUserAgent: client.getUserAgent(), id: clientId});
	} catch (error) {
		throwError(`[ERROR DeviceLib] Unknown ClientJs fingerprint error occurred.`, {
			extra: {
				...metadata,
			},
		});
	}

	return {
		id: String(clientId),
	};
};

export const getDeviceIdentity = async (
	metadata: Record<string, unknown> = {}
): Promise<DeviceIdentityFP | DeviceIdentityCL> => {
	// console.info('[INFO DeviceLib] getDeviceIdentity()', { metadata });

	const { userAgent }: { userAgent: string } = window.navigator;

	return match(isEnvironmentDevelopment(ENV))
		.with(true, async (isDev: true): Promise<DeviceIdentityFP | DeviceIdentityCL> => {
			const clientId: { id: string } = await _getDeviceClientId(metadata);
			// console.debug('[DEBUG DeviceLib] Case B: post-_getDeviceClientId()', { ...clientId });

			return {
				varient: DeviceFingerprintType.CLIPT52GYE,
				userAgent,
				id: clientId.id,
			};
		})
		.otherwise(async (): Promise<DeviceIdentityFP | DeviceIdentityCL> => {
			const fingerprintId: FingerprintJSResult | null = await _getDeviceFingerprintId(
				metadata
			);
			// console.debug('[DEBUG DeviceLib] Case A: post-_getDeviceFingerprintId()', { ...fingerprintId });

			if (isNil(fingerprintId)) {
				const clientId: { id: string } = await _getDeviceClientId(metadata);
				// console.debug('[DEBUG DeviceLib] post-_getDeviceClientId()', { ...clientId });

				return {
					varient: DeviceFingerprintType.CLIPT52GYE,
					userAgent,
					id: clientId.id,
				};
			}

			return {
				varient: DeviceFingerprintType.FP5ICAX3QY,
				userAgent,
				id: fingerprintId.visitorId,
				requestId: fingerprintId.requestId,
				confidence: fingerprintId.confidence,
			};
		});
};
