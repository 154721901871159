/** @type Array<string | boolean | number> */
const booleanValues = [true, false, 'true', 'false', '1', '0', 1, 0];

/** @type Array<string | boolean | number | undefined | null> */
const booleanLikeValues = [...booleanValues, undefined, null, 'undefined', 'null'];

/**
 * Returns a parsed boolean from a unknown string
 * @param {string | boolean | undefined} string
 * @returns {boolean}
 */
const stringToBoolean = (string) =>
	string === 'false' || string === 'undefined' || string === 'null' || string === '0'
		? false
		: !!string;

/** @type RegExp */
const LOCALE_REGEX = /^[A-Za-z]{2,4}([_-][A-Za-z]{4})?([_-]([A-Za-z]{2}|[0-9]{3}))?$/;

/**
 * Returns true if string is a locale
 * @param {string} locale
 * @returns {boolean}
 */
const isLocale = (locale) => LOCALE_REGEX.test(locale);

module.exports = {
	booleanValues,
	booleanLikeValues,
	stringToBoolean,
	LOCALE_REGEX,
	isLocale,
};
