// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary, Hex } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { ProgressColor, ProgressIcon } from './constants/dictionary-progress.constant';

export enum ImportStatus {
	DRAFT = 'draft',
	VALIDATING = 'validating',
	QUEUED = 'queued',
	IN_PROGRESS = 'in-progress',
	FIXES_NEEDED = 'fixes-needed',
	FAILED = 'failed',
	COMPLETED = 'completed',
}
export interface ImportStatusItem extends BaseDictionary {
	code: ImportStatus;
	id: `${IdNamespace.ImportStatus}_${string}`;
	order: number;
	label: string;
	description: string;
	color: Hex;
	icon: string;
	/* When true, the item's prgoress is final. Used to show/hide worker profile "Complete Onboarding" header button */
	isComplete?: boolean;
}

export const importStatuses = Map<ImportStatus | `${ImportStatus}`, ImportStatusItem>({
	[ImportStatus.DRAFT]: {
		code: ImportStatus.DRAFT,
		id: `impsta_2cvm7yilzi2N2VBG0X5RbY6IGRZ`,
		order: 0,
		// label: i18n.t('draft', { ns: 'app' }),
		label: 'Draft',
		description: 'An import workflow is initiated, but not yet imported (no progress)',
		color: ProgressColor.INCOMPLETE,
		icon: ProgressIcon.INCOMPLETE,
	},
	[ImportStatus.VALIDATING]: {
		code: ImportStatus.VALIDATING,
		id: `impsta_2cvn5lvyKrSDEyU0T7QbrM17Kpi`,
		order: 1,
		// label: i18n.t('validating', { ns: 'app' }),
		label: 'Validating',
		description: 'The import data is being reviewed prior to importing',
		color: ProgressColor.IN_REVIEW,
		icon: ProgressIcon.IN_REVIEW,
	},
	[ImportStatus.QUEUED]: {
		code: ImportStatus.QUEUED,
		id: `impsta_2cvn5ounVog3l3HV6pvG3Un0OdC`,
		order: 2,
		// label: i18n.t('queued', { ns: 'app' }),
		label: 'Queued',
		description: 'An import is queued and waiting to be processed',
		color: ProgressColor.SNOOZED,
		icon: ProgressIcon.SNOOZED,
	},
	[ImportStatus.IN_PROGRESS]: {
		code: ImportStatus.IN_PROGRESS,
		id: `impsta_2cvn5lk2fI909Syp5sWcLm6ZnqS`,
		order: 3,
		// label: i18n.t('in_progress', { ns: 'app' }),
		label: 'In-Progress',
		description: 'Import data is being processed and imported into the system',
		color: ProgressColor.IN_PROGRESS,
		icon: ProgressIcon.IN_PROGRESS,
	},
	[ImportStatus.FIXES_NEEDED]: {
		code: ImportStatus.FIXES_NEEDED,
		id: `impsta_2cvn5miUZdS13jIylcvk5Ibjosd`,
		order: 4,
		// label: i18n.t('fixesNeeded', { ns: 'app' }),
		label: 'Fixes Needed',
		description: 'Some imported data needs to be corrected before it can be imported',
		color: ProgressColor.MISSING,
		icon: ProgressIcon.MISSING,
	},
	[ImportStatus.FAILED]: {
		code: ImportStatus.FAILED,
		id: `impsta_2cvm80ALmpXUsSha2qtq4ziDN4M`,
		order: 5,
		// label: i18n.t('failed', { ns: 'app' }),
		label: 'Failed',
		description: 'The import has failed and needs to be re-imported after corrections are made',
		color: ProgressColor.FAIL,
		icon: ProgressIcon.FAIL,
		isComplete: true,
	},
	[ImportStatus.COMPLETED]: {
		code: ImportStatus.COMPLETED,
		id: `impsta_2cvm7uQWSaU5ctAGfEZS4MM6AGN`,
		order: 6,
		// label: i18n.t('completed', { ns: 'app' }),
		label: 'Completed',
		description:
			'The import has been successfully completed and all data is imported into the system',
		color: ProgressColor.PASS,
		icon: ProgressIcon.PASS,
		isComplete: true,
	},
});
