// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum PayRateUnit {
	HOURLY = 'hourly',
	HALF_DAY_FOUR_HOUR = 'half-day-four-hour',
	HALF_DAY_FIVE_HOUR = 'half-day-five-hour',
	EIGHT_HOUR = 'eight-hour',
	TEN_HOUR = 'ten-hour',
	TWELVE_HOUR = 'twelve-hour',
	DAILY = 'daily',
	WEEKLY = 'weekly',
	BIWEEKLY = 'biweekly',
	SEMI_MONTHLY = 'semi-monthly',
	MONTHLY = 'monthly',
	FIXED_CONTRACT = 'fixed-contract',
}

export enum PayRateUnitSchemaKey {
	HOURLY = 'hourly',
	HALF_DAY_FOUR_HOUR = 'halfDayFourHour',
	HALF_DAY_FIVE_HOUR = 'halfDayFiveHour',
	EIGHT_HOUR = 'eightHour',
	TEN_HOUR = 'tenHour',
	TWELVE_HOUR = 'twelveHour',
	DAILY = 'daily',
	WEEKLY = 'weekly',
	BIWEEKLY = 'biweekly',
	SEMI_MONTHLY = 'semiMonthly',
	MONTHLY = 'monthly',
	FIXED_CONTRACT = 'fixedContract',
}

export interface PayRateUnitItem extends BaseDictionary {
	code: PayRateUnit;
	id: `${IdNamespace.PayRateUnit}_${string}`;
	schemaKey: PayRateUnitSchemaKey;
	label: string;
	icon: string;
	field: string;
}

export const payRateUnits = Map<PayRateUnit | `${PayRateUnit}`, PayRateUnitItem>({
	[PayRateUnit.HOURLY]: {
		code: PayRateUnit.HOURLY,
		id: 'payrtu_2FeOs1naF1Schfaize5E4ZT2QME',
		schemaKey: PayRateUnitSchemaKey.HOURLY,
		// label: i18n.t('payRateUnits.hourly', { ns: 'dictionaries' }),
		label: 'Hourly',
		icon: 'clock',
		field: 'hourly',
	},
	[PayRateUnit.HALF_DAY_FOUR_HOUR]: {
		code: PayRateUnit.HALF_DAY_FOUR_HOUR,
		id: 'payrtu_2FeOrvTI8plYpG8KizsaylQECFv',
		schemaKey: PayRateUnitSchemaKey.HALF_DAY_FOUR_HOUR,
		// label: i18n.t('payRateUnits.halfDayFourHour', { ns: 'dictionaries' }),
		label: 'Half-day 4 Hour',
		icon: 'clock-one',
		field: 'halfDayFourHour',
	},
	[PayRateUnit.HALF_DAY_FIVE_HOUR]: {
		code: PayRateUnit.HALF_DAY_FIVE_HOUR,
		id: 'payrtu_2FeOrx5krAVMfgcNz96F7A9ifFn',
		schemaKey: PayRateUnitSchemaKey.HALF_DAY_FIVE_HOUR,
		// label: i18n.t('payRateUnits.halfDayFiveHour', { ns: 'dictionaries' }),
		label: 'Half-day 5 Hour',
		icon: 'clock-two',
		field: 'halfDayFiveHour',
	},
	[PayRateUnit.EIGHT_HOUR]: {
		code: PayRateUnit.EIGHT_HOUR,
		id: 'payrtu_2FeOrzvRDEnBPGjT2LzX8HrlLQ9',
		schemaKey: PayRateUnitSchemaKey.EIGHT_HOUR,
		// label: i18n.t('payRateUnits.eightHour', { ns: 'dictionaries' }),
		label: '8 Hour',
		icon: 'clock-eight',
		field: 'eightHour',
	},
	[PayRateUnit.TEN_HOUR]: {
		code: PayRateUnit.TEN_HOUR,
		id: 'payrtu_2FeOryTQ3GL710OtZrXJ0ZSWw30',
		schemaKey: PayRateUnitSchemaKey.TEN_HOUR,
		// label: i18n.t('payRateUnits.tenHour', { ns: 'dictionaries' }),
		label: '10 Hour',
		icon: 'clock-ten',
		field: 'tenHour',
	},
	[PayRateUnit.TWELVE_HOUR]: {
		code: PayRateUnit.TWELVE_HOUR,
		id: 'payrtu_2FeOrvwXBXUgcfRdIf3o4IC60Sl',
		schemaKey: PayRateUnitSchemaKey.TWELVE_HOUR,
		// label: i18n.t('payRateUnits.twelveHour', { ns: 'dictionaries' }),
		label: '12 Hour',
		icon: 'clock-twelve',
		field: 'twelveHour',
	},
	[PayRateUnit.DAILY]: {
		code: PayRateUnit.DAILY,
		id: 'payrtu_2FeOrvos2vT3Am6AYxDjftGrUuQ',
		schemaKey: PayRateUnitSchemaKey.DAILY,
		// label: i18n.t('payRateUnits.daily', { ns: 'dictionaries' }),
		label: 'Daily',
		icon: 'calendar-day',
		field: 'daily',
	},
	[PayRateUnit.WEEKLY]: {
		code: PayRateUnit.WEEKLY,
		id: 'payrtu_2FeOrx0cPW4tusw96ydNJvfrDJ9',
		schemaKey: PayRateUnitSchemaKey.WEEKLY,
		// label: i18n.t('payRateUnits.weekly', { ns: 'dictionaries' }),
		label: 'Weekly',
		icon: 'calendar-week',
		field: 'weekly',
	},
	[PayRateUnit.BIWEEKLY]: {
		code: PayRateUnit.BIWEEKLY,
		id: 'payrtu_2FeOs2W69NSrcIXjkzGK1TcV3LG',
		schemaKey: PayRateUnitSchemaKey.BIWEEKLY,
		// label: i18n.t('payRateUnits.biweekly', { ns: 'dictionaries' }),
		label: 'BiWeekly',
		icon: 'calendar-lines',
		field: 'biweekly',
	},
	[PayRateUnit.SEMI_MONTHLY]: {
		code: PayRateUnit.SEMI_MONTHLY,
		id: 'payrtu_2FeOry4aBwDHMenliyGJ8VjYQ2T',
		schemaKey: PayRateUnitSchemaKey.SEMI_MONTHLY,
		// label: i18n.t('payRateUnits.semi_monthly', { ns: 'dictionaries' }),
		label: 'Semi Monthly',
		icon: 'calendar-range',
		field: 'semiMonthly',
	},
	[PayRateUnit.MONTHLY]: {
		code: PayRateUnit.MONTHLY,
		id: 'payrtu_2FeOrxR6y4Q6WJmWlMYbb5SDXqQ',
		schemaKey: PayRateUnitSchemaKey.MONTHLY,
		// label: i18n.t('payRateUnits.monthly', { ns: 'dictionaries' }),
		label: 'Monthly',
		icon: 'calendar-days',
		field: 'monthly',
	},
	[PayRateUnit.FIXED_CONTRACT]: {
		code: PayRateUnit.FIXED_CONTRACT,
		id: 'payrtu_2FeOs2AvWCDxSTjxMKnayTwMxpD',
		schemaKey: PayRateUnitSchemaKey.FIXED_CONTRACT,
		// label: i18n.t('payRateUnits.fixed_contract', { ns: 'dictionaries' }),
		label: 'Fixed Contract',
		icon: 'file-contract',
		field: 'fixedContract',
	},
});
