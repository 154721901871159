// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';
import { IdNamespace } from '../../shared/database/constants/id-namespaces.constant';

const collection = 'sources';
const repository = 'Source';

export enum SourceScopes {
	'Create' = 'sources:create',
	'Read' = 'sources:read',
	'Update' = 'sources:update',
	'Delete' = 'sources:delete',
	'Manage' = 'sources:manage',
}

const NS: string = IdNamespace.SourceScope;

export const sourceScopes: AbilityScopeMap<SourceScopes> = Map<
	SourceScopes,
	AbilityScopeItem<SourceScopes>
>({
	[SourceScopes.Create]: {
		code: SourceScopes.Create,
		id: `${NS}_2FXnfMc0xF2MOES4vrq7bQvheJJ`,
		order: 0,
		icon: 'circle-user',
		label: 'Source Create',
		collection,
		repository,
	},
	[SourceScopes.Read]: {
		code: SourceScopes.Read,
		id: `${NS}_2FXngjuaZFFyPPFqEs8vGYSQXju`,
		order: 1,
		icon: 'circle-user',
		label: 'Source Read',
		collection,
		repository,
	},
	[SourceScopes.Update]: {
		code: SourceScopes.Update,
		id: `${NS}_2FXniIxwgcneqCOFaqzmi96xnUA`,
		order: 2,
		icon: 'circle-user',
		label: 'Source Update',
		collection,
		repository,
	},
	[SourceScopes.Delete]: {
		code: SourceScopes.Delete,
		id: `${NS}_2FXnkCwighgeFUEC0dcZuZ0kGEt`,
		order: 3,
		icon: 'circle-user',
		label: 'Client Delete',
		collection,
		repository,
	},
	[SourceScopes.Manage]: {
		code: SourceScopes.Manage,
		id: `${NS}_2FXndhdlZAMijwVidRNZO6yEKT9`,
		order: 4,
		icon: 'circle-user',
		label: 'Source Manage',
		alias: [SourceScopes.Create, SourceScopes.Read, SourceScopes.Update, SourceScopes.Delete],
		collection,
		repository,
	},
});
