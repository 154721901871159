import { Map } from 'immutable';
import en from 'dayjs/locale/en';
import fr from 'dayjs/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import { Locale } from 'date-fns';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum SystemLocale {
	EN = 'en',
	FR_CA = 'fr-ca',
}

export enum SystemLocaleSchemaKey {
	EN = 'en',
	FR_CA = 'fr_ca',
}

export interface LanguageItem extends BaseDictionary {
	code: SystemLocale;
	id: `${IdNamespace.Language}_${string}`;
	schemaKey: SystemLocaleSchemaKey;
	label: string;
	shortLabel: string;
	systemLocale: SystemLocale;
	plugins: {
		mui: Locale;
		date: string;
		dayjs: en.Locale;
		i18n: string;
		currencies: string;
		SuperTokens: string;
		intl: string;
		freshdeskWidget: string;
		csvbox: string;
	};
}

export const languages = Map<SystemLocale | `${SystemLocale}`, LanguageItem>({
	[SystemLocale.EN]: {
		code: SystemLocale.EN,
		id: 'lang_2FeOd6tMZNsYgg0Tbs14CjUdJpf',
		schemaKey: SystemLocaleSchemaKey.EN,
		label: 'English',
		shortLabel: 'EN',
		systemLocale: SystemLocale.EN,
		plugins: {
			mui: enLocale,
			date: 'en',
			dayjs: en,
			i18n: 'en',
			currencies: 'en_US',
			SuperTokens: 'en',
			intl: 'en-US',
			freshdeskWidget: 'en',
			csvbox: 'en',
		},
	},
	[SystemLocale.FR_CA]: {
		code: SystemLocale.FR_CA,
		id: 'lang_2FeOd6TIKd5Kli2mEkbKDKm8DR9',
		schemaKey: SystemLocaleSchemaKey.FR_CA,
		label: 'French (Canadian)',
		shortLabel: 'FR',
		systemLocale: SystemLocale.FR_CA,
		plugins: {
			mui: frLocale,
			date: 'fr',
			dayjs: fr,
			i18n: 'fr',
			currencies: 'fr',
			SuperTokens: 'fr',
			intl: 'fr',
			freshdeskWidget: 'fr',
			csvbox: 'fr',
		},
	},
});
