// import i18n from 'libs/localization/i18nInst';
import { Map } from 'immutable';
import type { AbilityScopeItem, AbilityScopeMap } from '../+types/scope-item.type';

const collection = 'teams';
const repository = 'Team';

export enum TeamScopes {
	'Create' = 'teams:create',
	'Read' = 'teams:read',
	'Update' = 'teams:update',
	'Delete' = 'teams:delete',
	'Manage' = 'teams:manage',
}

const namespace = 'teamsco';

export const teamScopes: AbilityScopeMap<TeamScopes> = Map<
	TeamScopes,
	AbilityScopeItem<TeamScopes>
>({
	[TeamScopes.Create]: {
		code: TeamScopes.Create,
		id: `${namespace}_2FXpr9ovzBRZUVnePV9slAuURnc`,
		order: 0,
		icon: 'circle-user',
		label: 'Team Create',
		collection,
		repository,
	},
	[TeamScopes.Read]: {
		code: TeamScopes.Read,
		id: `${namespace}_2FXpsSe7zkLSWBEkYFTPpkJq0SJ`,
		order: 1,
		icon: 'circle-user',
		label: 'Team Read',
		collection,
		repository,
	},
	[TeamScopes.Update]: {
		code: TeamScopes.Update,
		id: `${namespace}_2FXptuMFZmLLRkiflqEH5Sjb2G1`,
		order: 2,
		icon: 'circle-user',
		label: 'Team Update',
		collection,
		repository,
	},
	[TeamScopes.Delete]: {
		code: TeamScopes.Delete,
		id: `${namespace}_2FXpx1CPGfrhbUAQOVZimV42l4q`,
		order: 3,
		icon: 'circle-user',
		label: 'Team Delete',
		collection,
		repository,
	},
	[TeamScopes.Manage]: {
		code: TeamScopes.Manage,
		id: `${namespace}_2FXppAfzDhIW8QiFN51fTQdM2FI`,
		order: 4,
		icon: 'circle-user',
		label: 'Team Manage',
		alias: [TeamScopes.Create, TeamScopes.Read, TeamScopes.Update, TeamScopes.Delete],
		collection,
		repository,
	},
});
