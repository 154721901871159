// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum DeviceFingerprintType {
	FP5ICAX3QY = 'FP5icAX3QY',
	CLIPT52GYE = 'CLiPt52Gye',
	FASULEDOBX = 'FASulEdobX',
}

export interface DeviceFingerprintTypeItem extends BaseDictionary {
	code: DeviceFingerprintType;
	id: `${IdNamespace.DeviceFingerprintType}_${string}`;
	label: string;
}

export const deviceFingerprintTypes = Map<
	DeviceFingerprintType | `${DeviceFingerprintType}`,
	DeviceFingerprintTypeItem
>({
	[DeviceFingerprintType.FP5ICAX3QY]: {
		code: DeviceFingerprintType.FP5ICAX3QY,
		id: 'dfpty_2jTd4dfXX7TqX8eE7GEQclAAfuA',
		// label: i18n.t('fingerprint_id', { ns: 'app' }),
		label: 'FingerprintID',
	},
	[DeviceFingerprintType.CLIPT52GYE]: {
		code: DeviceFingerprintType.CLIPT52GYE,
		id: 'dfpty_2jTd4gvxRpA6Mw0CNDqoPrpZbun',
		// label: i18n.t('client_id', { ns: 'app' }),
		label: 'ClientID',
	},
	[DeviceFingerprintType.FASULEDOBX]: {
		code: DeviceFingerprintType.FASULEDOBX,
		id: 'dfpty_2jTu2rYOJYQpQhHKfEiAWH03Tmg',
		// label: i18n.t('fastify_id', { ns: 'app' }),
		label: 'FastifyId',
	},
});
