// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import { SortListItem, type SortList } from './types/dictionaries.type';
import { sortList } from './sort-list';

export enum EngagementTemplateSortList {
	TITLE = 'title',
}

export interface EngagementTemplateSortListItem extends Omit<SortListItem, 'id' | 'code'> {
	code: SortList | EngagementTemplateSortList;
	id: `${IdNamespace.SortList}_${string}` | `${IdNamespace.EngagementTemplateSortList}_${string}`;
}

export const engagementTemplateSortList: Map<
	SortList | `${SortList}` | EngagementTemplateSortList | `${EngagementTemplateSortList}`,
	EngagementTemplateSortListItem
> = Map<SortList | EngagementTemplateSortList, EngagementTemplateSortListItem>({
	[EngagementTemplateSortList.TITLE]: {
		code: EngagementTemplateSortList.TITLE,
		id: 'engtemsoli_2FeNkAoacgPQSOPmmz4tvONf6hP',
		order: 3,
		// label: i18n.t('engagementTemplateSortList.title', { ns: 'dictionaries' }),
		label: 'Title',
		directionIcons: {
			asc: 'sort-alpha-up-alt',
			desc: 'sort-alpha-down-alt',
		},
		function: {
			asc: {
				title: 'asc',
				_createdDate: 'asc',
			},
			desc: {
				title: 'desc',
				_createdDate: 'desc',
			},
		},
	},
}).merge(sortList);
