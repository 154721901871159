import { CompanyType } from '@dictionaries/company-types';
import { RouteMetadata } from 'routes/types/routes.type';

export const ClientsRoutesMetadata: Record<string, RouteMetadata> = {
	clients: {
		isClientPage: true,
		companyType: CompanyType.CLIENT,
	},
	vendors: {
		isVendorPage: true,
		companyType: CompanyType.VENDOR,
	},
	partners: {
		isPartnerPage: true,
		companyType: CompanyType.PARTNER,
	},
	administrators: {
		isAdministratorPage: true,
	},
	hiringManagers: {
		isHiringManagerPage: true,
	},
};
